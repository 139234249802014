/**
 * Sidebar Store Module
 * Manages the state of the application sidebar across desktop and mobile views.
 * Provides functionality for collapsing/expanding and showing/hiding the sidebar.
 */

import { create } from 'zustand';

/**
 * SidebarState Interface
 * @interface SidebarState
 * @property {boolean} isCollapsed - Whether the sidebar is in collapsed state (desktop)
 * @property {boolean} isMobileOpen - Whether the sidebar is open in mobile view
 * @property {Function} toggleSidebar - Toggle between collapsed and expanded states
 * @property {Function} setMobileOpen - Set the mobile sidebar open/closed state
 */
interface SidebarState {
  isCollapsed: boolean;
  isMobileOpen: boolean;
  toggleSidebar: () => void;
  setMobileOpen: (open: boolean) => void;
}

/**
 * Sidebar Zustand Store
 * Global state management for the application sidebar.
 * Handles responsive states for both desktop and mobile views.
 * 
 * @example
 * // To use in a component:
 * const { isCollapsed, toggleSidebar } = useSidebarStore();
 * 
 * // Toggle sidebar
 * <button onClick={toggleSidebar}>Toggle</button>
 * 
 * // Conditionally render based on state
 * {isCollapsed ? <CollapsedView /> : <ExpandedView />}
 */
export const useSidebarStore = create<SidebarState>((set) => ({
  isCollapsed: false,
  isMobileOpen: false,
  toggleSidebar: () => set((state) => ({ isCollapsed: !state.isCollapsed })),
  setMobileOpen: (open) => set({ isMobileOpen: open }),
}));