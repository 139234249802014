import React from 'react';
import { Home, Users, BarChart2, Share2, Settings, Building2, UserCircle, MessageSquare, GraduationCap, ChevronLeft, Menu, Bot } from 'lucide-react';
import NavItem from './NavItem';
import { useSidebarStore } from '@/stores/sidebarStore';
import { useAuthStore } from '@/stores/authStore';

const Sidebar = () => {
  const { isCollapsed, toggleSidebar, isMobileOpen, setMobileOpen } = useSidebarStore();
  const { user } = useAuthStore();

  const menuItems = [
    { section: 'Merchant Dashboard', items: [
      { icon: Home, label: 'Home', href: '/' },
      { icon: Users, label: 'Sessions', href: '/sessions' },
      // Analytics tab hidden temporarily
      // { icon: BarChart2, label: 'Analytics', href: '/analytics', badge: 'Building' },
      { icon: Share2, label: 'Share', href: '/share' },
    ]},
    { section: 'Assistant Setup', items: [
      { icon: Settings, label: 'Settings', href: '/settings' },
      { icon: Building2, label: 'Inventory', href: '/brands' },
      { icon: Bot, label: 'Assistant Config', href: '/assistant-config' },
    ]},
    { section: 'Merchant Section', items: [
      { icon: UserCircle, label: 'Membership', href: '/membership', badge: 'Premium' },
      { icon: MessageSquare, label: 'Feedback', href: '/feedback' },
      { icon: GraduationCap, label: 'Tutorials', href: '/tutorials' },
    ]}
  ];

  return (
    <>
      {/* Mobile Overlay */}
      {isMobileOpen && (
        <div 
          className="fixed inset-0 bg-black/50 z-40 lg:hidden"
          onClick={() => setMobileOpen(false)}
        />
      )}

      <aside 
        className={`bg-white border-r-2 border-gray-200 h-screen fixed left-0 top-0 z-50 shadow-lg transition-all duration-300
          ${isCollapsed ? 'w-20' : 'w-64'}
          lg:translate-x-0 
          ${isMobileOpen ? 'translate-x-0' : '-translate-x-full'}
        `}
      >
        <div className="p-6">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-3">
              <div className="bg-primary/10 p-2 rounded-lg">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/mattressai2.firebasestorage.app/o/default-logo.png?alt=media&token=d19ea3eb-1c91-41d4-b193-4f65c76989cc"
                  alt="MattressAI Logo"
                  className="h-12 w-auto"
                />
              </div>
              {!isCollapsed && (
                <span className="text-xl font-bold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                  MattressAI
                </span>
              )}
            </div>
            <button 
              onClick={toggleSidebar}
              className="p-1.5 hover:bg-gray-100 rounded-lg transition-colors lg:block hidden"
            >
              <ChevronLeft className={`h-5 w-5 text-text/50 transition-transform ${isCollapsed ? 'rotate-180' : ''}`} />
            </button>
          </div>
          <button
            onClick={() => setMobileOpen(false)}
            className="ml-auto p-1.5 hover:bg-gray-100 rounded-lg transition-colors lg:hidden"
          >
            <Menu className="h-5 w-5" />
          </button>
          <nav className="space-y-8">
            {menuItems.map((section, index) => (
              <div key={index}>
                {!isCollapsed && (
                  <h3 className="text-xs font-semibold text-primary uppercase tracking-wider mb-3">
                    {section.section}
                  </h3>
                )}
                <ul className="space-y-2">
                  {section.items.map((item, itemIndex) => (
                    <NavItem
                      key={itemIndex}
                      icon={item.icon}
                      label={item.label}
                      href={item.href}
                      badge={item.badge}
                      collapsed={isCollapsed}
                      onClick={() => setMobileOpen(false)}
                    />
                  ))}
                </ul>
              </div>
            ))}
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;