import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { CheckCircle, Copy, ExternalLink } from 'lucide-react';
import { useOnboardingStore } from '@/stores/onboardingStore';
import { useQuickStartStore } from '@/stores/quickStartStore';
import Button from '@/components/ui/Button';
import QuickStartGuide from '@/components/inventory/QuickStartGuide';

// Validation helper
function validateConfig(config: any) {
  // Ensure config exists
  if (!config) return { isValid: false, missing: ['Configuration is missing'] };

  const required = {
    'Store Name': config.storeInfo?.name?.trim?.(),
    'Brand Color': config.branding?.colors?.primary,
    'Assistant Name': config.assistant?.name?.trim?.()
  };

  // Add optional safe checks
  const optional = {
    'Store Hours': config.storeInfo?.hours,
    'Chat Title': config.branding?.chatTitle,
    'Welcome Message': config.branding?.welcomeMessage,
    'Assistant Greeting': config.assistant?.greeting
  };

  // Check required fields
  const missing = Object.entries(required)
    .filter(([_, value]) => !value)
    .map(([key]) => key);

  // Log validation for debugging
  console.log('Config validation:', {
    config,
    required,
    optional,
    missing,
    isValid: missing.length === 0
  });

  return { isValid: missing.length === 0, missing };
}

// Config Summary Component
function ConfigSummary({ config }: { config: any }) {
  // Early return with error UI if config is invalid
  if (!config) {
    console.error('Invalid config passed to ConfigSummary');
    return (
      <div className="p-4 bg-yellow-50 text-yellow-800 rounded-lg">
        <h4 className="font-medium">Invalid Configuration</h4>
        <p className="text-sm mt-1">Configuration data is missing or invalid.</p>
      </div>
    );
  }

  const sections = [
    {
      title: 'Store Information',
      fields: [
        { label: 'Name', value: config.storeInfo?.name || 'Not set' },
        { label: 'Hours', value: config.storeInfo?.hours || 'Not set' }
      ]
    },
    {
      title: 'Branding',
      fields: [
        { label: 'Primary Color', value: config.branding?.colors?.primary || 'Not set' },
        { label: 'Chat Title', value: config.branding?.chatTitle || config.storeInfo?.name || 'Not set' }
      ]
    },
    {
      title: 'Assistant',
      fields: [
        { label: 'Name', value: config.assistant?.name || 'Not set' },
        { label: 'Greeting', value: config.assistant?.greeting || 'Default greeting' }
      ]
    },
    {
      title: 'Lead Capture',
      fields: [
        { label: 'Email', value: config.leads?.adminEmail || 'Not set' },
        { label: 'Mobile', value: config.leads?.adminMobile || 'Not set' }
      ]
    }
  ];

  return (
    <div className="space-y-6">
      {sections.map((section) => (
        <div key={section.title} className="bg-white rounded-lg p-4">
          <h3 className="text-sm font-medium text-gray-900 mb-2">{section.title}</h3>
          <dl className="grid grid-cols-2 gap-2">
            {section.fields.map((field) => (
              <div key={field.label}>
                <dt className="text-sm text-gray-500">{field.label}</dt>
                <dd className="text-sm font-medium text-gray-900">{field.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      ))}
    </div>
  );
}

// Deployment Status Component
function DeploymentStatus({ chatUrl, onCopy }: { chatUrl: string; onCopy: () => void }) {
  return (
    <div className="bg-green-50 rounded-lg p-6 space-y-4">
      <div className="flex items-start gap-3">
        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
        <div>
          <h3 className="text-green-800 font-medium">Successfully Deployed!</h3>
          <p className="text-green-700 text-sm mt-1">
            Your AI assistant is now live and ready to help your customers
          </p>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-green-200 p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <ExternalLink className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-600">{chatUrl}</span>
          </div>
          <Button
            variant="secondary"
            size="sm"
            onClick={onCopy}
            className="flex items-center gap-2"
          >
            <Copy className="w-4 h-4" />
            Copy URL
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function FinalStep() {
  const navigate = useNavigate();
  const { config, isDeployed, isDeploying, chatUrl, deploy } = useOnboardingStore();
  const { setOpen: setQuickStartOpen } = useQuickStartStore();
  const [deployError, setDeployError] = React.useState<string | null>(null);

  // Handle deployment
  const handleDeploy = async () => {
    if (isDeploying) return;

    const { isValid, missing } = validateConfig(config);
    if (!isValid) {
      toast.error(`Please complete the following required fields to continue: ${missing.join(', ')}`);
      return;
    }

    try {
      setDeployError(null);
      await deploy();
      toast.success('Your MattressAI Assistant has been successfully deployed and is ready to use!');
      setQuickStartOpen(true);
      navigate('/brands');
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unable to deploy your MattressAI Assistant. Please try again or contact support.';
      setDeployError(message);
      toast.error(message);
    }
  };

  // Handle URL copy
  const handleCopyUrl = () => {
    if (chatUrl) {
      navigator.clipboard.writeText(`https://${chatUrl}`);
      toast.success('Your MattressAI Chat URL has been copied to clipboard! Share it with your customers.');
    }
  };

  if (!config) {
    return (
      <div className="p-4 bg-yellow-50 text-yellow-800 rounded-lg">
        <h4 className="font-medium">Configuration Not Found</h4>
        <p className="text-sm mt-1">Please complete the previous steps first.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">
            Configuration Summary
          </h3>
          <ConfigSummary config={config} />
        </div>

        <div className="bg-gray-50 p-6 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h3 className="text-sm font-medium text-gray-900">Ready to Deploy</h3>
              <p className="text-sm text-gray-500">
                Your AI assistant is configured and ready to go
              </p>
            </div>
            <Button
              variant="primary"
              onClick={handleDeploy}
              disabled={isDeploying || isDeployed}
            >
              {isDeploying ? 'Deploying...' : isDeployed ? 'Deployed' : 'Deploy Assistant'}
            </Button>
          </div>

          {deployError && (
            <p className="mt-2 text-sm text-red-600">{deployError}</p>
          )}
        </div>
      </div>

      {isDeployed && chatUrl && (
        <DeploymentStatus chatUrl={chatUrl} onCopy={handleCopyUrl} />
      )}

      <QuickStartGuide />
    </div>
  );
} 