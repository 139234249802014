import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Store, Palette, MessageSquare, Phone, CheckCircle, ArrowRight } from 'lucide-react';
import { useOnboardingStore } from '../../stores/onboardingStore';
import Button from '../ui/Button';
import StoreBasicsStep from './steps/StoreBasicsStep';
import BrandingStep from './steps/BrandingStep';
import AssistantStep from './steps/AssistantStep';
import LeadCaptureStep from './steps/LeadCaptureStep';
import FinalStep from './steps/FinalStep';
import { useAuth } from '../../hooks/useAuth';
import ErrorBoundary from '../common/ErrorBoundary';
import toast from 'react-hot-toast';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

const steps = [
  {
    title: 'Store Basics',
    description: 'Set up your store profile and business details',
    icon: Store,
    component: StoreBasicsStep,
    key: 'storeBasics' as const,
    tips: [
      'Enter your store name exactly as you want customers to see it',
      'Include your business hours to help manage customer expectations',
      'Add your website to enable seamless customer transitions'
    ]
  },
  {
    title: 'Brand Identity',
    description: 'Create a beautiful and engaging chat experience',
    icon: Palette,
    component: BrandingStep,
    key: 'brandIdentity' as const,
    tips: [
      'Choose colors that match your brand identity',
      'Upload a high-quality logo for brand recognition',
      'Craft a welcoming message that reflects your brand voice'
    ]
  },
  {
    title: 'Assistant Personality',
    description: 'Configure your AI mattress expert',
    icon: MessageSquare,
    component: AssistantStep,
    key: 'assistantPersonality' as const,
    tips: [
      'Give your assistant a friendly, memorable name',
      'Define their personality to match your brand tone',
      'Set clear expertise areas in mattress types and sleep solutions'
    ]
  },
  {
    title: 'Lead Capture',
    description: 'Never miss a potential customer',
    icon: Phone,
    component: LeadCaptureStep,
    key: 'leadCapture' as const,
    tips: [
      'Set up SMS notifications for immediate response',
      'Configure email alerts for detailed lead information',
      'Add your booking system link for direct appointments'
    ]
  },
  {
    title: 'Ready to Launch!',
    description: 'Review and activate your AI assistant',
    icon: CheckCircle,
    component: FinalStep,
    key: 'chatSetup' as const,
    tips: [
      'Review your configuration one last time',
      'Test your chat interface before going live',
      'Learn about monitoring and optimization tools'
    ]
  },
];

export default function OnboardingPopup() {
  const { user } = useAuth();
  const { currentStep, stepsCompleted, isComplete, config, updateLiveChat } = useOnboardingStore();

  // Prevent scrolling when popup is open
  useEffect(() => {
    if (!isComplete) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [isComplete]);

  // Update live chat whenever configuration changes
  useEffect(() => {
    let isUpdating = false;
    let timeoutId: NodeJS.Timeout | null = null;

    const updateChat = async () => {
      if (isUpdating || !user || !config.storeInfo.name) return;
      
      try {
        isUpdating = true;
        
        // Only update if we have the minimum required fields
        if (
          config.storeInfo.name &&
          config.branding.colors.primary &&
          config.assistant.name
        ) {
          await updateLiveChat();
        }
      } catch (error) {
        console.error('Failed to update live chat:', error);
        if (error instanceof Error && !error.message.includes('No user found')) {
          toast.error('Failed to save changes. Please try again.');
        }
      } finally {
        isUpdating = false;
      }
    };

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(updateChat, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [user, config, updateLiveChat]);

  if (isComplete) return null;

  const CurrentStepComponent = steps[currentStep].component;
  const currentStepData = steps[currentStep];

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="fixed inset-4 md:inset-10 bg-white rounded-2xl shadow-2xl overflow-hidden flex flex-col"
        >
          {/* Header */}
          <div className="flex-shrink-0 border-b p-6 bg-gradient-to-r from-blue-50 to-indigo-50">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h2 className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                  Welcome to MattressAI
                </h2>
                <p className="text-gray-600 mt-1">Let's set up your AI assistant in just a few steps</p>
              </div>
              <div className="text-sm font-medium text-blue-600">
                Step {currentStep + 1} of {steps.length}
              </div>
            </div>
            <div className="flex gap-2">
              {steps.map((step, index) => (
                <div
                  key={step.key}
                  className="flex-1 h-2 rounded-full overflow-hidden bg-gray-200"
                >
                  <motion.div
                    initial={false}
                    animate={{
                      width: stepsCompleted[step.key] ? '100%' : 
                             currentStep === index ? '50%' : '0%'
                    }}
                    className="h-full bg-gradient-to-r from-blue-500 to-indigo-500"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Content */}
          <div className="flex-1 overflow-y-auto p-6">
            <div className="max-w-2xl mx-auto">
              <div className="flex items-start gap-4 mb-8">
                <div className="flex-shrink-0 w-12 h-12 rounded-full bg-gradient-to-br from-blue-500 to-indigo-500 flex items-center justify-center">
                  {React.createElement(currentStepData.icon, {
                    className: "w-6 h-6 text-white",
                  })}
                </div>
                <div>
                  <h3 className="text-xl font-semibold">{currentStepData.title}</h3>
                  <p className="text-gray-600 mt-1">{currentStepData.description}</p>
                  
                  {/* Tips Section */}
                  <div className="mt-4 space-y-2">
                    {currentStepData.tips.map((tip, index) => (
                      <div key={index} className="flex items-center gap-2 text-sm text-gray-600">
                        <ArrowRight className="w-4 h-4 text-blue-500" />
                        <span>{tip}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <ErrorBoundary>
                <CurrentStepComponent />
              </ErrorBoundary>
            </div>
          </div>

          {/* Footer */}
          <div className="flex-shrink-0 border-t p-6 bg-gradient-to-r from-gray-50 to-blue-50">
            <div className="max-w-2xl mx-auto flex justify-between items-center">
              <Button
                variant="secondary"
                onClick={() => useOnboardingStore.getState().setStep(currentStep - 1)}
                disabled={currentStep === 0}
                className="px-6"
              >
                Back
              </Button>
              {currentStep < steps.length - 1 ? (
                <Button
                  variant="primary"
                  onClick={async () => {
                    try {
                      const { completeStep, setStep, initializeChat, config } = useOnboardingStore.getState();
                      
                      if (currentStep === 0 && user) {
                        if (!config.storeInfo.name) {
                          toast.error('Please enter your store name to continue with setup');
                          return;
                        }
                        
                        await initializeChat();
                        
                        const subdomain = config.storeInfo.name.toLowerCase().replace(/[^a-z0-9]/g, '-');
                        // Check for existing subdomain first
                        const subdomainRef = doc(db, 'subdomains', subdomain);
                        const existingSubdomain = await getDoc(subdomainRef);
                        const existingSubdomainData = existingSubdomain.exists() ? existingSubdomain.data() : {};
                        
                        await setDoc(subdomainRef, {
                          ...existingSubdomainData, // Preserve existing data
                          merchantId: user.uid,
                          subdomain,
                          status: 'active',
                          createdAt: new Date(),
                          storeName: config.storeInfo.name,
                          chatUrl: `${subdomain}.chat.mattressai.co`,
                          features: {
                            ...(existingSubdomainData.features || {}),
                            chatEnabled: true
                          }
                        });
                      }

                      completeStep(steps[currentStep].key);
                      setStep(currentStep + 1);
                    } catch (error) {
                      console.error('Failed to proceed to next step:', error);
                      toast.error('Unable to proceed with setup. Please try again or contact support.');
                    }
                  }}
                  className="px-6 bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700"
                >
                  Continue
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={async () => {
                    try {
                      const { completeOnboarding, config } = useOnboardingStore.getState();
                      
                      if (!user) {
                        toast.error('Please sign in to your MattressAI account to complete the setup process');
                        return;
                      }

                      const storeInfo = config.storeInfo || { name: '' };
                      const branding = config.branding || { colors: {}, chatTitle: '' };
                      const assistant = config.assistant || { name: '', greeting: '' };
                      const leads = config.leads || {};

                      if (!storeInfo.name) {
                        toast.error('Please enter your store name to complete setup');
                        return;
                      }

                      const subdomain = storeInfo.name.toLowerCase().replace(/[^a-z0-9]/g, '-');

                      try {
                        // Generate a default masterPrompt for the AI assistant
                        const defaultMasterPrompt = `You are a helpful AI assistant for ${storeInfo.name}, a mattress retailer.

Your primary goal is to collect customer contact information while providing expert mattress guidance.

## STORE INFORMATION
- Store Name: ${storeInfo.name}
${storeInfo.hours ? `- Store Hours: ${storeInfo.hours}` : ''}
${storeInfo.contactInfo ? `- Contact Information: ${storeInfo.contactInfo}` : ''}
${storeInfo.website ? `- Website: ${storeInfo.website}` : ''}

## FOCUS AREAS
- Mattress matching: Help customers find the right mattress based on their needs
- Sleep position guidance: Recommend mattresses based on sleep position
- Budget considerations: Work within the customer's price range
- Comfort preferences: Determine soft, medium, or firm mattress preferences

## TONE AND STYLE
- Be ${assistant.persona || 'professional and helpful'}
- Keep conversations natural and flowing
- Ask only ONE question at a time
- Focus on mattress-related topics`;

                        // Save comprehensive configuration to the settings subcollection
                        const merchantConfigRef = doc(db, 'merchants', user.uid, 'merchantConfig', 'settings');
                        // Get existing config first
                        const existingConfigSnap = await getDoc(merchantConfigRef);
                        const existingConfig = existingConfigSnap.exists() ? existingConfigSnap.data() : {};
                        
                        // Check for masterPrompt in legacy locations and log warnings
                        if (existingConfig.chatConfig?.masterPrompt) {
                          console.warn('Legacy masterPrompt found in chatConfig.masterPrompt. This will be migrated to assistant.masterPrompt.');
                        }
                        
                        // Use existing assistant masterPrompt if available, otherwise use the default
                        const existingMasterPrompt = existingConfig.assistant?.masterPrompt;
                        
                        await setDoc(merchantConfigRef, {
                          ...existingConfig, // Preserve existing config data
                          storeInfo,
                          branding: {
                            ...(existingConfig.branding || {}),
                            colors: branding.colors || { primary: '#2563eb', secondary: '#64748b' },
                            ...(branding.logo && { logo: branding.logo }),
                            ...(branding.chatIcon && { chatIcon: branding.chatIcon }),
                            chatTitle: branding.chatTitle || storeInfo.name,
                            welcomeMessage: branding.welcomeMessage || 'Welcome! How can I help you today?'
                          },
                          assistant: {
                            ...(existingConfig.assistant || {}),
                            name: assistant.name || 'AI Assistant',
                            description: assistant.description || 'Your friendly mattress expert',
                            persona: assistant.persona || 'professional and helpful',
                            greeting: assistant.greeting || 'Hello! How can I assist you today?',
                            // Always save masterPrompt in the canonical path
                            masterPrompt: existingMasterPrompt || defaultMasterPrompt,
                            masterPromptSource: 'auto',
                            masterPromptLastEditedAt: new Date().toISOString()
                          },
                          leads: {
                            ...(existingConfig.leads || {}),
                            adminEmail: leads.adminEmail || '',
                            adminMobile: leads.adminMobile || '',
                            redirectUrl: leads.redirectUrl || ''
                          },
                          features: {
                            ...(existingConfig.features || {}),
                            chatEnabled: true,
                            recommendationsEnabled: true,
                            analyticsEnabled: true
                          },
                          chatConfig: {
                            ...(existingConfig.chatConfig || {}),
                            // Remove masterPrompt from chatConfig if it exists
                            ...(existingConfig.chatConfig?.masterPrompt ? { 
                              // Create a copy without the masterPrompt field
                              ...Object.fromEntries(
                                Object.entries(existingConfig.chatConfig)
                                .filter(([key]) => key !== 'masterPrompt')
                              )
                            } : existingConfig.chatConfig || {}),
                            welcomeMessage: branding.welcomeMessage || 'Welcome! How can I help you today?',
                            model: 'gpt-4',
                            temperature: 0.7,
                            maxTokens: 2000,
                            initialGreeting: assistant.greeting || 'Hello! How can I assist you today?',
                            conversationStyle: 'professional',
                            leadCaptureTiming: 'after-engagement',
                            primaryGoal: 'lead',
                            responseCreativity: 0.7,
                            productRecommendationLimit: 3,
                            educationTopics: 'sleep health, mattress types, sleep positions, mattress materials'
                          },
                          updatedAt: new Date(),
                          createdAt: existingConfig.createdAt || new Date()
                        });

                        // Save essential info to the merchant document for easier access
                        const merchantRef = doc(db, 'merchants', user.uid);
                        // Get existing merchant data first
                        const existingMerchantSnap = await getDoc(merchantRef);
                        const existingMerchant = existingMerchantSnap.exists() ? existingMerchantSnap.data() : {};
                        
                        await setDoc(merchantRef, {
                          ...existingMerchant, // Preserve existing merchant data
                          storeInfo: {
                            ...(existingMerchant.storeInfo || {}),
                            name: storeInfo.name,
                            ...(storeInfo.website && { website: storeInfo.website }),
                            ...(storeInfo.hours && { hours: storeInfo.hours }),
                            ...(storeInfo.contactInfo && { contactInfo: storeInfo.contactInfo }),
                            hasMultipleLocations: storeInfo.hasMultipleLocations || false
                          },
                          chatUrl: `${subdomain}.chat.mattressai.co`,
                          branding: {
                            ...(existingMerchant.branding || {}),
                            colors: branding.colors || { primary: '#2563eb', secondary: '#64748b' }
                          },
                          features: {
                            ...(existingMerchant.features || {}),
                            chatEnabled: true,
                            recommendationsEnabled: true,
                            analyticsEnabled: true
                          },
                          updatedAt: new Date()
                        }, { merge: true });

                        completeOnboarding();
                        toast.success('Your MattressAI Assistant has been launched successfully! Let\'s set up your inventory next.');
                      } catch (error) {
                        console.error('Failed to update Firestore:', error);
                        throw new Error('Unable to save your configuration. Please try again.');
                      }
                    } catch (error) {
                      console.error('Failed to complete onboarding:', error);
                      if (error instanceof Error) {
                        toast.error(error.message);
                      } else {
                        toast.error('Unable to launch your MattressAI Assistant. Please try again or contact support.');
                      }
                    }
                  }}
                  className="px-8 bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700"
                >
                  Launch Assistant
                </Button>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
} 