/**
 * Utility functions for formatting data
 */

/**
 * Formats a phone number to E.164 international format
 * Converts any phone number format to +xxxxxxxxxxx format
 * 
 * @param phoneNumber The phone number to format
 * @returns Formatted phone number in E.164 format (+15738811864)
 */
export function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) return '';

  // Remove all non-digit characters
  let digitsOnly = phoneNumber.replace(/\D/g, '');
  
  // Handle US numbers
  if (digitsOnly.length === 10) {
    // Add US country code
    digitsOnly = '1' + digitsOnly;
  }
  
  // If it doesn't have a country code (assuming international format needs to start with +)
  if (digitsOnly.length > 10 && !phoneNumber.includes('+')) {
    // Check if it starts with country code 1 (US/Canada)
    if (digitsOnly.charAt(0) === '1' && digitsOnly.length === 11) {
      // Already has country code, just add +
      return `+${digitsOnly}`;
    }
  }
  
  // If it already has a country code, just ensure it starts with +
  if (digitsOnly.length > 10) {
    return `+${digitsOnly}`;
  }
  
  // Return the original number if we can't format it properly
  // This preserves any international formatting that might already be correct
  return phoneNumber.startsWith('+') ? phoneNumber : `+${digitsOnly}`;
}

/**
 * Formats currency amounts
 * @param amount The amount to format
 * @returns Formatted currency string
 */
export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
} 