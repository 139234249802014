import React from 'react';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { useTeamManagement } from '@/hooks/useTeamManagement';
import { Plus, X, AlertCircle, Trash2, RefreshCw, Users } from 'lucide-react';
import FormTextField from '@/components/ui/form/FormTextField';
import FormField from '@/components/ui/FormField';
import { TeamMember } from '@/services/teamManagement';

interface TeamStepProps {
  onPrevious: () => void;
  onComplete: () => void;
}

/**
 * Team Management Step
 * Handles team member invitations and permissions
 */
const TeamStep: React.FC<TeamStepProps> = ({ onPrevious, onComplete }) => {
  const {
    teamMembers,
    showInviteForm,
    inviteEmail,
    inviteRole,
    isLoadingTeam,
    isInviting,
    teamError,
    setShowInviteForm,
    setInviteEmail,
    setInviteRole,
    handleInvite,
    handleRoleChange,
    handleRemoveTeamMember,
    handleResendInvite
  } = useTeamManagement();

  return (
    <Card className="p-6">
      <div className="space-y-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium">Team Members</h3>
          <Button 
            variant="secondary" 
            onClick={() => setShowInviteForm(!showInviteForm)}
            className="flex items-center gap-1"
          >
            {showInviteForm ? (
              <>
                <X className="w-4 h-4" />
                <span>Cancel</span>
              </>
            ) : (
              <>
                <Plus className="w-4 h-4" />
                <span>Invite Team Member</span>
              </>
            )}
          </Button>
        </div>

        {teamError && (
          <div className="p-3 bg-red-50 border border-red-100 rounded-lg flex items-start gap-2 text-red-800">
            <AlertCircle className="w-5 h-5 mt-0.5 flex-shrink-0" />
            <p className="text-sm">{teamError}</p>
          </div>
        )}

        {showInviteForm && (
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <h4 className="text-sm font-medium mb-3">Invite New Team Member</h4>
            <div className="space-y-4">
              <FormTextField
                label="Email Address"
                type="email"
                value={inviteEmail}
                onChange={setInviteEmail}
                placeholder="colleague@example.com"
              />

              <FormField label="Role">
                <select 
                  className="w-full px-3 py-2 border rounded-lg border-gray-200"
                  value={inviteRole}
                  onChange={(e) => setInviteRole(e.target.value as TeamMember['role'])}
                >
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                  <option value="staff">Staff</option>
                </select>
              </FormField>
            </div>

            <div className="flex justify-end mt-4">
              <Button 
                variant="primary" 
                onClick={handleInvite} 
                disabled={isInviting || !inviteEmail}
              >
                {isInviting ? 'Sending...' : 'Send Invitation'}
              </Button>
            </div>
          </div>
        )}

        {isLoadingTeam ? (
          <div className="text-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
            <p className="mt-2 text-sm text-gray-600">Loading team members...</p>
          </div>
        ) : teamMembers.length === 0 ? (
          <div className="text-center py-8">
            <Users className="w-12 h-12 text-gray-400 mx-auto mb-3" />
            <h3 className="text-lg font-medium text-gray-900">No team members yet</h3>
            <p className="mt-1 text-sm text-gray-500">
              Invite your colleagues to collaborate with you
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            {teamMembers.map((member: TeamMember) => (
              <div key={member.id} className="flex items-center justify-between py-3 border-b last:border-0">
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
                    {member.name[0].toUpperCase()}
                  </div>
                  <div>
                    <p className="font-medium">{member.name}</p>
                    <p className="text-sm text-gray-500">{member.email}</p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    member.status === 'pending' 
                      ? 'bg-yellow-50 text-yellow-600' 
                      : 'bg-green-50 text-green-600'
                  }`}>
                    {member.status === 'pending' ? 'Pending' : 'Active'}
                  </span>
                  <select 
                    className="px-3 py-1.5 text-sm border rounded-lg border-gray-200"
                    value={member.role}
                    onChange={(e) => handleRoleChange(member.id, e.target.value)}
                    disabled={member.role === 'owner'}
                  >
                    <option value="owner">Owner</option>
                    <option value="admin">Admin</option>
                    <option value="manager">Manager</option>
                    <option value="staff">Staff</option>
                  </select>
                  {member.status === 'pending' && (
                    <button
                      onClick={() => handleResendInvite(member.email)}
                      className="p-1.5 text-gray-400 hover:text-gray-600"
                      title="Resend invitation"
                    >
                      <RefreshCw className="w-4 h-4" />
                    </button>
                  )}
                  {member.role !== 'owner' && (
                    <button
                      onClick={() => handleRemoveTeamMember(member.id)}
                      className="p-1.5 text-gray-400 hover:text-red-600"
                      title="Remove team member"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex justify-end gap-3 mt-6">
          <Button variant="secondary" onClick={onPrevious}>
            Previous
          </Button>
          <Button 
            variant="primary" 
            onClick={onComplete}
          >
            Return to Profile
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default TeamStep; 