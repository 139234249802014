import { getStorage, ref, uploadBytes, getDownloadURL, uploadString } from 'firebase/storage';
import { auth } from '@/config/firebase';
import { isDevEnvironment } from '@/utils/environment';

interface UploadOptions {
  merchantId?: string;
  isPublic?: boolean;
}

// Maximum file size for data URL uploads (5MB)
const MAX_DATA_URL_SIZE = 5 * 1024 * 1024; // 5MB

// For dev environment only - store files in local storage (IndexedDB)
const LOCAL_STORAGE_KEY = 'firebase_storage_files';

/**
 * Saves a file to browser's IndexedDB in development mode
 * This completely bypasses Firebase Storage for local development
 */
async function saveFileLocally(file: File, path: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const dataUrl = e.target?.result as string;
        if (!dataUrl) {
          throw new Error('Failed to read file as data URL');
        }
        
        // Create a unique file key
        const fileKey = `${path}_${file.name}_${Date.now()}`;
        
        // Get existing files or create new object
        const existingFiles = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        
        // Save the file data
        existingFiles[fileKey] = {
          dataUrl,
          name: file.name,
          path,
          type: file.type,
          size: file.size,
          uploadedAt: new Date().toISOString()
        };
        
        // Save back to local storage
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(existingFiles));
        
        // Create a fake download URL that we can use locally
        // This URL format mimics Firebase Storage URLs for consistency
        const fakeDownloadUrl = `http://localhost:3003/dev-storage/${encodeURIComponent(fileKey)}`;
        console.log('Dev mode: File saved locally, URL:', fakeDownloadUrl);
        
        resolve(fakeDownloadUrl);
      } catch (error) {
        console.error('Error saving file locally:', error);
        reject(error);
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * Enhanced file upload function that handles CORS issues
 * In development: uses local storage to bypass Firebase Storage
 * In production: uses Firebase Storage with data URL for CORS resilience
 */
export async function uploadFile(file: File, path: string, options: UploadOptions = {}): Promise<string> {
  try {
    if (!auth.currentUser) {
      throw new Error('No authenticated user found');
    }

    // Construct the storage path
    let storagePath: string;
    if (options.isPublic) {
      // Public assets path
      storagePath = `public/${path}/${file.name}`;
    } else if (options.merchantId) {
      // Merchant-specific path
      storagePath = `merchants/${options.merchantId}/${path}/${file.name}`;
    } else {
      // User-specific path
      storagePath = `users/${auth.currentUser.uid}/${path}/${file.name}`;
    }

    console.log(`Uploading file to ${storagePath} (${file.size} bytes)`);

    // In development environment, use local storage instead of Firebase
    if (isDevEnvironment()) {
      console.log('Development mode: Using local storage for file upload');
      return saveFileLocally(file, storagePath);
    }

    // In production, use Firebase Storage with data URL approach
    const storage = getStorage();
    const fileRef = ref(storage, storagePath);

    // Convert file to data URL and upload
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = async (e) => {
        try {
          const dataUrl = e.target?.result as string;
          if (!dataUrl) {
            throw new Error('Failed to read file as data URL');
          }
          
          console.log(`File converted to data URL (${dataUrl.length} chars)`);
          
          // Upload using data URL method
          const snapshot = await uploadString(fileRef, dataUrl, 'data_url');
          const downloadURL = await getDownloadURL(snapshot.ref);
          
          console.log('File uploaded successfully, URL:', downloadURL);
          resolve(downloadURL);
        } catch (error) {
          console.error('Error in data URL upload:', error);
          reject(error);
        }
      };
      
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(new Error('Failed to read file data'));
      };
      
      reader.readAsDataURL(file);
    });
  } catch (error) {
    console.error('Error uploading file:', error);
    if (error instanceof Error) {
      if (error.message.includes('unauthorized')) {
        throw new Error('You do not have permission to upload files to this location');
      } else if (error.message.includes('unauthenticated')) {
        throw new Error('Please sign in to upload files');
      } else if (error.message.includes('CORS')) {
        throw new Error('CORS error: Unable to upload. Please try a different browser or contact support.');
      } else if (error.message.includes('network')) {
        throw new Error('Network error: Please check your internet connection and try again.');
      }
    }
    throw new Error('Failed to upload file. Please try again with a smaller file or a different file format.');
  }
} 