import { doc, collection, setDoc, getDocs, query, where, deleteDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/config/firebase';
import { User } from 'firebase/auth';

// Types
export interface TeamMember {
  id: string;
  name: string;
  email: string;
  role: 'owner' | 'admin' | 'manager' | 'staff';
  status: 'active' | 'pending';
}

export interface Team {
  id: string;
  name: string;
  ownerId: string;
  members: TeamMember[];
}

// Service object
export const teamManagementService = {
  async createTeam(user: User, teamName: string): Promise<string> {
    const teamsRef = collection(db, 'teams');
    const teamRef = doc(teamsRef);
    
    const team = {
      name: teamName,
      ownerId: user.uid,
      members: [{
        uid: user.uid,
        email: user.email!,
        role: 'owner',
        status: 'active'
      }]
    };
    
    await setDoc(teamRef, team);
    return teamRef.id;
  },

  async getTeams(userId: string): Promise<Team[]> {
    const teamsRef = collection(db, 'teams');
    const q = query(teamsRef, where('members', 'array-contains', { uid: userId }));
    const querySnapshot = await getDocs(q);
    
    return querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as Team[];
  },

  async deleteTeam(teamId: string, userId: string): Promise<void> {
    const teamRef = doc(db, 'teams', teamId);
    const teamDoc = await getDocs(query(collection(db, 'teams'), where('id', '==', teamId)));
    
    if (teamDoc.empty || teamDoc.docs[0].data().ownerId !== userId) {
      throw new Error('Unauthorized to delete team');
    }
    
    await deleteDoc(teamRef);
  },

  async getTeamMembers(merchantId: string): Promise<TeamMember[]> {
    const teamRef = collection(db, `merchants/${merchantId}/team`);
    const snapshot = await getDocs(teamRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as TeamMember[];
  },

  async inviteTeamMember(merchantId: string, email: string, role: TeamMember['role']): Promise<void> {
    const teamRef = collection(db, `merchants/${merchantId}/team`);
    await addDoc(teamRef, {
      email,
      role,
      name: email.split('@')[0], // Default name from email
      status: 'pending',
      createdAt: new Date().toISOString()
    });
  },

  async updateTeamMemberRole(merchantId: string, memberId: string, role: TeamMember['role']): Promise<void> {
    const memberRef = doc(db, `merchants/${merchantId}/team/${memberId}`);
    await updateDoc(memberRef, { role });
  },

  async removeTeamMember(merchantId: string, memberId: string): Promise<void> {
    const memberRef = doc(db, `merchants/${merchantId}/team/${memberId}`);
    await deleteDoc(memberRef);
  },

  async resendInvitation(merchantId: string, email: string): Promise<void> {
    // Implementation for resending invitation
    // This would typically trigger a Cloud Function to send a new email
    console.log('Resending invitation to:', email);
  }
}; 