// Test deployment - GitHub Actions should deploy this automatically
/**
 * Main Application Component
 * Handles routing, authentication, and layout structure of the application.
 * Implements lazy loading for optimal performance and code splitting.
 */

import React, { Suspense, lazy, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useAuthStore } from './stores/authStore';
import { useOnboardingStore } from './stores/onboardingStore';
import { QueryProvider } from './providers/QueryProvider';
import { ThemeProvider } from './providers/ThemeProvider';
import Layout from './components/layout/Layout';
import { Toaster } from 'react-hot-toast';
import OnboardingPopup from './components/onboarding/OnboardingPopup';
import { RouteErrorBoundary } from './components/common/ErrorBoundary';
import { CentralizedConfigService } from './services/config/CentralizedConfigService';

// Lazy loaded components for code splitting and performance optimization
const LoginPage = lazy(() => import('./components/auth/LoginPage').then(module => ({ default: module.default })));
const SignUp = lazy(() => import('./components/auth/SignUpPage').then(module => ({ default: module.default })));
const HomePage = lazy(() => import('./components/home/HomePage').then(module => ({ default: module.default })));
const ChatConfig = lazy(() => import('./pages/ChatConfig').then(module => ({ default: module.default })));
const Analytics = lazy(() => import('./components/analytics/AnalyticsPage').then(module => ({ default: module.default })));
const Sessions = lazy(() => import('./pages/Sessions').then(module => ({ default: module.default })));
const SessionDetail = lazy(() => import('./pages/SessionDetail').then(module => ({ default: module.default })));
const Share = lazy(() => import('./pages/Share').then(module => ({ default: module.default })));
const Settings = lazy(() => import('./components/settings/SettingsPage').then(
  module => ({ default: module.default }),
  error => {
    console.error('Failed to load Settings page:', error);
    throw new Error('Failed to load Settings page. Please try refreshing the page.');
  }
));
const Brands = lazy(() => import('./pages/Brands').then(module => ({ default: module.default })));
const AssistantConfig = lazy(() => import('./pages/AssistantConfig').then(module => ({ default: module.default })));
const Membership = lazy(() => import('./components/membership/MembershipPage').then(module => ({ default: module.default })));
const Feedback = lazy(() => import('./pages/Feedback').then(module => ({ default: module.default })));
const Tutorials = lazy(() => import('./pages/Tutorials').then(module => ({ default: module.default })));

/**
 * Loading spinner component displayed during suspense states
 * Used as a fallback while lazy-loaded components are being fetched
 */
const Loading = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
  </div>
);

/**
 * Root application component that sets up:
 * - Authentication-based routing
 * - Protected routes
 * - Lazy loading with suspense
 * - Global toast notifications
 * - Onboarding flow for new users
 */
function App() {
  const { user, isAuthenticated, isLoading } = useAuthStore();
  const { isComplete } = useOnboardingStore();

  // Pre-load config at the application root level to ensure it's cached before using
  // This helps prevent race conditions when navigating between settings pages
  useEffect(() => {
    const preloadConfig = async () => {
      try {
        const configService = CentralizedConfigService.getInstance();
        await configService.loadConfig();
        console.log('Config preloaded successfully at app root');
      } catch (error) {
        console.error('Failed to preload config:', error);
      }
    };

    // Only preload if user is authenticated
    if (user) {
      preloadConfig();
    }
  }, [user]);

  // Display loading spinner while authentication state is being determined
  if (isLoading) {
    return <Loading />;
  }

  // Router configuration with protected routes and authentication checks
  const router = createBrowserRouter([
    // Main application routes - protected by authentication
    {
      path: '/',
      element: isAuthenticated ? (
        <>
          <Layout />
          {!isComplete && <OnboardingPopup />}
        </>
      ) : (
        <Navigate to="/login" replace />
      ),
      errorElement: <RouteErrorBoundary />,
      children: [
        {
          path: '/',
          element: (
            <Suspense fallback={<Loading />}>
              <HomePage />
            </Suspense>
          ),
          errorElement: <RouteErrorBoundary />
        },
        {
          path: '/sessions',
          element: (
            <Suspense fallback={<Loading />}>
              <Sessions />
            </Suspense>
          ),
        },
        {
          path: '/sessions/:sessionId',
          element: (
            <Suspense fallback={<Loading />}>
              <SessionDetail />
            </Suspense>
          ),
        },
        {
          path: '/analytics',
          element: (
            <Suspense fallback={<Loading />}>
              <Analytics />
            </Suspense>
          ),
        },
        {
          path: '/share',
          element: (
            <Suspense fallback={<Loading />}>
              <Share />
            </Suspense>
          ),
        },
        {
          path: '/settings',
          element: (
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          ),
          errorElement: <RouteErrorBoundary />
        },
        {
          path: '/brands',
          element: (
            <Suspense fallback={<Loading />}>
              <Brands />
            </Suspense>
          ),
        },
        {
          path: '/assistant-config',
          element: (
            <Suspense fallback={<Loading />}>
              <AssistantConfig />
            </Suspense>
          ),
        },
        {
          path: '/membership',
          element: (
            <Suspense fallback={<Loading />}>
              <Membership />
            </Suspense>
          ),
        },
        {
          path: '/feedback',
          element: (
            <Suspense fallback={<Loading />}>
              <Feedback />
            </Suspense>
          ),
        },
        {
          path: '/tutorials',
          element: (
            <Suspense fallback={<Loading />}>
              <Tutorials />
            </Suspense>
          ),
        },
      ],
    },
    // Public authentication routes
    {
      path: '/login',
      element: !isAuthenticated ? (
        <Suspense fallback={<Loading />}>
          <LoginPage />
        </Suspense>
      ) : (
        <Navigate to="/" replace />
      ),
    },
    {
      path: '/signup',
      element: !isAuthenticated ? (
        <Suspense fallback={<Loading />}>
          <SignUp />
        </Suspense>
      ) : (
        <Navigate to="/" replace />
      ),
    },
  ]);

  // Wrap the application with query provider and toast notifications
  return (
    <QueryProvider>
      <ThemeProvider>
        <RouterProvider router={router} />
        <Toaster position="bottom-right" />
      </ThemeProvider>
    </QueryProvider>
  );
}

export default App;