/**
 * Quick Start Store Module
 * Manages the state of the Quick Start wizard for initial application setup.
 * Controls modal visibility and inventory setup method selection.
 */

import { create } from 'zustand';

/**
 * InventoryMethod Type
 * Defines the available methods for setting up inventory:
 * - 'system-brands': Use predefined brands from the system catalog
 * - 'custom-brand': Define custom brands specific to the merchant
 */
export type InventoryMethod = 'system-brands' | 'custom-brand';

/**
 * QuickStartState Interface
 * @interface QuickStartState
 * @property {boolean} isOpen - Whether the quick start modal is open
 * @property {InventoryMethod | null} selectedMethod - The selected inventory setup method
 * @property {Function} setOpen - Sets the open/closed state of the modal
 * @property {Function} setSelectedMethod - Updates the selected inventory method
 * @property {Function} reset - Resets the store to its initial state
 */
interface QuickStartState {
  isOpen: boolean;
  selectedMethod: InventoryMethod | null;
  setOpen: (isOpen: boolean) => void;
  setSelectedMethod: (method: InventoryMethod) => void;
  reset: () => void;
}

/**
 * Quick Start Zustand Store
 * Global state management for the quick start onboarding flow.
 * Tracks the wizard's open state and the merchant's inventory setup preferences.
 * 
 * @example
 * // To use in a component:
 * const { isOpen, selectedMethod, setOpen } = useQuickStartStore();
 * 
 * // Open the quick start modal
 * <button onClick={() => setOpen(true)}>Start Setup</button>
 * 
 * // Conditionally render based on selected method
 * {selectedMethod === 'system-brands' ? <SystemBrands /> : <CustomBrand />}
 */
export const useQuickStartStore = create<QuickStartState>((set) => ({
  isOpen: false,
  selectedMethod: null,
  setOpen: (isOpen) => set({ isOpen }),
  setSelectedMethod: (method) => set({ selectedMethod: method }),
  reset: () => set({ isOpen: false, selectedMethod: null }),
})); 