/**
 * Toast Notification Store Module
 * Manages the global toast notification system for the application.
 * Provides functionality for creating, displaying, and removing notification toasts.
 */

import { create } from 'zustand';

/**
 * ToastType Type
 * Defines the available types of toast notifications:
 * - 'success': Positive action completion
 * - 'error': Error notifications
 * - 'info': Informational messages
 * - 'warning': Warning alerts
 */
export type ToastType = 'success' | 'error' | 'info' | 'warning';

/**
 * Toast Interface
 * Represents a single toast notification
 * @interface Toast
 * @property {string} id - Unique identifier for the toast
 * @property {ToastType} type - Type of toast notification
 * @property {string} message - Content message to display
 */
interface Toast {
  id: string;
  type: ToastType;
  message: string;
}

/**
 * ToastState Interface
 * @interface ToastState
 * @property {Toast[]} toasts - Array of active toast notifications
 * @property {Function} addToast - Creates and displays a new toast
 * @property {Function} removeToast - Removes a specific toast by ID
 */
interface ToastState {
  toasts: Toast[];
  addToast: (type: ToastType, message: string, duration?: number) => void;
  removeToast: (id: string) => void;
}

/**
 * Toast Zustand Store
 * Global state management for application-wide toast notifications.
 * Handles creation, automatic removal, and manual dismissal of toasts.
 * 
 * @example
 * // To use in a component:
 * const { addToast } = useToastStore();
 * 
 * // Display success toast
 * addToast('success', 'Settings saved successfully');
 * 
 * // Display error toast with custom duration (10 seconds)
 * addToast('error', 'Failed to save settings', 10000);
 */
export const useToastStore = create<ToastState>((set) => ({
  toasts: [],
  addToast: (type, message, duration = 5000) => {
    // Generate random ID for the toast
    const id = Math.random().toString(36).substring(7);
    
    // Add toast to the array
    set((state) => ({
      toasts: [...state.toasts, { id, type, message }],
    }));
    
    // Set timeout to automatically remove the toast after duration
    setTimeout(() => {
      set((state) => ({
        toasts: state.toasts.filter((toast) => toast.id !== id),
      }));
    }, duration);
  },
  removeToast: (id) =>
    set((state) => ({
      toasts: state.toasts.filter((toast) => toast.id !== id),
    })),
}));