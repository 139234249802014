import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Building2, Plus, ArrowRight, Check, Rocket, ShoppingBag, Zap } from 'lucide-react';
import { useQuickStartStore } from '@/stores/quickStartStore';
import Button from '@/components/ui/Button';
import { InventoryMethod } from '@/stores/quickStartStore';

const inventorySetupOptions = [
  {
    title: "Use System Brands",
    description: "Choose from our curated list of popular mattress brands and models",
    action: "system-brands" as InventoryMethod,
    icon: Building2,
    benefits: [
      "Quick setup with pre-configured brands",
      "Regular updates with new models",
      "Verified product information",
      "Standardized data format"
    ]
  },
  {
    title: "Add Custom Brand",
    description: "Create your own brand and add custom mattress models",
    action: "custom-brand" as InventoryMethod,
    icon: Plus,
    benefits: [
      "Full control over product details",
      "Support for unique features",
      "Custom pricing flexibility",
      "Specialized product categories"
    ]
  }
];

const features = [
  {
    icon: Rocket,
    title: "Quick Setup",
    description: "Get your inventory ready in minutes with our guided process"
  },
  {
    icon: ShoppingBag,
    title: "Product Management",
    description: "Easily manage and update your mattress inventory"
  },
  {
    icon: Zap,
    title: "Smart Recommendations",
    description: "AI-powered product suggestions based on customer needs"
  }
];

export default function QuickStartGuide() {
  const { isOpen, selectedMethod, setOpen, setSelectedMethod } = useQuickStartStore();

  const handleMethodSelect = (method: InventoryMethod) => {
    setSelectedMethod(method);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="bg-white rounded-xl shadow-2xl w-full max-w-3xl overflow-hidden"
          >
            {/* Header */}
            <div className="p-6 border-b bg-gradient-to-r from-blue-50 to-indigo-50">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                    Set Up Your Inventory
                  </h2>
                  <p className="mt-2 text-gray-600">
                    Let's get your mattress inventory set up so your AI assistant can provide accurate recommendations
                  </p>
                </div>
                <button
                  onClick={handleClose}
                  className="p-2 hover:bg-white/50 rounded-lg transition-colors"
                >
                  <X className="w-5 h-5 text-gray-500" />
                </button>
              </div>

              {/* Feature highlights */}
              <div className="mt-6 grid grid-cols-3 gap-4">
                {features.map((feature, index) => (
                  <div key={index} className="flex items-start gap-3 p-3 rounded-lg bg-white/50">
                    <feature.icon className="w-5 h-5 text-blue-500 flex-shrink-0" />
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">{feature.title}</h3>
                      <p className="text-xs text-gray-500 mt-1">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Options */}
            <div className="p-6 grid grid-cols-2 gap-6">
              {inventorySetupOptions.map((option) => (
                <motion.button
                  key={option.action}
                  onClick={() => handleMethodSelect(option.action)}
                  className={`w-full p-6 rounded-xl border-2 transition-all flex flex-col items-start gap-4 hover:border-blue-500 hover:bg-blue-50 ${
                    selectedMethod === option.action
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200'
                  }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div className={`w-12 h-12 rounded-lg flex items-center justify-center ${
                    selectedMethod === option.action
                      ? 'bg-blue-500'
                      : 'bg-gray-100'
                  }`}>
                    <option.icon className={`w-6 h-6 ${
                      selectedMethod === option.action
                        ? 'text-white'
                        : 'text-gray-500'
                    }`} />
                  </div>
                  <div className="text-left">
                    <h3 className="font-semibold text-gray-900">{option.title}</h3>
                    <p className="text-sm text-gray-500 mt-1">{option.description}</p>
                  </div>
                  <div className="mt-2 space-y-2">
                    {option.benefits.map((benefit, index) => (
                      <div key={index} className="flex items-center gap-2 text-sm">
                        <Check className={`w-4 h-4 ${
                          selectedMethod === option.action
                            ? 'text-blue-500'
                            : 'text-gray-400'
                        }`} />
                        <span className="text-gray-600">{benefit}</span>
                      </div>
                    ))}
                  </div>
                </motion.button>
              ))}
            </div>

            {/* Info Box */}
            <div className="px-6 pb-6">
              <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl p-4">
                <h4 className="text-sm font-medium text-blue-900 mb-2">Pro Tip</h4>
                <p className="text-sm text-blue-700">
                  Start with system brands for popular mattresses to get up and running quickly. 
                  You can always add custom brands later for your unique products.
                </p>
              </div>
            </div>

            {/* Footer */}
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <button
                  onClick={handleClose}
                  className="text-sm text-gray-500 hover:text-gray-700"
                >
                  I'll set this up later
                </button>
                <Button
                  variant="primary"
                  onClick={handleClose}
                  disabled={!selectedMethod}
                  className="px-8 bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700"
                >
                  Start Setup
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
} 