export const env = {
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
  },
  openai: {
    apiKey: import.meta.env.VITE_OPENAI_API_KEY,
    baseUrl: import.meta.env.VITE_OPENAI_BASE_URL,
  },
  pinecone: {
    apiKey: import.meta.env.VITE_PINECONE_API_KEY,
    environment: import.meta.env.VITE_PINECONE_ENVIRONMENT,
    index: import.meta.env.VITE_PINECONE_INDEX,
  },
  merchant: {
    id: import.meta.env.VITE_DEFAULT_MERCHANT_ID || 'default-merchant',
  },
}; 