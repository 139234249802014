import React from 'react';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

export function ColorPicker({ color, onChange }: ColorPickerProps) {
  return (
    <div className="flex gap-3">
      <input
        type="color"
        className="h-10 w-20 border rounded cursor-pointer"
        value={color}
        onChange={(e) => onChange(e.target.value)}
      />
      <input
        type="text"
        className="flex-grow px-4 py-2 border rounded-lg border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary"
        value={color}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
} 