import React, { useEffect, useState } from 'react';
import { useOnboardingStore } from '@/stores/onboardingStore';
import FormField from '@/components/ui/FormField';
import { Phone, Mail, Link as LinkIcon } from 'lucide-react';
import { useAuth } from '@/hooks/useAuth';
import { formatPhoneNumber } from '../../../utils/formatters';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/config/firebase';

export default function LeadCaptureStep() {
  const { config, updateConfig } = useOnboardingStore();
  const { user } = useAuth();
  const { leads = {} } = config;
  
  const [adminMobile, setAdminMobile] = useState(leads?.adminMobile || '');
  const [adminEmail, setAdminEmail] = useState(leads?.adminEmail || '');
  const [redirectUrl, setRedirectUrl] = useState(leads?.redirectUrl || '');
  
  useEffect(() => {
    if (!user) return;
    
    // Load existing lead settings from merchantConfig/settings
    const loadLeadSettings = async () => {
      try {
        const merchantConfigRef = doc(db, 'merchants', user.uid, 'merchantConfig', 'settings');
        const merchantConfigDoc = await getDoc(merchantConfigRef);
        
        if (merchantConfigDoc.exists()) {
          const data = merchantConfigDoc.data();
          if (data.leads) {
            updateConfig('leads', {
              adminEmail: data.leads.adminEmail || '',
              adminMobile: data.leads.adminMobile || '',
              redirectUrl: data.leads.redirectUrl || ''
            });
            setAdminEmail(data.leads.adminEmail || '');
            setAdminMobile(data.leads.adminMobile || '');
            setRedirectUrl(data.leads.redirectUrl || '');
          }
        }
      } catch (error) {
        console.error('Error loading lead settings:', error);
      }
    };
    
    loadLeadSettings();
  }, [user, updateConfig]);
  
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Store raw input for display
    setAdminMobile(e.target.value);
    
    // Format and save the E.164 formatted number
    updateConfig('leads', {
      ...leads,
      adminMobile: formatPhoneNumber(e.target.value)
    });
  };
  
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminEmail(e.target.value);
    updateConfig('leads', {
      ...leads,
      adminEmail: e.target.value
    });
  };
  
  const handleRedirectUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRedirectUrl(e.target.value);
    updateConfig('leads', {
      ...leads,
      redirectUrl: e.target.value
    });
  };

  return (
    <div className="space-y-8">
      <div>
        <h3 className="text-lg font-medium mb-4">Lead Capture Settings</h3>
        <p className="text-gray-600 mb-4">
          Configure how you receive notifications when potential customers interact with your AI assistant.
        </p>
        
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
          <h4 className="font-medium text-blue-800 mb-2">Why This Matters</h4>
          <p className="text-blue-700 text-sm">
            Responding to leads within the first 5 minutes increases conversion rates by 100x. 
            Set up SMS notifications to never miss a potential customer.
          </p>
        </div>
        
        <div className="space-y-6">
          <FormField label="Text Message Notifications">
            <div className="relative">
              <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="tel"
                className="w-full pl-10 pr-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="+1 (555) 123-4567"
                value={adminMobile}
                onChange={handlePhoneChange}
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Your assistant will send leads to this number - format will be automatically fixed
            </p>
          </FormField>
          
          <FormField label="Email Notifications">
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="email"
                className="w-full pl-10 pr-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="store@example.com"
                value={adminEmail}
                onChange={handleEmailChange}
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Receive detailed lead information and conversation transcripts
            </p>
          </FormField>

          <FormField label="Redirect URL (Optional)">
            <div className="relative">
              <LinkIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="url"
                className="w-full pl-10 pr-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder="https://calendly.com/your-store"
                value={redirectUrl}
                onChange={handleRedirectUrlChange}
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Direct customers to your booking system or landing page
            </p>
          </FormField>
        </div>
      </div>
    </div>
  );
} 