import { ChatConfig } from './types';

/**
 * Gets the lead capture strategy based on timing configuration and capture style
 * 
 * @param timing The lead capture timing setting
 * @param config The chat configuration
 * @returns The formatted lead capture strategy text
 */
export function getLeadCaptureStrategy(timing: string, config: ChatConfig): string {
  // Get the lead capture configuration
  const leadCapture = config.leadCapture || {};
  const captureStyle = leadCapture.captureStyle || 'ask';
  const incentives = leadCapture.incentives || 'special offers';
  const requiredFields = leadCapture.requiredFields || { email: 'required', name: 'optional', phone: 'disabled' };

  // Determine the persistence level based on captureStyle
  let persistenceLevel = '';
  if (captureStyle === 'persistent') {
    persistenceLevel = `- Be persistent but natural in your approach
- If they decline, respect their decision but try again later
- Make at least two different attempts with different approaches`;
  } else if (captureStyle === 'require') {
    persistenceLevel = `- Politely but firmly require contact information before proceeding with recommendations
- Explain that this is necessary to provide the best possible service
- If they repeatedly decline, you may proceed but continue to request information`;
  } else {
    persistenceLevel = `- Ask once politely, if they decline, respect their decision and move on
- You may make one additional attempt later if natural in conversation`;
  }

  // Create incentive-based messages
  const incentiveMessage = incentives ? 
    `- Mention you can send them "${incentives}" if they provide their contact information` : '';
  
  // Determine which fields to request based on requiredFields
  let fieldsToRequest = '';
  if (requiredFields.email !== 'disabled') {
    fieldsToRequest += `- Email address${requiredFields.email === 'required' ? ' (required)' : ' (optional)'}`;
  }
  if (requiredFields.name !== 'disabled') {
    fieldsToRequest += `\n- Name${requiredFields.name === 'required' ? ' (required)' : ' (optional)'}`;
  }
  if (requiredFields.phone !== 'disabled') {
    fieldsToRequest += `\n- Phone number${requiredFields.phone === 'required' ? ' (required)' : ' (optional)'}`;
  }

  // Base lead capture strategy based on timing
  let baseStrategy = '';
  switch (timing) {
    case 'immediate':
      baseStrategy = `Ask for contact information immediately after greeting:
- "I'd be happy to help you find the perfect mattress. To provide you with ${incentives}, could I get your contact information?"
- "Before we dive into mattress options, I'd like to send you our ${incentives}. What email should I use?"`;
      break;
    
    case 'after_preferences':
    case 'after-preferences':
      baseStrategy = `Ask for contact information after learning about their sleep preferences:
- "Based on your sleep preferences, I can send you our tailored ${incentives}. What's your email address?"
- "I'd like to email you our special recommendations for ${incentives}. What's your email address?"`;
      break;
    
    case 'before_recommendations':
    case 'before-recommendations':
      baseStrategy = `Ask for contact information right before making specific product recommendations:
- "I have several great mattress options for you. I'd like to email you ${incentives}. What's your email address?"
- "Before I recommend specific models, I'd like to send you our ${incentives}. What email works best for you?"`;
      break;
    
    case 'after_rapport':
    case 'after-engagement':
    default:
      baseStrategy = `Ask for contact information after establishing rapport (2-3 exchanges):
- "I can see you're looking for specific features. Would you like me to email you our ${incentives}?"
- "Since you're interested in [mentioned feature], I can send you our ${incentives}. What's your email?"`;
      break;
  }

  return `${baseStrategy}

FIELDS TO REQUEST:
${fieldsToRequest}

PERSISTENCE APPROACH:
${persistenceLevel}${incentiveMessage ? `\n${incentiveMessage}` : ''}`;
} 