/**
 * Helper functions for working with configuration objects
 */
import { MerchantConfig } from '@/types/merchantConfig';

/**
 * Safely gets a nested property from an object using a path string
 * (Similar to lodash.get)
 * 
 * @param obj The object to get the property from
 * @param path The path to the property (e.g., 'storeInfo.name')
 * @param defaultValue The default value if the property doesn't exist
 * @returns The property value or the default value
 */
export function getNestedValue<T = any>(
  obj: Record<string, any>, 
  path: string, 
  defaultValue?: T
): T {
  if (!obj || typeof obj !== 'object') {
    return defaultValue as T;
  }
  
  const parts = path.split('.');
  let current = obj;
  
  for (const part of parts) {
    if (current === undefined || current === null || typeof current !== 'object') {
      return defaultValue as T;
    }
    current = current[part];
  }
  
  return current !== undefined ? current as T : (defaultValue as T);
}

/**
 * Checks if a nested property exists in an object using a path string
 * (Similar to lodash.has)
 * 
 * @param obj The object to check
 * @param path The path to check (e.g., 'storeInfo.name')
 * @returns True if the property exists
 */
export function hasNestedProperty(obj: Record<string, any>, path: string): boolean {
  if (!obj || typeof obj !== 'object') {
    return false;
  }
  
  const parts = path.split('.');
  let current = obj;
  
  for (const part of parts) {
    if (current === undefined || current === null || typeof current !== 'object') {
      return false;
    }
    if (!(part in current)) {
      return false;
    }
    current = current[part];
  }
  
  return true;
}

/**
 * Determine if the given changes should trigger a masterPrompt update
 * 
 * @param changes The configuration changes that occurred
 * @returns True if changes affect the masterPrompt
 */
export function shouldUpdateMasterPrompt(changes: Partial<MerchantConfig>): boolean {
  // Define keys that trigger masterPrompt updates when changed
  const triggerKeys = [
    'storeInfo.name',
    'storeInfo.uniqueFeatures',
    'storeInfo.location',
    'storeInfo.hours',
    'storeInfo.contactInfo',
    'storeInfo.website',
    'storeInfo.storeFiles',
    'chatConfig.primaryGoal',
    'chatConfig.leadCaptureTiming',
    'chatConfig.conversationStyle',
    'chatConfig.responseCreativity',
    'chatConfig.questions',
    'chatConfig.educationTopics',
    'chatConfig.productRecommendationLimit',
    'assistant.name',
    'assistant.persona',
    'assistant.description',
    'assistant.temperature',
  ];
  
  // If we're explicitly setting a master prompt, don't auto-update
  if (getNestedValue(changes, 'assistant.masterPrompt')) {
    console.log('Skipping auto masterPrompt generation because masterPrompt is being explicitly set');
    return false;
  }
  
  // Special handling for the common case of updating the entire chatConfig or assistant section
  if (changes.chatConfig || changes.assistant) {
    console.log('Detected changes to chatConfig or assistant section, triggering masterPrompt update');
    return true;
  }
  
  // Check if any trigger key is in the changes
  const shouldUpdate = triggerKeys.some(key => hasNestedProperty(changes, key));
  
  if (shouldUpdate) {
    console.log('Detected specific config changes that affect masterPrompt');
  }
  
  return shouldUpdate;
} 