/**
 * Environment Utilities
 * Helper functions for environment-specific behavior and configuration.
 */

/**
 * Determines if the application is running in a development environment
 * @returns Boolean indicating if current environment is development
 */
export function isDevEnvironment(): boolean {
  // Check if we're in a development environment
  // In Vite apps, we can use import.meta.env instead of process.env
  return (
    import.meta.env.MODE === 'development' ||
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname.includes('local')
  );
}

/**
 * Gets the current environment name
 * @returns String representation of the current environment
 */
export function getEnvironment(): 'development' | 'staging' | 'production' {
  if (isDevEnvironment()) {
    return 'development';
  }
  
  // Check for staging environment
  if (
    window.location.hostname.includes('staging') ||
    window.location.hostname.includes('dev') ||
    window.location.hostname.includes('test')
  ) {
    return 'staging';
  }
  
  // Default to production
  return 'production';
}

/**
 * Gets environment-specific configuration values
 * @param developmentValue Value to use in development environment
 * @param stagingValue Value to use in staging environment
 * @param productionValue Value to use in production environment
 * @returns The appropriate value for the current environment
 */
export function getEnvValue<T>(
  developmentValue: T,
  stagingValue: T,
  productionValue: T
): T {
  const env = getEnvironment();
  
  switch (env) {
    case 'development':
      return developmentValue;
    case 'staging':
      return stagingValue;
    case 'production':
      return productionValue;
    default:
      return productionValue;
  }
} 