import React from 'react';
import Card from '@/components/ui/Card';
import FormField from '@/components/ui/FormField';
import Button from '@/components/ui/Button';
import { useProfileManagement } from '@/hooks/useProfileManagement';
import { useAuth } from '@/hooks/useAuth';
import { useToastStore } from '@/stores/toastStore';
import FormTextField from '@/components/ui/form/FormTextField';

interface ProfileStepProps {
  onNext: () => void;
}

/**
 * Profile Information Step
 * Handles user profile information editing
 */
const ProfileStep: React.FC<ProfileStepProps> = ({ onNext }) => {
  const { user } = useAuth();
  const { addToast } = useToastStore();
  const { 
    profileData, 
    updateProfileField, 
    saveProfile, 
    isLoading, 
    isSaving, 
    error 
  } = useProfileManagement(user?.uid);

  /**
   * Handles save and navigation to next step
   */
  const handleSaveAndContinue = async () => {
    const success = await saveProfile();
    if (success) {
      addToast('success', 'Profile saved successfully');
      onNext();
    }
  };

  if (isLoading) {
    return (
      <Card className="p-6">
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="p-6">
      <h3 className="text-lg font-medium mb-4">Personal Information</h3>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-100 rounded-lg">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormField label="Email">
          <input 
            type="email" 
            className="w-full px-3 py-2 border rounded-lg border-gray-200" 
            value={user?.email || ''} 
            disabled 
          />
        </FormField>

        <FormTextField
          label="Display Name"
          value={profileData.displayName}
          onChange={(value) => updateProfileField('displayName', value)}
          placeholder="Enter your name"
        />

        <FormTextField
          label="Phone Number"
          value={profileData.phoneNumber}
          onChange={(value) => updateProfileField('phoneNumber', value)}
          placeholder="(555) 123-4567"
        />

        <FormTextField
          label="Job Title"
          value={profileData.jobTitle}
          onChange={(value) => updateProfileField('jobTitle', value)}
          placeholder="Store Manager"
        />
      </div>

      <div className="flex justify-end mt-6">
        <Button 
          variant="primary" 
          onClick={handleSaveAndContinue}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Next Step'}
        </Button>
      </div>
    </Card>
  );
};

export default ProfileStep; 