import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { getFirestore, doc, updateDoc, getDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { uploadFile } from '@/utils/fileUpload';
import { auth } from '@/config/firebase';
import { StoreFile } from '@/types/store';
import { analyzeImage } from '../api/imageAnalysisApi';
import { isDevEnvironment } from '@/utils/environment';
import { PdfService } from './PdfService';

/**
 * Service for handling store file uploads and processing
 */
export class StoreFileService {
  // Cache for image analysis to avoid redundant API calls
  private imageAnalysisCache: Record<string, {
    description: string,
    timestamp: number
  }> = {};
  
  // PDF service for document extraction
  private pdfService = new PdfService();
  
  // Cache expiration time (24 hours)
  private readonly CACHE_EXPIRATION = 24 * 60 * 60 * 1000;
  
  /**
   * Upload a store-related file to Firebase Storage
   * @param file The file to upload
   * @returns Promise with the file metadata
   */
  async uploadStoreFile(file: File): Promise<StoreFile> {
    if (!auth.currentUser) {
      throw new Error('No authenticated user found');
    }

    const merchantId = auth.currentUser.uid;
    const path = 'store-files';
    
    try {
      // Upload the file using the existing utility
      const downloadURL = await uploadFile(file, path, { merchantId });
      
      // Create file metadata
      const storeFile: StoreFile = {
        name: file.name,
        url: downloadURL,
        type: file.type,
        uploadedAt: new Date().toISOString(),
        size: file.size
      };
      
      // If it's an image, we can pre-analyze it to save time later
      if (file.type.includes('image/')) {
        try {
          console.log('Pre-analyzing image with GPT-4o:', file.name);
          // Don't await this - let it happen in background
          this.analyzeImageWithGPT4o(downloadURL).then(analysis => {
            if (analysis) {
              // Cache the analysis result
              this.imageAnalysisCache[downloadURL] = {
                description: analysis,
                timestamp: Date.now()
              };
              console.log('Successfully pre-analyzed image:', file.name);
            }
          }).catch(err => {
            console.error('Error pre-analyzing image:', err);
          });
        } catch (analysisError) {
          console.error('Error setting up image pre-analysis:', analysisError);
          // Don't fail the upload if analysis fails
        }
      }
      
      // Update the merchant config to include this file
      await this.saveFileMetadata(merchantId, storeFile);
      
      return storeFile;
    } catch (error) {
      console.error('Error uploading store file:', error);
      throw error;
    }
  }
  
  /**
   * Save file metadata to the merchant config
   */
  private async saveFileMetadata(merchantId: string, fileData: StoreFile): Promise<void> {
    const db = getFirestore();
    const settingsRef = doc(db, `merchants/${merchantId}/merchantConfig/settings`);
    
    try {
      // Get current store info
      const settingsSnap = await getDoc(settingsRef);
      if (!settingsSnap.exists()) {
        throw new Error('Merchant config not found');
      }
      
      // Add file to storeFiles array
      await updateDoc(settingsRef, {
        'storeInfo.storeFiles': arrayUnion(fileData)
      });
    } catch (error) {
      console.error('Error saving file metadata:', error);
      throw error;
    }
  }
  
  /**
   * Delete a store file
   */
  async deleteStoreFile(fileData: StoreFile): Promise<void> {
    if (!auth.currentUser) {
      throw new Error('No authenticated user found');
    }
    
    const merchantId = auth.currentUser.uid;
    const storage = getStorage();
    
    try {
      // Extract the file path from the URL
      const fileUrl = new URL(fileData.url);
      const filePath = decodeURIComponent(fileUrl.pathname.split('/o/')[1].split('?')[0]);
      
      // Delete the file from storage
      const fileRef = ref(storage, filePath);
      await deleteObject(fileRef);
      
      // Remove file metadata from merchant config
      const db = getFirestore();
      const settingsRef = doc(db, `merchants/${merchantId}/merchantConfig/settings`);
      
      await updateDoc(settingsRef, {
        'storeInfo.storeFiles': arrayRemove(fileData)
      });
      
      // Clear from cache if it exists
      if (this.imageAnalysisCache[fileData.url]) {
        delete this.imageAnalysisCache[fileData.url];
      }
    } catch (error) {
      console.error('Error deleting store file:', error);
      throw error;
    }
  }
  
  /**
   * Get file content for text-based files (for the masterPrompt)
   * This function extracts text content from text-based files like TXT or CSV
   */
  async getFileContent(fileUrl: string): Promise<string> {
    try {
      const response = await fetch(fileUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.status} ${response.statusText}`);
      }
      
      // Check if it's a text-based file
      const contentType = response.headers.get('Content-Type');
      if (contentType && (
        contentType.includes('text/') || 
        contentType.includes('application/json') ||
        contentType.includes('application/csv')
      )) {
        return await response.text();
      }
      
      // For non-text files, return a description
      return `[This file is not text-based and requires processing with GPT-4o for content extraction]`;
    } catch (error) {
      console.error('Error getting file content:', error);
      return `[Error extracting file content: ${error instanceof Error ? error.message : 'Unknown error'}]`;
    }
  }
  
  /**
   * Analyze an image with GPT-4o to get a detailed description
   * @param imageUrl URL of the image to analyze
   * @returns Promise with the description from GPT-4o
   */
  async analyzeImageWithGPT4o(imageUrl: string): Promise<string> {
    // Check cache first
    if (
      this.imageAnalysisCache[imageUrl] && 
      Date.now() - this.imageAnalysisCache[imageUrl].timestamp < this.CACHE_EXPIRATION
    ) {
      console.log('Using cached image analysis for:', imageUrl);
      return this.imageAnalysisCache[imageUrl].description;
    }
    
    try {
      console.log('Analyzing image with GPT-4o:', imageUrl);
      
      // Call the API to analyze the image
      const analysisResult = await analyzeImage(imageUrl);
      
      // Store in cache for future use
      this.imageAnalysisCache[imageUrl] = {
        description: analysisResult.description,
        timestamp: Date.now()
      };
      
      // Update the file metadata with the analysis results if user is logged in
      if (auth.currentUser) {
        try {
          await this.updateFileAnalysisMetadata(imageUrl, {
            description: analysisResult.description,
            analyzedAt: new Date().toISOString(),
            detectedItems: analysisResult.detectedItems,
            storeRelevance: analysisResult.storeRelevance
          });
        } catch (updateError) {
          console.error('Error updating file analysis metadata:', updateError);
          // Continue even if metadata update fails
        }
      }
      
      return analysisResult.description;
    } catch (error) {
      console.error('Error analyzing image with GPT-4o:', error);
      
      // Return fallback content in dev environment
      if (isDevEnvironment()) {
        return `[This is a development environment placeholder for GPT-4o image analysis]
This image appears to be from a mattress store. It likely shows the store layout, 
product displays, or promotional materials. In a production environment, 
GPT-4o would provide a detailed description of everything visible in the image.`;
      }
      
      // In production, return error message
      return `[Error analyzing image: ${error instanceof Error ? error.message : 'Unknown error'}]`;
    }
  }
  
  /**
   * Update file metadata with analysis results
   * @param fileUrl The URL of the file to update
   * @param analysis The analysis data to store
   */
  private async updateFileAnalysisMetadata(
    fileUrl: string, 
    analysis: {
      description: string;
      analyzedAt: string;
      detectedItems?: string[];
      storeRelevance?: 'high' | 'medium' | 'low';
    }
  ): Promise<void> {
    if (!auth.currentUser) return;
    
    const merchantId = auth.currentUser.uid;
    const db = getFirestore();
    const settingsRef = doc(db, `merchants/${merchantId}/merchantConfig/settings`);
    
    try {
      // Get current settings
      const settingsSnap = await getDoc(settingsRef);
      if (!settingsSnap.exists()) return;
      
      const settings = settingsSnap.data();
      const storeInfo = settings.storeInfo || {};
      const storeFiles = storeInfo.storeFiles || [];
      
      // Find the file in the array
      const fileIndex = storeFiles.findIndex((file: any) => file.url === fileUrl);
      if (fileIndex === -1) return;
      
      // Create updated file object
      const updatedFile = {
        ...storeFiles[fileIndex],
        analysis,
        // Determine category based on analysis
        category: this.determineCategoryFromAnalysis(analysis)
      };
      
      // Create updated storeFiles array
      const updatedStoreFiles = [...storeFiles];
      updatedStoreFiles[fileIndex] = updatedFile;
      
      // Update the document
      await updateDoc(settingsRef, {
        'storeInfo.storeFiles': updatedStoreFiles
      });
      
      console.log('Successfully updated file analysis metadata');
    } catch (error) {
      console.error('Error updating file analysis metadata:', error);
      throw error;
    }
  }
  
  /**
   * Determine the file category based on analysis
   * @param analysis The analysis data
   * @returns Category string
   */
  private determineCategoryFromAnalysis(
    analysis: {
      description: string;
      analyzedAt: string;
      detectedItems?: string[];
      storeRelevance?: 'high' | 'medium' | 'low';
    }
  ): 'map' | 'product' | 'promotion' | 'document' | 'other' {
    const description = analysis.description.toLowerCase();
    
    // Check for maps and layouts
    if (
      description.includes('map') || 
      description.includes('layout') || 
      description.includes('floor plan') ||
      description.includes('store design')
    ) {
      return 'map';
    }
    
    // Check for products
    if (
      description.includes('product') || 
      description.includes('mattress') || 
      description.includes('pillow') ||
      description.includes('bedding') ||
      description.includes('display') ||
      description.includes('showcase')
    ) {
      return 'product';
    }
    
    // Check for promotions
    if (
      description.includes('promotion') || 
      description.includes('sale') || 
      description.includes('discount') ||
      description.includes('offer') ||
      description.includes('deal') ||
      description.includes('special')
    ) {
      return 'promotion';
    }
    
    // Default to other
    return 'other';
  }
  
  /**
   * Process file content for inclusion in the masterPrompt
   * @param files Array of store files
   * @returns Formatted string with file content for the masterPrompt
   */
  async processFilesForMasterPrompt(files: StoreFile[]): Promise<string> {
    if (!files || files.length === 0) {
      return '';
    }
    
    let fileContent = '## STORE FILES AND RESOURCES\n';
    fileContent += 'The following files contain important information about our store. Use this information when answering customer questions:\n';
    
    // Process each file
    for (const file of files) {
      // Add file header
      fileContent += `\n### ${file.name}\n`;
      
      // For text-based files, extract content
      if (file.type.includes('text/') || 
          file.type.includes('application/json') ||
          file.type.includes('application/csv')) {
        try {
          const content = await this.getFileContent(file.url);
          fileContent += content + '\n';
        } catch (error) {
          fileContent += `[Error extracting content: ${error instanceof Error ? error.message : 'Unknown error'}]\n`;
        }
      } else if (file.type.includes('image/')) {
        // For image files, use GPT-4o analysis
        fileContent += `[Image file - ${Math.round(file.size / 1024)} KB]\n`;
        
        try {
          // Get image analysis from GPT-4o
          const imageAnalysis = await this.analyzeImageWithGPT4o(file.url);
          
          // Add analysis to the content
          fileContent += `Image Description: ${imageAnalysis}\n\n`;
          
          // Add guidance for the AI assistant
          if (file.name.toLowerCase().includes('map') || imageAnalysis.toLowerCase().includes('map') || 
              imageAnalysis.toLowerCase().includes('layout') || imageAnalysis.toLowerCase().includes('floor plan')) {
            fileContent += `Note: When customers ask about the store layout or navigation, refer to this information to provide accurate directions.\n`;
          } else if (imageAnalysis.toLowerCase().includes('product') || imageAnalysis.toLowerCase().includes('mattress') || 
                     imageAnalysis.toLowerCase().includes('display')) {
            fileContent += `Note: Reference this display information when customers ask about how products look in the showroom.\n`;
          } else if (imageAnalysis.toLowerCase().includes('promotion') || imageAnalysis.toLowerCase().includes('sale') || 
                     imageAnalysis.toLowerCase().includes('discount')) {
            fileContent += `Note: This promotional information may be time-limited. Confirm with the customer if they're asking about current promotions.\n`;
          }
        } catch (error) {
          // Fallback to basic description if analysis fails
          fileContent += `This is a ${file.name.toLowerCase().includes('map') ? 'store layout map' : 'visual resource'} that contains important visual information. `;
          fileContent += `If customers ask about store layout or request to see the ${file.name.toLowerCase().includes('map') ? 'floor map' : 'image'}, `;
          fileContent += `inform them that it's available in our physical store or on our website.\n`;
        }
      } else if (file.type.includes('application/pdf')) {
        // For PDF files, use PDF extraction
        fileContent += `[PDF document - ${Math.round(file.size / 1024)} KB]\n`;
        
        try {
          // Extract PDF content using our PDF service
          const pdfContent = await this.pdfService.getSummarizedPdfContent(file.url);
          
          // Check if it's a catalog or other document type
          const extractionResult = await this.pdfService.extractPdfContent(file.url);
          const isCatalog = this.pdfService.isCatalog(extractionResult);
          
          // Add content type indication
          if (isCatalog) {
            fileContent += `Document Type: Product Catalog/Specifications\n\n`;
            fileContent += `Note: This document contains product specifications and details that can be referenced when customers ask about specific mattress models or features.\n\n`;
          } else if (extractionResult.title?.toLowerCase().includes('warranty') || 
                    pdfContent.toLowerCase().includes('warranty') ||
                    pdfContent.toLowerCase().includes('guarantee')) {
            fileContent += `Document Type: Warranty Information\n\n`;
            fileContent += `Note: This document contains warranty information that should be referenced when customers ask about product guarantees or return policies.\n\n`;
          } else if (extractionResult.title?.toLowerCase().includes('care') || 
                    pdfContent.toLowerCase().includes('care instructions') ||
                    pdfContent.toLowerCase().includes('maintenance')) {
            fileContent += `Document Type: Care Instructions\n\n`;
            fileContent += `Note: This document contains care and maintenance instructions that should be shared when customers ask about mattress longevity or care.\n\n`;
          }
          
          // Add the extracted content
          fileContent += pdfContent + '\n';
          
          // Add usage guidance
          fileContent += `\nUsage Guidance: When customers ask about information contained in this document, provide specific details rather than general responses. If they request more detailed information than what's shown here, offer to email them the complete document.\n`;
        } catch (error) {
          // Fallback to generic description if extraction fails
          fileContent += `This document contains important information that may be relevant to customer inquiries. `;
          fileContent += `If customers ask for detailed information that might be in this document, `;
          fileContent += `suggest they visit our website or contact the store directly for the complete document.\n`;
        }
      } else {
        // For other file types
        fileContent += `[${file.type} file - ${Math.round(file.size / 1024)} KB]\n`;
      }
    }
    
    // Add guidelines for using file information
    fileContent += `\nGuidelines for using store files:
- Reference information from these files when relevant to customer questions
- Do not mention that these are "files" - simply use the information naturally in conversation
- If files contain pricing or promotional information, verify with the customer if the information is still current
- For floor maps or visual resources, use the detailed descriptions to guide customers about the store layout
- When files contain detailed specifications, use them to provide accurate information about products or services
- For images that have been analyzed, incorporate those details when describing products or store features
- For PDF documents, reference specific sections and details rather than vague mentions
- When answering questions about warranty, care instructions, or specifications, cite the relevant documents
- Keep descriptions based on these files brief and conversational, focusing on key points`;
    
    return fileContent;
  }
} 