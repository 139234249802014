/**
 * Authentication Hook Module
 * Custom hook for managing authentication state and user roles
 * Provides role-based access control helpers
 */

import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

interface AuthState {
  user: User | null;
  loading: boolean;
}

/**
 * useAuth Hook
 * Manages authentication state and provides role-based access control
 * 
 * @returns {Object} Authentication state and role checks
 * @property {User | null} user - Current user object
 * @property {boolean} isLoading - Loading state of auth
 * @property {boolean} isAuthenticated - Whether user is authenticated
 * @property {Function} logout - Function to log out user
 * @property {boolean} isSuperAdmin - Whether user has super admin privileges
 * @property {boolean} isAdmin - Whether user has admin privileges
 * @property {boolean} isMerchant - Whether user has merchant privileges
 * 
 * @example
 * const { user, isAuthenticated, isAdmin } = useAuth();
 * if (isAdmin) {
 *   // Show admin features
 * }
 */
export function useAuth() {
  const [state, setState] = useState<AuthState>({
    user: null,
    loading: true,
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setState({
        user,
        loading: false,
      });
    });

    return () => unsubscribe();
  }, []);

  // Role-based access control flags
  const isSuperAdmin = state.user?.role === 'super_admin';
  const isAdmin = state.user?.role === 'admin' || isSuperAdmin;     // Admins include super admins
  const isMerchant = state.user?.role === 'merchant' || isAdmin;    // Merchants include admins

  return {
    user: state.user,            // Current user object
    isLoading: state.loading,       // Auth loading state
    isAuthenticated: !!state.user, // Auth state
    logout: () => {
      // Implement logout functionality
    },
    isSuperAdmin,    // Super admin check
    isAdmin,         // Admin check
    isMerchant,      // Merchant check
  };
}