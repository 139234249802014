import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import Sidebar from './Sidebar';
import Header from './Header';
import { useSidebarStore } from '@/stores/sidebarStore';

const Layout = () => {
  const { isSuperAdmin } = useAuth();
  const { isCollapsed } = useSidebarStore();

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className={`${isCollapsed ? 'lg:ml-20' : 'lg:ml-64'} transition-[margin] duration-300`}>
        <Header isSuperAdmin={isSuperAdmin} />
        <main className="pt-24 px-4 lg:px-8 pb-8">
          <div className="max-w-7xl mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;