import React from 'react';
import FormField from '@/components/ui/FormField';

interface FormTextFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  type?: 'text' | 'email' | 'password' | 'tel';
  disabled?: boolean;
}

/**
 * Reusable form text field component
 * Standardizes input styling and provides consistent onChange handling
 */
const FormTextField: React.FC<FormTextFieldProps> = ({
  label,
  value,
  onChange,
  placeholder = '',
  type = 'text',
  disabled = false
}) => {
  return (
    <FormField label={label}>
      <input 
        type={type}
        className="w-full px-3 py-2 border rounded-lg border-gray-200" 
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      />
    </FormField>
  );
};

export default FormTextField; 