/**
 * Account Settings Component
 * A comprehensive settings interface for managing user account preferences.
 * Features multi-step wizard for profile, security, and team settings.
 * Uses Firebase Authentication and Firestore for data persistence.
 */

import React, { useState } from 'react';
import { User, Shield, Users } from 'lucide-react';
import { useAuth } from '@/hooks/useAuth';
import SettingsWizard from './SettingsWizard';
import ProfileStep from './account/ProfileStep';
import SecurityStep from './account/SecurityStep';
import TeamStep from './account/TeamStep';

/**
 * AccountSettings Component
 * Main settings interface for user account management.
 * Serves as a container for tab-based navigation between different settings sections.
 */
const AccountSettings = () => {
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);

  /**
   * Available steps for the settings wizard
   */
  const steps = [
    { title: 'Profile Information', icon: <User className="w-5 h-5" /> },
    { title: 'Security Settings', icon: <Shield className="w-5 h-5" /> },
    { title: 'Team Management', icon: <Users className="w-5 h-5" /> },
  ];

  return (
    <SettingsWizard
      steps={steps}
      currentStep={currentStep}
      onStepChange={setCurrentStep}
    >
      {currentStep === 0 && (
        <ProfileStep 
          onNext={() => setCurrentStep(1)} 
        />
      )}
      
      {currentStep === 1 && (
        <SecurityStep 
          onNext={() => setCurrentStep(2)}
          onPrevious={() => setCurrentStep(0)}
        />
      )}
      
      {currentStep === 2 && (
        <TeamStep 
          onPrevious={() => setCurrentStep(1)}
          onComplete={() => setCurrentStep(0)}
        />
      )}
    </SettingsWizard>
  );
};

export default AccountSettings;