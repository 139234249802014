import React from 'react';
import { useOnboardingStore } from '@/stores/onboardingStore';
import FormField from '@/components/ui/FormField';

export default function StoreBasicsStep() {
  const { config, updateConfig } = useOnboardingStore();
  const { storeInfo } = config;

  const handleChange = (field: keyof typeof storeInfo, value: string | boolean) => {
    updateConfig('storeInfo', { [field]: value });
  };

  return (
    <div className="space-y-6">
      <FormField label={
        <span className="flex items-center">
          <span className="font-semibold text-blue-700">Store Name</span>
          <span className="ml-2 px-2 py-0.5 bg-blue-100 text-blue-800 text-xs font-medium rounded">Important</span>
        </span>
      }>
        <input
          type="text"
          className="w-full px-4 py-2 border rounded-lg border-blue-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 bg-blue-50"
          placeholder="Enter your store name"
          value={storeInfo.name}
          onChange={(e) => handleChange('name', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          This will be displayed in your chat interface
        </p>
      </FormField>

      <FormField label="Store Hours">
        <textarea
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 h-24 resize-none"
          placeholder="Mon-Fri: 9am-6pm&#10;Sat: 10am-5pm&#10;Sun: Closed"
          value={storeInfo.hours}
          onChange={(e) => handleChange('hours', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          Your store hours will help the AI provide accurate availability information
        </p>
      </FormField>

      {/* Multiple Locations section is hidden but still maintains the data in the store */}
      <div className="hidden">
        <FormField label="Multiple Locations">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              checked={storeInfo.hasMultipleLocations}
              onChange={(e) => handleChange('hasMultipleLocations', e.target.checked)}
            />
            <span className="text-sm text-gray-700">This store has multiple locations</span>
          </label>
        </FormField>
      </div>

      <FormField label="Contact Information">
        <textarea
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 h-24 resize-none"
          placeholder="Store Address:&#10;Phone:&#10;Email:"
          value={storeInfo.contactInfo}
          onChange={(e) => handleChange('contactInfo', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          Enter the information you would like your AI assistant to know about your store. 
          Please note that shoppers will be able to access this information if they ask the AI assistant.
        </p>
      </FormField>

      <div className="bg-blue-50 rounded-lg p-4 mt-6">
        <h4 className="text-sm font-medium text-blue-900 mb-2">Why this information matters</h4>
        <p className="text-sm text-blue-700">
          Your store details help the AI assistant provide accurate information to customers
          and create a more personalized shopping experience. Don't worry about getting everything
          perfect now - you'll be able to change or delete this information anytime in the settings tab
          after completing onboarding.
        </p>
      </div>
    </div>
  );
} 