import React from 'react';
import { Upload, Loader2, RefreshCw } from 'lucide-react';

interface FileUploadProps {
  accept: string;
  onUpload: (file: File) => void;
  currentFile?: string;
  isLoading?: boolean;
}

export function FileUpload({ accept, onUpload, currentFile, isLoading }: FileUploadProps) {
  return (
    <div className="flex flex-col gap-4">
      {currentFile ? (
        <div className="flex flex-col items-center border rounded-lg p-4 bg-white shadow-sm">
          <img
            src={currentFile}
            alt="Uploaded file"
            className="w-24 h-24 object-contain mb-3"
          />
          <label className="flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 cursor-pointer transition-colors">
            <RefreshCw className="w-4 h-4 mr-2" />
            Replace
            <input
              type="file"
              className="hidden"
              accept={accept}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file && !isLoading) {
                  onUpload(file);
                }
              }}
              disabled={isLoading}
            />
          </label>
        </div>
      ) : (
        <div className="flex-1">
          <label className={`flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors ${isLoading ? 'opacity-50 cursor-not-allowed' : 'border-gray-300'}`}>
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              {isLoading ? (
                <Loader2 className="w-8 h-8 text-gray-400 mb-2 animate-spin" />
              ) : (
                <Upload className="w-8 h-8 text-gray-400 mb-2" />
              )}
              <p className="text-sm text-gray-500">
                {isLoading ? 'Uploading...' : 'Click to upload'}
              </p>
              <p className="text-xs text-gray-400">(SVG, PNG, or JPG)</p>
            </div>
            <input
              type="file"
              className="hidden"
              accept={accept}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file && !isLoading) {
                  onUpload(file);
                }
              }}
              disabled={isLoading}
            />
          </label>
        </div>
      )}
    </div>
  );
} 