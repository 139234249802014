import { useState, useCallback } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useToastStore } from '@/stores/toastStore';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';

/**
 * Hook for managing security settings
 * Handles password changes and authentication
 */
export function useSecuritySettings() {
  const { user } = useAuth();
  const { addToast } = useToastStore();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  /**
   * Check if user is authenticated with Google
   * Used to conditionally render password change UI
   */
  const isGoogleUser = user?.providerData[0]?.providerId === 'google.com';

  /**
   * Validates password change requirements
   * Checks length and confirmation match
   */
  const validatePasswordChange = useCallback(() => {
    if (newPassword.length < 8) {
      setPasswordError('New password must be at least 8 characters long');
      return false;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError('New passwords do not match');
      return false;
    }
    return true;
  }, [newPassword, confirmPassword]);

  /**
   * Changes user password in Firebase Auth
   * Requires reauthentication for security
   */
  const handlePasswordChange = useCallback(async () => {
    try {
      setIsChangingPassword(true);
      setPasswordError(null);

      if (!validatePasswordChange()) {
        setIsChangingPassword(false);
        return;
      }

      if (!user) {
        throw new Error('No user logged in');
      }

      // Reauthenticate user before changing password
      const credential = EmailAuthProvider.credential(
        user.email!,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);

      // Update password
      await updatePassword(user, newPassword);

      // Clear form
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');

      addToast('success', 'Password updated successfully');
      return true;
    } catch (error) {
      console.error('Error changing password:', error);
      
      if (error instanceof Error) {
        if (error.message.includes('auth/wrong-password')) {
          setPasswordError('Current password is incorrect');
        } else {
          setPasswordError(error.message);
        }
      } else {
        setPasswordError('Failed to change password');
      }
      
      return false;
    } finally {
      setIsChangingPassword(false);
    }
  }, [user, currentPassword, newPassword, confirmPassword, validatePasswordChange, addToast]);

  return {
    isGoogleUser,
    currentPassword,
    newPassword,
    confirmPassword,
    passwordError,
    isChangingPassword,
    setCurrentPassword,
    setNewPassword,
    setConfirmPassword,
    handlePasswordChange
  };
} 