import { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '@/config/firebase';

interface ProfileData {
  displayName: string;
  phoneNumber: string;
  jobTitle: string;
}

interface ProfileState {
  data: ProfileData;
  isLoading: boolean;
  isSaving: boolean;
  error: string | null;
}

/**
 * Hook for managing user profile data
 * Handles loading, updating, and saving profile information
 */
export function useProfileManagement(userId: string | undefined) {
  const [state, setState] = useState<ProfileState>({
    data: {
      displayName: '',
      phoneNumber: '',
      jobTitle: ''
    },
    isLoading: false,
    isSaving: false,
    error: null
  });

  /**
   * Loads profile data from Firestore
   */
  const loadProfile = useCallback(async () => {
    if (!userId) return;
    
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      
      // Get the merchant document
      const merchantDoc = await getDoc(doc(db, 'merchants', userId));
      
      // Initialize with empty data
      let profileData = {
        displayName: '',
        phoneNumber: '',
        jobTitle: ''
      };
      
      // If document exists, populate with data
      if (merchantDoc.exists()) {
        const data = merchantDoc.data();
        profileData = {
          displayName: data.displayName || '',
          phoneNumber: data.phoneNumber || '',
          jobTitle: data.jobTitle || ''
        };
      }
      
      setState(prev => ({ 
        ...prev, 
        data: profileData, 
        isLoading: false 
      }));
    } catch (error) {
      console.error('Error loading profile:', error);
      setState(prev => ({ 
        ...prev, 
        isLoading: false, 
        error: error instanceof Error ? error.message : 'Failed to load profile' 
      }));
    }
  }, [userId]);

  /**
   * Loads profile on component mount and userId change
   */
  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  /**
   * Updates a single profile field
   */
  const updateProfileField = (field: keyof ProfileData, value: string) => {
    setState(prev => ({
      ...prev,
      data: { 
        ...prev.data, 
        [field]: value 
      }
    }));
  };

  /**
   * Saves profile data to Firestore
   */
  const saveProfile = async (): Promise<boolean> => {
    if (!userId) return false;
    
    try {
      setState(prev => ({ ...prev, isSaving: true, error: null }));
      
      // Get existing merchant data first
      const merchantRef = doc(db, 'merchants', userId);
      const merchantDoc = await getDoc(merchantRef);
      const existingData = merchantDoc.exists() ? merchantDoc.data() : {};
      
      // Save profile data, preserving existing fields
      await setDoc(merchantRef, {
        ...existingData,
        displayName: state.data.displayName,
        phoneNumber: state.data.phoneNumber,
        jobTitle: state.data.jobTitle,
        updatedAt: new Date().toISOString()
      }, { merge: true });
      
      setState(prev => ({ ...prev, isSaving: false }));
      return true;
    } catch (error) {
      console.error('Error saving profile:', error);
      setState(prev => ({ 
        ...prev, 
        isSaving: false, 
        error: error instanceof Error ? error.message : 'Failed to save profile' 
      }));
      return false;
    }
  };

  return {
    profileData: state.data,
    isLoading: state.isLoading,
    isSaving: state.isSaving,
    error: state.error,
    updateProfileField,
    saveProfile,
    reloadProfile: loadProfile
  };
} 