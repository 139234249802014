/**
 * Settings Wizard Component
 * A multi-step wizard interface for settings configuration.
 * Features a navigation bar with icons and step titles.
 * Uses Tailwind CSS for styling and transitions.
 */

import React from 'react';

/**
 * Step Interface
 * Defines the structure of each step in the wizard
 * @interface Step
 * @property {string} title - The text label for the step
 * @property {React.ReactNode} icon - Icon component to display with the step
 */
interface Step {
  title: string;
  icon: React.ReactNode;
}

/**
 * Props for the SettingsWizard component
 * @interface SettingsWizardProps
 * @property {Step[]} steps - Array of step objects defining the wizard progression
 * @property {number} currentStep - Zero-based index of the current active step
 * @property {Function} onStepChange - Callback when a step is selected
 * @property {React.ReactNode} children - Content to be rendered below the step navigation
 */
interface SettingsWizardProps {
  steps: Step[];
  currentStep: number;
  onStepChange: (step: number) => void;
  children: React.ReactNode;
}

/**
 * SettingsWizard Component
 * Renders a wizard interface with step navigation and content area.
 * Features:
 * - Interactive step navigation with icons
 * - Visual indication of current step
 * - Hover effects for step buttons
 * - Flexible content rendering
 * 
 * @example
 * ```tsx
 * <SettingsWizard
 *   steps={[
 *     { title: 'General', icon: <CogIcon /> },
 *     { title: 'Profile', icon: <UserIcon /> }
 *   ]}
 *   currentStep={0}
 *   onStepChange={handleStepChange}
 * >
 *   <StepContent />
 * </SettingsWizard>
 * ```
 */
const SettingsWizard: React.FC<SettingsWizardProps> = ({
  steps,
  currentStep,
  onStepChange,
  children
}) => {
  return (
    <div className="space-y-8">
      {/* Step navigation bar */}
      <div className="flex items-center justify-between">
        <nav className="flex space-x-4">
          {steps.map((step, index) => (
            <button
              key={step.title}
              onClick={() => onStepChange(index)}
              className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                currentStep === index
                  ? 'bg-primary/10 text-primary'
                  : 'text-text/50 hover:text-text hover:bg-gray-50'
              }`}
            >
              {/* Step icon */}
              {step.icon}
              {/* Step title */}
              <span className="text-sm font-medium">{step.title}</span>
            </button>
          ))}
        </nav>
      </div>

      {/* Step content */}
      {children}
    </div>
  );
};

export default SettingsWizard; 