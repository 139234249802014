/**
 * Card Component
 * A simple container component with consistent styling
 * Provides elevation and border styling using Tailwind CSS
 */

import React from 'react';

/**
 * Card Props Interface
 * @property {React.ReactNode} children - Content to be rendered inside the card
 * @property {string} [className] - Additional CSS classes to apply
 */
interface CardProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * Card Component
 * Renders a styled container with shadow and rounded corners
 * 
 * @example
 * <Card className="p-4">
 *   <h2>Card Title</h2>
 *   <p>Card content</p>
 * </Card>
 */
const Card: React.FC<CardProps> = ({ children, className = '' }) => {
  return (
    <div className={`bg-white rounded-lg shadow-md border border-gray-200 ${className}`}>
      {children}
    </div>
  );
};

export default Card;