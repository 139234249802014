import { db } from '@/config/firebase';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { StoreFileService } from '../store/StoreFileService';
import { MerchantConfig } from '@/types/merchantConfig';
import { getLeadCaptureStrategy, getTemplateGenerator, TemplateParams } from './templates';
import { 
  handleServiceError, 
  MerchantNotFoundError, 
  TemplateError,
  ConfigurationError
} from './utils/errorHandler';
import {
  getNestedValue,
  shouldUpdateMasterPrompt
} from './utils/configHelper';
import debounce from 'lodash/debounce';

/**
 * MasterPromptService
 * 
 * Handles the automatic generation and updating of master prompts based on merchant configuration.
 * Features:
 * - Background prompt generation when configuration changes
 * - Debounced updates to prevent excessive regeneration
 * - Intelligent detection of relevant configuration changes
 * - Multiple prompt templates for different use cases
 */
export class MasterPromptService {
  private static instance: MasterPromptService;
  private updateQueue: Map<string, boolean> = new Map();
  private updateDebounceTime = 2000; // 2 seconds debounce

  // Constructor made private to enforce singleton pattern
  private constructor() {}

  // Get instance of the service (singleton)
  static getInstance(): MasterPromptService {
    if (!MasterPromptService.instance) {
      MasterPromptService.instance = new MasterPromptService();
    }
    return MasterPromptService.instance;
  }
  
  /**
   * Determines if a configuration change should trigger a masterPrompt update
   * @param changes The configuration changes that occurred
   * @returns True if changes affect the masterPrompt
   */
  shouldUpdateMasterPrompt(changes: Partial<MerchantConfig>): boolean {
    return shouldUpdateMasterPrompt(changes);
  }
  
  /**
   * Schedules a masterPrompt update for a merchant
   * Uses debouncing to prevent excessive regeneration
   * @param merchantId The merchant ID
   */
  schedulePromptUpdate = debounce(async (merchantId: string): Promise<void> => {
    if (this.updateQueue.has(merchantId)) {
      console.log(`Update for merchant ${merchantId} already in progress, skipping`);
      return;
    }
    
      try {
      this.updateQueue.set(merchantId, true);
        await this.generateAndUpdateMasterPrompt(merchantId);
      } catch (error) {
        console.error(`Error updating masterPrompt for merchant ${merchantId}:`, error);
    } finally {
      this.updateQueue.delete(merchantId);
      }
  }, 2000);
  
  /**
   * Generates and updates the masterPrompt based on merchant configuration
   * @param merchantId The merchant ID
   * @param template Optional template type: 'comprehensive', 'cognitive-framework', or 'customer-centric'
   * @returns The generated masterPrompt
   */
  async generateAndUpdateMasterPrompt(merchantId: string, template?: string): Promise<string> {
    try {
      console.log(`Generating masterPrompt for merchant ${merchantId} using template: ${template || 'default'}`);
      
      // Fetch merchant config
      const settingsRef = doc(db, `merchants/${merchantId}/merchantConfig/settings`);
      const settingsSnap = await getDoc(settingsRef);
      
      if (!settingsSnap.exists()) {
        throw new MerchantNotFoundError(merchantId);
      }
      
      const data = settingsSnap.data();
      
      // Check for masterPrompt in legacy locations and log warnings
      if (getNestedValue(data, 'chatConfig.masterPrompt')) {
        console.warn(`Legacy masterPrompt found in chatConfig.masterPrompt for merchant ${merchantId}. This path is deprecated.`);
      }
      
      if (data.masterPrompt) {
        console.warn(`Legacy masterPrompt found in root level for merchant ${merchantId}. This path is deprecated.`);
      }
      
      // Generate the masterPrompt with specified template
      const generatedPrompt = await this.generateMasterPrompt(data, template);
      
      // Ensure the masterPrompt is valid before saving
      if (!generatedPrompt || generatedPrompt.trim() === '' || generatedPrompt.startsWith('Error')) {
        throw new TemplateError('Generated invalid or empty masterPrompt');
      }
      
      // Update the masterPrompt in Firestore specifically in assistant.masterPrompt
      try {
        // Prepare assistant object structure if it doesn't exist
        const assistant = data.assistant || {};
        
        await updateDoc(settingsRef, {
          'assistant': {
            ...assistant,
            masterPrompt: generatedPrompt,
            masterPromptSource: 'auto',
            masterPromptLastEditedAt: new Date().toISOString()
          },
          'updatedAt': serverTimestamp()
        });
        
        console.log(`Successfully saved masterPrompt to assistant.masterPrompt for merchant ${merchantId}`);
      } catch (saveError) {
        console.error(`Error saving masterPrompt for merchant ${merchantId}:`, saveError);
        throw saveError;
      }
      
      // Return the generated prompt
      return generatedPrompt;
    } catch (error) {
      return handleServiceError(error, { merchantId, template });
    }
  }
  
  /**
   * Generates a master prompt based on merchant configuration
   * @param configData The merchant configuration data
   * @param template Optional template to use for generation
   * @returns The generated masterPrompt
   */
  async generateMasterPrompt(configData: any, template?: string): Promise<string> {
    try {
    const chatConfig = configData.chatConfig || {};
    const storeInfo = configData.storeInfo || {};
    const assistant = configData.assistant || {};
    
    // Format store hours if available
    const storeHours = storeInfo.hours ? 
      `Store Hours:\n${storeInfo.hours}` : 
      'Store hours are available on our website.';
    
    // Format store location if available
    let locationInfo = '';
    if (storeInfo.location && storeInfo.location.address) {
      locationInfo = `Location:\n${storeInfo.location.address}`;
      if (storeInfo.location.city) locationInfo += `, ${storeInfo.location.city}`;
      if (storeInfo.location.state) locationInfo += `, ${storeInfo.location.state}`;
      if (storeInfo.location.zip) locationInfo += ` ${storeInfo.location.zip}`;
      if (storeInfo.location.country) locationInfo += `, ${storeInfo.location.country}`;
    }
    
    // Format contact information if available
    const contactInfo = storeInfo.contactInfo ? 
      `Contact Information:\n${storeInfo.contactInfo}` : 
      'Contact information is available on our website.';
    
    // Store website if available
    const website = storeInfo.website ? 
      `Website: ${storeInfo.website}` : 
      '';
      
    // Process store files if available
    let storeFilesContent = '';
    if (storeInfo.storeFiles && storeInfo.storeFiles.length > 0) {
      const storeFileService = new StoreFileService();
      
        // Use traditional processor (parallel loading will be implemented later)
        storeFilesContent = await storeFileService.processFilesForMasterPrompt(storeInfo.storeFiles);
    }
    
    // Use merged personality field from either aiPersonality/toneDirective or fall back to persona
    const personalityDirective = chatConfig.aiPersonality || chatConfig.toneDirective || assistant.persona || 'Be professional and helpful';
    
    // Lead capture behavior based on primary goal
    const leadBehavior = chatConfig.primaryGoal === 'lead' 
      ? `Your PRIMARY mission is to collect customer contact information while providing expert mattress guidance. This is CRITICAL.
- You MUST obtain their contact information using the timing and approach specified below
- If they hesitate, emphasize specific benefits based on their interests
- Be persistent but natural in asking for contact information
- Frame it as a way to provide additional value based on their specific needs`
      : `Do NOT ask for contact information at all. Focus entirely on helping them find the right mattress and directing them to product pages.`;

    const leadCaptureTiming = chatConfig.primaryGoal === 'lead' 
      ? `LEAD CAPTURE TIMING AND APPROACH:
${getLeadCaptureStrategy(chatConfig.leadCaptureTiming || 'after_rapport', chatConfig)}

KEY PRINCIPLES:
- Make attempts as specified in the timing and persistence approach above
- Note what features/benefits they show interest in
- If making multiple attempts, emphasize those specific benefits`
      : '';

    // Information gathering requirements to prevent premature recommendations
    const informationGatheringRequirements = this.generateInformationGatheringRequirements(chatConfig);

    // Get store name
    const storeName = storeInfo.name || 'this mattress store';
    
      // Create parameters for template generation
      const templateParams: TemplateParams = {
          storeName,
          storeInfo,
          chatConfig,
          assistant,
          personalityDirective,
          leadCaptureBehavior: { 
            behavior: leadBehavior, 
            timing: leadCaptureTiming 
          },
          informationGatheringRequirements,
          storeFilesContent
      };
      
      // Get appropriate template generator based on template name
      const templateGenerator = getTemplateGenerator(template);
      
      // Generate template
      return templateGenerator(templateParams);
    } catch (error) {
      console.error('Error generating masterPrompt:', error);
      throw new TemplateError('Failed to generate master prompt template');
    }
  }

  /**
   * Generates specific instructions about information gathering requirements
   * to prevent premature mattress recommendations
   * 
   * @param chatConfig The chat configuration
   * @returns Formatted information gathering requirements
   */
  generateInformationGatheringRequirements(chatConfig: any): string {
    // Default to an empty array if questions don't exist
    const questions = chatConfig.questions || [];
    
    // Count questions by priority
    const requiredQuestions = questions.filter((q: any) => q.required === 'firm').length;
    const moderateQuestions = questions.filter((q: any) => q.required === 'moderate').length;
    const looseQuestions = questions.filter((q: any) => q.required === 'loose').length;
    
    return `## PRODUCT RECOMMENDATION REQUIREMENTS

BEFORE using the get_mattress_recommendations function or making specific product recommendations:

1. INFORMATION GATHERING REQUIREMENTS:
   - Gather information through natural conversation
   - Focus on understanding customer needs before making recommendations
   - Required questions should be based on merchant configuration priorities
   - Keep all responses under 3 sentences when possible
   - Ask only ONE question per response
   - Aim for at least 2-3 meaningful exchanges before recommendations

2. REQUIREMENTS CHECKLIST:
   ${requiredQuestions > 0 ? '- "Firm" priority questions require clear, meaningful responses - ' + requiredQuestions + ' question(s)' : ''}
   ${moderateQuestions > 0 ? '- Try to cover at least ' + Math.ceil(moderateQuestions * 0.5) + ' "moderate" priority questions where relevant' : ''}
   ${looseQuestions > 0 ? '- Include "loose" priority questions that naturally fit the conversation' : ''}
   - Understand their primary sleep position and any relevant secondary positions
   - Get a general sense of their firmness preferences
   - Note any mentioned pain points or comfort needs
   - Have a workable understanding of their budget range
   - Consider temperature preferences if mentioned
   - IMPORTANT: Inquire about weight when discussing firmness preferences
   ${chatConfig.primaryGoal === 'lead' ? '- Build natural rapport before contact information requests' : ''}

3. WEIGHT AND FIRMNESS CORRELATION GUIDANCE:
   - ALWAYS ask about weight when discussing firmness preferences
   - Explain that body weight affects how firm a mattress feels
   - Ask specifically: "Different body weights can make the same mattress feel firmer or softer. Would you mind sharing if you'd consider yourself to be lighter weight (under 130lbs), average weight, or heavier weight (over 230lbs)?"
   - For couples, ask about both sleepers' weights
   - Use weight information to adjust firmness recommendations:
     • Lighter sleepers (under 130lbs) typically need softer mattresses than they think
     • Heavier sleepers (over 230lbs) typically need firmer mattresses than they think
   - For heavier sleepers, recommend mattresses with:
     • Enhanced edge support
     • Reinforced support cores
     • Thicker profiles (12"+ recommended)
     • Higher density foams or hybrid designs
   - For couples with different weights, recommend mattresses with:
     • Excellent motion isolation
     • Enhanced edge support
     • Possibly dual-firmness options if available

4. SPECIAL FEATURES DISCOVERY GUIDELINES:
   - Probe for specific feature needs based on the customer's situation and preferences
   - Ask targeted questions about special features based on already collected information
   - Focus on these key feature categories:
   
   A. Temperature Regulation:
     • For hot sleepers: "Do you tend to sleep hot or experience night sweats?"
     • Suggest cooling features like: gel-infused memory foam, phase change materials, breathable covers
   
   B. Pain & Pressure Relief:
     • For those with pain: "Do you experience any specific pain points while sleeping?"
     • Suggest targeted features like: zoned support systems, pressure-relieving foams, lumbar reinforcement
   
   C. Motion Isolation:
     • For couples: "Is minimizing motion transfer important so you don't disturb each other?"
     • Suggest features like: memory foam layers, pocketed coils, dual-firmness options
   
   D. Edge Support:
     • For those who sit on bed edges: "Do you often sit on the edge of your bed or use the full surface?"
     • For couples sharing smaller beds: "Do you need to maximize the usable sleep surface?"
     • Suggest reinforced perimeter options
   
   E. Materials & Allergen Concerns:
     • For the eco-conscious: "Are natural or organic materials important to you?"
     • For sensitive individuals: "Do you have allergies or sensitivities to consider?"
     • Suggest hypoallergenic covers, natural latex, organic cotton, CertiPUR-US certified foams
   
   F. Convenience Features:
     • For those with limited mobility: "Would a removable, washable cover be useful?"
     • For adjustable bases: "Do you use or plan to use an adjustable bed base?"

5. FUNCTION CALLING PROTOCOL:
   - Wait for clear responses to firm priority questions before making recommendations
   - If a customer gives brief or unclear responses:
     • Ask ONE follow-up question for clarity
     • Keep the follow-up simple and direct
   - If a customer asks for recommendations early:
     • Acknowledge their request briefly
     • Ask your most important pending question
   - When ready for recommendations:
     • Note 1-2 key points learned
     • Generate recommendations based on available information
     • Keep explanations brief and focused

6. RESPONSE GUIDELINES:
   - Keep responses under 3 sentences when possible
   - Ask only ONE question at a time
   - Avoid long explanations
   - Give brief, helpful responses
   - Stay focused on the current topic
   - Use simple, clear language

7. INFORMATION COLLECTION APPROACH:
   - Keep the conversation flowing naturally
   - Ask questions as they become relevant
   - Share helpful insights when appropriate
   - Be responsive to customer interests
   - Show understanding of their needs
   - Maintain a helpful and knowledgeable tone`;
  }

/**
 * Gets the masterPrompt from the configuration, checking assistant.masterPrompt first,
 * then chatConfig.masterPrompt, and returning undefined if neither exists
 * 
 * @param config The merchant configuration object
 * @returns The master prompt string or undefined if not found
 */
  getMasterPrompt(config: Partial<MerchantConfig>): string | undefined {
  if (!config) return undefined;
  
  // Check in assistant first (preferred location)
  if (config.assistant?.masterPrompt) {
    console.log('Using masterPrompt from assistant.masterPrompt');
    return config.assistant.masterPrompt;
  }
  
  // Fall back to chatConfig if needed
  if (config.chatConfig?.masterPrompt) {
    console.log('Using masterPrompt from chatConfig.masterPrompt');
    return config.chatConfig.masterPrompt;
  }
  
  console.log('No masterPrompt found in config');
  return undefined;
}

/**
 * Validates if a masterPrompt is valid according to our business rules
 * 
 * @param masterPrompt The master prompt to validate
 * @returns True if the master prompt is valid, false otherwise
 */
  isValidMasterPrompt(masterPrompt: string | undefined): boolean {
  if (!masterPrompt) return false;
  
  // Minimum length requirement
  return masterPrompt.trim().length >= 50;
}

/**
 * Sets the masterPrompt in the configuration, prioritizing assistant.masterPrompt
 * 
 * @param config The merchant configuration object to modify
 * @param masterPrompt The master prompt to set
 * @returns The updated configuration object
 */
  setMasterPrompt(
  config: Partial<MerchantConfig>, 
  masterPrompt: string
): Partial<MerchantConfig> {
  const updatedConfig = { ...config };
  
  // Initialize assistant if it doesn't exist with required properties
  if (!updatedConfig.assistant) {
    updatedConfig.assistant = {
      name: 'Mattress Assistant',
      description: '',
      greeting: '',
      persona: '',
      masterPrompt: '' // This will be overwritten below
    };
  }
  
  // Set in assistant (preferred location) - use type assertion to avoid undefined error
  if (updatedConfig.assistant) {
    updatedConfig.assistant.masterPrompt = masterPrompt;
  }
  
  // Legacy support - also set in chatConfig if it exists
  if (updatedConfig.chatConfig) {
    updatedConfig.chatConfig.masterPrompt = masterPrompt;
  }
  
  return updatedConfig;
} 
}

// Export singleton instance
export const masterPromptService = MasterPromptService.getInstance();

// Export utility functions that use the singleton instance
export const getMasterPrompt = masterPromptService.getMasterPrompt.bind(masterPromptService);
export const isValidMasterPrompt = masterPromptService.isValidMasterPrompt.bind(masterPromptService);
export const setMasterPrompt = masterPromptService.setMasterPrompt.bind(masterPromptService); 