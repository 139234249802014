/**
 * Custom error classes for the MasterPromptService
 */

/**
 * Base error class for MasterPromptService errors
 */
export class MasterPromptError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MasterPromptError';
  }
}

/**
 * Error for configuration issues
 */
export class ConfigurationError extends MasterPromptError {
  constructor(message: string) {
    super(message);
    this.name = 'ConfigurationError';
  }
}

/**
 * Error for template generation issues
 */
export class TemplateError extends MasterPromptError {
  constructor(message: string) {
    super(message);
    this.name = 'TemplateError';
  }
}

/**
 * Error for file processing issues
 */
export class FileProcessingError extends MasterPromptError {
  constructor(message: string, public readonly fileUrl?: string) {
    super(message);
    this.name = 'FileProcessingError';
  }
}

/**
 * Error thrown when a merchant configuration is not found
 */
export class MerchantNotFoundError extends MasterPromptError {
  constructor(merchantId: string) {
    super(`No configuration found for merchant ${merchantId}`);
    this.name = 'MerchantNotFoundError';
  }
}

/**
 * Central error handler for MasterPromptService
 * Logs errors and formats user-friendly messages
 * 
 * @param error The error that was caught
 * @param context Additional context for the error
 * @returns A user-friendly error message
 */
export function handleServiceError(error: unknown, context: Record<string, any> = {}): string {
  // Get error details
  const errorDetails = getErrorDetails(error);
  
  // Log the error with context
  console.error(`MasterPromptService error: ${errorDetails.message}`, {
    name: errorDetails.name,
    context,
    stack: errorDetails.stack
  });
  
  // Return user-friendly message based on error type
  if (error instanceof MerchantNotFoundError) {
    return 'Error: Merchant configuration not found. Please set up your store details first.';
  }
  
  if (error instanceof ConfigurationError) {
    return `Configuration error: ${errorDetails.message}`;
  }
  
  if (error instanceof TemplateError) {
    return 'Error generating prompt template. Please try again or contact support.';
  }
  
  if (error instanceof FileProcessingError) {
    return 'Error processing store files. Please check your uploaded files and try again.';
  }
  
  // Generic error message for unexpected errors
  return 'An unexpected error occurred while generating the master prompt. Please try again.';
}

/**
 * Safely extracts error details from any error type
 */
function getErrorDetails(error: unknown): { name: string; message: string; stack?: string } {
  if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack
    };
  }
  
  if (typeof error === 'string') {
    return {
      name: 'Error',
      message: error
    };
  }
  
  return {
    name: 'Unknown Error',
    message: String(error)
  };
} 