import React from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createMUITheme } from '../theme';
import { useTheme } from '../hooks/useTheme';
import { DEFAULT_THEME } from '../types/config';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { theme, isLoading } = useTheme();
  
  // Use default theme while loading or if theme is undefined
  const currentTheme = isLoading || !theme ? DEFAULT_THEME : theme;
  const muiTheme = React.useMemo(() => createMUITheme(currentTheme), [currentTheme]);

  // Apply theme CSS variables for Tailwind
  React.useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--primary-color', currentTheme.primary);
    root.style.setProperty('--secondary-color', currentTheme.secondary);
    root.style.setProperty('--background-color', currentTheme.background);
    root.style.setProperty('--text-color', currentTheme.text);
    root.style.setProperty('--accent-color', currentTheme.accent);
  }, [currentTheme]);

  if (isLoading) {
    return (
      <MUIThemeProvider theme={muiTheme}>
        <CssBaseline />
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
        </div>
      </MUIThemeProvider>
    );
  }

  return (
    <MUIThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}; 