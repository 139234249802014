import React from 'react';
import { X, Minus, MessageSquare } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface PreviewBranding {
  colors: {
    primary: string;
    secondary: string;
  };
  logo?: string;
}

interface PreviewConfig {
  storeInfo: {
    name: string;
    hours: string;
    hasMultipleLocations: boolean;
    contactInfo: string;
  };
  welcomeMessage: string;
  aiPersonality: string;
  conversationStyle: string;
  leadCaptureTiming: string;
  primaryGoal: string;
  educationTopics: string;
  responseCreativity: number;
  productRecommendationLimit: number;
  questions: any[];
  model: string;
  temperature: number;
}

interface ChatPreviewProps {
  isOpen: boolean;
  onClose: () => void;
  branding: PreviewBranding;
  config: PreviewConfig;
}

export default function ChatPreview({ isOpen, onClose, branding, config }: ChatPreviewProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="bg-white rounded-lg shadow-2xl w-full max-w-sm overflow-hidden"
          >
            {/* Chat Header */}
            <div 
              className="flex items-center justify-between p-4"
              style={{ backgroundColor: branding.colors.primary }}
            >
              <div className="flex items-center space-x-2">
                {branding.logo ? (
                  <img
                    src={branding.logo}
                    alt="Store logo"
                    className="w-6 h-6 rounded"
                  />
                ) : (
                  <MessageSquare className="w-6 h-6 text-white" />
                )}
                <h3 className="text-white font-medium">{config.storeInfo.name || 'Chat Assistant'}</h3>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={onClose}
                  className="p-1 hover:bg-white/10 rounded transition-colors"
                >
                  <Minus className="w-4 h-4 text-white" />
                </button>
                <button
                  onClick={onClose}
                  className="p-1 hover:bg-white/10 rounded transition-colors"
                >
                  <X className="w-4 h-4 text-white" />
                </button>
              </div>
            </div>

            {/* Chat Messages */}
            <div className="p-4 h-96 overflow-y-auto space-y-4">
              {/* Assistant Message */}
              <div className="flex items-start space-x-2">
                {branding.logo ? (
                  <img
                    src={branding.logo}
                    alt="Assistant"
                    className="w-8 h-8 rounded mt-1"
                  />
                ) : (
                  <div 
                    className="w-8 h-8 rounded mt-1 flex items-center justify-center"
                    style={{ backgroundColor: branding.colors.primary }}
                  >
                    <MessageSquare className="w-5 h-5 text-white" />
                  </div>
                )}
                <div className="flex-1">
                  <div className="bg-gray-100 rounded-lg p-3 inline-block">
                    <p className="text-sm text-gray-800">
                      {config.welcomeMessage}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Chat Input */}
            <div className="p-4 border-t">
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  placeholder="Type your message..."
                  className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2"
                  style={{ 
                    borderColor: branding.colors.primary,
                    '--tw-ring-color': branding.colors.primary
                  } as React.CSSProperties}
                  disabled
                />
                <button
                  className="p-2 rounded-lg text-white"
                  style={{ backgroundColor: branding.colors.primary }}
                  disabled
                >
                  Send
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
} 