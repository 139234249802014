import { useState, useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useToastStore } from '@/stores/toastStore';
import { teamManagementService, TeamMember } from '@/services/teamManagement';

/**
 * Hook for managing team members
 * Handles invitations, role changes, and member removal
 */
export function useTeamManagement() {
  const { user } = useAuth();
  const { addToast } = useToastStore();
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState<TeamMember['role']>('staff');
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isLoadingTeam, setIsLoadingTeam] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [teamError, setTeamError] = useState<string | null>(null);

  /**
   * Loads team members from Firestore
   * Updates local state with team data
   */
  useEffect(() => {
    const loadTeamMembers = async () => {
      if (!user) return;

      try {
        setIsLoadingTeam(true);
        setTeamError(null);
        
        const members = await teamManagementService.getTeamMembers(user.uid);
        setTeamMembers(members);
      } catch (error) {
        console.error('Error loading team members:', error);
        setTeamError('Failed to load team members');
      } finally {
        setIsLoadingTeam(false);
      }
    };

    loadTeamMembers();
  }, [user]);

  /**
   * Sends team member invitation
   * Creates pending member record and sends email
   */
  const handleInvite = async () => {
    if (!user || !inviteEmail) return;

    try {
      setIsInviting(true);
      setTeamError(null);

      await teamManagementService.inviteTeamMember(user.uid, inviteEmail, inviteRole);
      
      // Refresh team members list
      const members = await teamManagementService.getTeamMembers(user.uid);
      setTeamMembers(members);

      addToast('success', 'Invitation sent successfully');
      setShowInviteForm(false);
      setInviteEmail('');
      setInviteRole('staff');
    } catch (error) {
      console.error('Error inviting team member:', error);
      
      if (error instanceof Error) {
        setTeamError(error.message);
      } else {
        setTeamError('Failed to send invitation');
      }
    } finally {
      setIsInviting(false);
    }
  };

  /**
   * Updates team member role
   */
  const handleRoleChange = async (memberId: string, newRole: string) => {
    if (!user) return;

    try {
      setTeamError(null);
      await teamManagementService.updateTeamMemberRole(user.uid, memberId, newRole as TeamMember['role']);
      
      // Update local state
      setTeamMembers(members => 
        members.map(member => 
          member.id === memberId ? { ...member, role: newRole as TeamMember['role'] } : member
        )
      );

      addToast('success', 'Team member role updated');
    } catch (error) {
      console.error('Error updating role:', error);
      
      if (error instanceof Error) {
        setTeamError(error.message);
      } else {
        setTeamError('Failed to update role');
      }
    }
  };

  /**
   * Removes team member from organization
   */
  const handleRemoveTeamMember = async (memberId: string) => {
    if (!user) return;

    if (!window.confirm('Are you sure you want to remove this team member?')) {
      return;
    }

    try {
      setTeamError(null);
      await teamManagementService.removeTeamMember(user.uid, memberId);
      
      // Update local state
      setTeamMembers(members => members.filter(member => member.id !== memberId));
      addToast('success', 'Team member removed');
    } catch (error) {
      console.error('Error removing team member:', error);
      
      if (error instanceof Error) {
        setTeamError(error.message);
      } else {
        setTeamError('Failed to remove team member');
      }
    }
  };

  /**
   * Resends invitation email to pending team member
   */
  const handleResendInvite = async (email: string) => {
    if (!user) return;

    try {
      setTeamError(null);
      await teamManagementService.resendInvitation(user.uid, email);
      addToast('success', 'Invitation resent successfully');
    } catch (error) {
      console.error('Error resending invitation:', error);
      
      if (error instanceof Error) {
        setTeamError(error.message);
      } else {
        setTeamError('Failed to resend invitation');
      }
    }
  };

  return {
    teamMembers,
    showInviteForm,
    inviteEmail,
    inviteRole,
    isLoadingTeam,
    isInviting,
    teamError,
    setShowInviteForm,
    setInviteEmail,
    setInviteRole,
    handleInvite,
    handleRoleChange,
    handleRemoveTeamMember,
    handleResendInvite
  };
} 