import { getAuth } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface ErrorDetails {
  message: string;
  stack?: string;
  componentStack?: string;
  url: string;
  timestamp: string;
  userId?: string;
  metadata?: Record<string, any>;
}

/**
 * Service to handle error monitoring and reporting
 */
export class ErrorMonitoringService {
  private static instance: ErrorMonitoringService;
  private isInitialized = false;
  private captureConsoleErrors = true;
  private captureUnhandledRejections = true;
  private captureUncaughtExceptions = true;
  private environmentName = process.env.NODE_ENV || 'development';
  private applicationVersion = process.env.REACT_APP_VERSION || '0.0.0';
  
  /**
   * Get the singleton instance
   */
  public static getInstance(): ErrorMonitoringService {
    if (!ErrorMonitoringService.instance) {
      ErrorMonitoringService.instance = new ErrorMonitoringService();
    }
    return ErrorMonitoringService.instance;
  }
  
  /**
   * Private constructor to enforce singleton pattern
   */
  private constructor() {}
  
  /**
   * Initialize error monitoring
   */
  public initialize(): void {
    if (this.isInitialized) {
      console.debug('Error monitoring already initialized');
      return;
    }
    
    if (this.captureConsoleErrors) {
      this.overrideConsoleError();
    }
    
    if (this.captureUnhandledRejections) {
      window.addEventListener('unhandledrejection', this.handleUnhandledRejection);
    }
    
    if (this.captureUncaughtExceptions) {
      window.addEventListener('error', this.handleUncaughtException);
    }
    
    console.debug('Error monitoring initialized');
    this.isInitialized = true;
  }
  
  /**
   * Set the environment name
   */
  public setEnvironment(name: string): void {
    this.environmentName = name;
  }
  
  /**
   * Set the application version
   */
  public setVersion(version: string): void {
    this.applicationVersion = version;
  }
  
  /**
   * Capture and report an error
   */
  public captureError(error: Error, metadata?: Record<string, any>): void {
    const errorDetails: ErrorDetails = {
      message: error.message,
      stack: error.stack,
      url: window.location.href,
      timestamp: new Date().toISOString(),
      metadata
    };
    
    // Add user ID if available
    const auth = getAuth();
    if (auth.currentUser) {
      errorDetails.userId = auth.currentUser.uid;
    }
    
    // Report to console
    console.debug('Captured error:', errorDetails);
    
    // Report to Firebase
    this.reportToFirebase(errorDetails);
  }
  
  /**
   * Capture and report a React error boundary error
   */
  public captureReactError(error: Error, componentStack: string, metadata?: Record<string, any>): void {
    const errorDetails: ErrorDetails = {
      message: error.message,
      stack: error.stack,
      componentStack,
      url: window.location.href,
      timestamp: new Date().toISOString(),
      metadata
    };
    
    // Add user ID if available
    const auth = getAuth();
    if (auth.currentUser) {
      errorDetails.userId = auth.currentUser.uid;
    }
    
    // Report to console
    console.debug('Captured React error:', errorDetails);
    
    // Report to Firebase
    this.reportToFirebase(errorDetails);
  }
  
  /**
   * Override console.error to capture errors
   */
  private overrideConsoleError(): void {
    const originalConsoleError = console.error;
    console.error = (...args: any[]) => {
      // Call original console.error
      originalConsoleError.apply(console, args);
      
      // Process arguments
      const errorMessage = args.map(arg => {
        if (arg instanceof Error) {
          return `${arg.name}: ${arg.message}`;
        } else if (typeof arg === 'object') {
          try {
            return JSON.stringify(arg);
          } catch (e) {
            return String(arg);
          }
        } else {
          return String(arg);
        }
      }).join(' ');
      
      // Create error details
      const errorDetails: ErrorDetails = {
        message: `Console Error: ${errorMessage}`,
        url: window.location.href,
        timestamp: new Date().toISOString()
      };
      
      // Add user ID if available
      const auth = getAuth();
      if (auth.currentUser) {
        errorDetails.userId = auth.currentUser.uid;
      }
      
      // Report to Firebase
      this.reportToFirebase(errorDetails);
    };
  }
  
  /**
   * Handle unhandled promise rejections
   */
  private handleUnhandledRejection = (event: PromiseRejectionEvent): void => {
    const error = event.reason;
    const errorDetails: ErrorDetails = {
      message: error instanceof Error ? error.message : String(error),
      stack: error instanceof Error ? error.stack : undefined,
      url: window.location.href,
      timestamp: new Date().toISOString()
    };
    
    // Add user ID if available
    const auth = getAuth();
    if (auth.currentUser) {
      errorDetails.userId = auth.currentUser.uid;
    }
    
    // Report to console
    console.debug('Unhandled rejection captured:', errorDetails);
    
    // Report to Firebase
    this.reportToFirebase(errorDetails);
  };
  
  /**
   * Handle uncaught exceptions
   */
  private handleUncaughtException = (event: ErrorEvent): void => {
    const errorDetails: ErrorDetails = {
      message: event.message || 'Uncaught exception',
      stack: event.error?.stack,
      url: window.location.href,
      timestamp: new Date().toISOString()
    };
    
    // Add user ID if available
    const auth = getAuth();
    if (auth.currentUser) {
      errorDetails.userId = auth.currentUser.uid;
    }
    
    // Report to console
    console.debug('Uncaught exception captured:', errorDetails);
    
    // Report to Firebase
    this.reportToFirebase(errorDetails);
  };
  
  /**
   * Report error to Firebase
   */
  private async reportToFirebase(errorDetails: ErrorDetails): Promise<void> {
    try {
      const errorDoc = doc(db, 'errors', `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`);
      
      await setDoc(errorDoc, {
        ...errorDetails,
        environment: this.environmentName,
        appVersion: this.applicationVersion,
        userAgent: navigator.userAgent,
        timestamp: serverTimestamp()
      });
    } catch (error) {
      console.debug('Failed to report error to Firebase:', error);
    }
  }
  
  /**
   * Cleanup event listeners
   */
  public cleanup(): void {
    if (this.captureUnhandledRejections) {
      window.removeEventListener('unhandledrejection', this.handleUnhandledRejection);
    }
    
    if (this.captureUncaughtExceptions) {
      window.removeEventListener('error', this.handleUncaughtException);
    }
    
    this.isInitialized = false;
  }
} 