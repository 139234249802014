import React from 'react';
import { useOnboardingStore } from '@/stores/onboardingStore';
import FormField from '@/components/ui/FormField';

export default function AssistantStep() {
  const { config, updateConfig } = useOnboardingStore();
  const { assistant } = config;

  const handleChange = (field: keyof typeof assistant, value: string) => {
    updateConfig('assistant', { [field]: value });
  };

  return (
    <div className="space-y-6">
      <div className="mb-6 bg-gray-50 rounded-lg p-5">
        <h3 className="text-lg font-medium text-gray-900 mb-2">Configure Your AI Assistant</h3>
        <p className="text-sm text-gray-700">
          Personalize how your AI assistant interacts with customers. These initial settings help establish 
          your assistant's identity and tone. You can refine these settings further after onboarding.
        </p>
      </div>

      <FormField label="Assistant Name">
        <input
          type="text"
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          placeholder="MattressAI"
          value={assistant.name}
          onChange={(e) => handleChange('name', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          The name your AI assistant will use when chatting with customers
        </p>
      </FormField>

      <FormField label="Assistant Persona">
        <textarea
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 h-32 resize-none"
          placeholder="You are helpful and detailed. You have extensive knowledge about mattresses, sleep science, and customer service."
          value={assistant.persona}
          onChange={(e) => handleChange('persona', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          This defines your assistant's personality, expertise, and communication style. Think about how knowledgeable, 
          friendly, or professional you want your assistant to be. The AI will use this guidance to shape all interactions. Or just tell it "Act as if you are the child of The Wolf of Wall Street and Mattress Mack"
        </p>
      </FormField>

      <FormField label="Greeting Message">
        <textarea
          className="w-full px-4 py-2 border rounded-lg border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 h-24 resize-none"
          placeholder="Hello! I'm your AI mattress expert. How can I help you today?"
          value={assistant.greeting}
          onChange={(e) => handleChange('greeting', e.target.value)}
        />
        <p className="mt-1 text-sm text-gray-500">
          This is the first message customers see when starting a conversation. A good greeting welcomes 
          customers, introduces the assistant, and encourages engagement with a question or offer of help.
        </p>
      </FormField>

      <div className="bg-blue-50 rounded-lg p-4">
        <h4 className="text-sm font-medium text-blue-900 mb-2">AI Assistant Configuration Tips</h4>
        <div className="space-y-2 text-sm text-blue-700">
          <p>• <strong>Name:</strong> Choose something simple and relevant to your business</p>
          <p>• <strong>Persona:</strong> Be specific about your industry expertise and preferred tone</p>
          <p>• <strong>Greeting:</strong> Keep it brief, friendly, and end with an invitation to engage</p>
          <p>• Consider your target customer demographic when setting the tone</p>
          <p>• You can make more advanced adjustments in the settings after onboarding</p>
        </div>
      </div>
    </div>
  );
} 