import React, { useState } from 'react';
import { useOnboardingStore } from '@/stores/onboardingStore';
import { ColorPicker } from '@/components/common/ColorPicker';
import { FileUpload } from '@/components/common/FileUpload';
import ChatPreview from '@/components/onboarding/ChatPreview';
import { uploadFile } from '@/utils/fileUpload';
import { auth } from '@/config/firebase';
import toast from 'react-hot-toast';

export default function BrandingStep() {
  const { config, updateConfig } = useOnboardingStore();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleColorChange = (color: string) => {
    updateConfig('branding', {
      colors: {
        primary: color,
        secondary: color // Using same color for secondary by default
      }
    });
  };

  const handleLogoUpload = async (file: File) => {
    try {
      if (!auth.currentUser?.uid) {
        throw new Error('Please sign in to your MattressAI account to upload files');
      }

      setIsUploading(true);
      const logoUrl = await uploadFile(file, 'logos', {
        merchantId: auth.currentUser.uid
      });
      
      updateConfig('branding', {
        logo: logoUrl
      });
      toast.success('Logo successfully uploaded to your MattressAI account');
    } catch (error) {
      console.error('Error uploading logo:', error);
      toast.error(error instanceof Error ? error.message : 'Unable to upload your logo. Please try a different image or contact support.');
    } finally {
      setIsUploading(false);
    }
  };

  // We're keeping the function but not exposing it in the UI
  const handleFaviconUpload = async (file: File) => {
    try {
      if (!auth.currentUser?.uid) {
        throw new Error('Please sign in to your MattressAI account to upload files');
      }

      setIsUploading(true);
      const faviconUrl = await uploadFile(file, 'favicons', {
        merchantId: auth.currentUser.uid
      });
      
      updateConfig('branding', {
        chatIcon: faviconUrl
      });
      toast.success('Favicon successfully uploaded to your MattressAI account');
    } catch (error) {
      console.error('Error uploading favicon:', error);
      toast.error(error instanceof Error ? error.message : 'Unable to upload your favicon. Please try a different image or contact support.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="mb-8 bg-gray-50 rounded-lg p-5">
        <h3 className="text-lg font-medium text-gray-900 mb-2">Basic Brand Identity</h3>
        <p className="text-sm text-gray-700">
          Set up your AI assistant's initial appearance by selecting a primary brand color and uploading your logo. 
          These will be displayed in your customer chat interface. After completing onboarding, you'll have access 
          to more extensive customization options in the settings panel.
        </p>
      </div>

      <div>
        <h3 className="text-lg font-medium">Primary Color</h3>
        <div className="space-y-4">
          <div>
            <ColorPicker
              color={config.branding.colors.primary}
              onChange={handleColorChange}
            />
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-lg font-medium">Logo</h3>
        <div>
          <FileUpload
            accept="image/*"
            onUpload={handleLogoUpload}
            currentFile={config.branding.logo}
            isLoading={isUploading}
          />
          <p className="mt-2 text-xs text-gray-500">
            For best results, use a square image (recommended: 512 x 512 pixels)
          </p>
        </div>
      </div>

      <div className="flex justify-end space-x-4 mt-8">
        <button
          onClick={() => setIsPreviewOpen(true)}
          className="px-6 py-3 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded-md hover:from-blue-600 hover:to-indigo-700 shadow-md transform hover:scale-105 transition duration-300 flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
          </svg>
          See Your AI Assistant in Action!
        </button>
      </div>

      <ChatPreview
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        branding={{
          colors: {
            primary: config.branding.colors.primary,
            secondary: config.branding.colors.secondary
          },
          logo: config.branding.logo
        }}
        config={{
          storeInfo: config.storeInfo,
          welcomeMessage: config.branding.welcomeMessage,
          aiPersonality: config.assistant.persona,
          conversationStyle: 'professional',
          leadCaptureTiming: 'after-engagement',
          primaryGoal: 'lead',
          educationTopics: '',
          responseCreativity: 0.7,
          productRecommendationLimit: 3,
          questions: [],
          model: 'gpt-4',
          temperature: 0.7
        }}
      />
    </div>
  );
} 