import { useState, useCallback, useEffect } from 'react';
import { ThemeConfig, DEFAULT_THEME } from '../types/config';
import { MerchantConfigService } from '../services/merchant/config';

export const useTheme = () => {
  const [theme, setTheme] = useState<ThemeConfig>(DEFAULT_THEME);
  const [isLoading, setIsLoading] = useState(true);

  // Initialize theme from merchant config
  useEffect(() => {
    const initializeTheme = async () => {
      try {
        const merchantService = MerchantConfigService.getInstance();
        // Initialize with current hostname
        await merchantService.initializeFromSubdomain(window.location.hostname);
        const config = merchantService.getCurrentConfig();
        if (config?.branding?.colors) {
          setTheme(config.branding.colors);
        }
      } catch (error) {
        console.warn('Failed to load merchant theme, using default:', error);
        // Keep using default theme
      } finally {
        setIsLoading(false);
      }
    };

    initializeTheme();
  }, []);
  
  const updateTheme = useCallback(async (newTheme: Partial<ThemeConfig>) => {
    try {
      // Update local state with full theme object
      const updatedTheme = { ...theme, ...newTheme };
      setTheme(updatedTheme);
      
      // Get merchant service instance and update Firebase
      const merchantService = MerchantConfigService.getInstance();
      await merchantService.updateTheme(updatedTheme);
      
      return updatedTheme;
    } catch (error) {
      console.error('Failed to update theme:', error);
      throw error;
    }
  }, [theme]);
  
  return {
    theme,
    updateTheme,
    isLoading
  };
}; 