import React, { useState, useRef, useEffect, useCallback } from 'react';
import { User, Menu, LogOut, Settings, Copy, Check, ExternalLink } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';
import { useSidebarStore } from '@/stores/sidebarStore';
import { useAuthStore } from '@/stores/authStore';
import AccountSettings from '@/components/settings/AccountSettings';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/config/firebase';
import toast from 'react-hot-toast';

interface HeaderProps {
  isSuperAdmin?: boolean;
}

const Header: React.FC<HeaderProps> = ({ isSuperAdmin }) => {
  const setMobileOpen = useSidebarStore((state) => state.setMobileOpen);
  const { logout, user } = useAuthStore();
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);
  const currentPath = window.location.pathname;
  const [merchantChatUrl, setMerchantChatUrl] = useState<string>('');
  const [isCopied, setIsCopied] = useState(false);
  
  const getBreadcrumbItems = () => {
    const paths = currentPath.split('/').filter(Boolean);
    return paths.map((path) => ({
      label: path.charAt(0).toUpperCase() + path.slice(1),
      href: `/${paths.slice(0, paths.indexOf(path) + 1).join('/')}`,
    }));
  };

  // Fetch merchant chat URL from Firestore
  const fetchMerchantChatUrl = useCallback(async () => {
    if (!user) return;

    try {
      const merchantDoc = await getDoc(doc(db, 'merchants', user.uid));
      if (merchantDoc.exists()) {
        const merchantData = merchantDoc.data();
        const chatUrl = merchantData.chatUrl;
        if (chatUrl) {
          // Remove any potential localhost prefix from the URL
          const cleanUrl = chatUrl.replace(/^https?:\/\/localhost:[0-9]+\//, '');
          setMerchantChatUrl(cleanUrl);
        }
      }
    } catch (error) {
      console.error('Failed to fetch merchant chat URL:', error);
    }
  }, [user]);

  // Handle copy chat URL to clipboard
  const handleCopyUrl = async () => {
    if (!merchantChatUrl) return;
    
    try {
      await navigator.clipboard.writeText(merchantChatUrl);
      setIsCopied(true);
      toast.success('Chat URL copied to clipboard!');
      
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy URL:', error);
      toast.error('Failed to copy URL. Please try again.');
    }
  };

  // Load the chat URL on component mount
  useEffect(() => {
    fetchMerchantChatUrl();
  }, [fetchMerchantChatUrl]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
      setShowUserMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="h-16 fixed right-0 top-0 left-0 lg:left-64 bg-white border-b-2 border-gray-200 z-10 shadow-md">
        <div className="h-full px-4 lg:px-8 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button 
              onClick={() => setMobileOpen(true)}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors lg:hidden"
              aria-label="Open menu"
            >
              <Menu className="h-5 w-5 text-text" />
            </button>
            <div className="hidden lg:block">
              <Breadcrumbs items={getBreadcrumbItems()} />
            </div>
          </div>
          
          {/* Chat URL Display with Glimmer Effect */}
          {merchantChatUrl && (
            <div className="hidden md:flex items-center gap-2 bg-primary/5 border border-primary/20 px-3 py-1.5 rounded-lg relative overflow-hidden">
              {/* Glimmer effect overlay */}
              <div className="absolute inset-0 pointer-events-none">
                <div className="absolute inset-0 w-[200%] animate-[shimmer_3s_infinite] bg-gradient-to-r from-transparent via-white/30 to-transparent"></div>
              </div>
              
              <div className="flex items-center max-w-xs lg:max-w-md relative z-10">
                <span className="text-sm font-medium text-primary mr-2">Your Chat URL:</span>
                <span className="text-sm text-gray-600 truncate">{merchantChatUrl}</span>
              </div>
              <div className="flex items-center gap-1 relative z-10">
                <a 
                  href={`https://${merchantChatUrl}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="p-1.5 hover:bg-primary/10 rounded-full transition-colors"
                  title="Open chat in new tab"
                >
                  <ExternalLink className="h-4 w-4 text-primary" />
                </a>
                <button 
                  onClick={handleCopyUrl}
                  className="p-1.5 hover:bg-primary/10 rounded-full transition-colors"
                  title="Copy URL to clipboard"
                >
                  {isCopied ? 
                    <Check className="h-4 w-4 text-green-500" /> : 
                    <Copy className="h-4 w-4 text-primary" />
                  }
                </button>
              </div>
            </div>
          )}
          
          <div className="flex items-center gap-2 lg:gap-4">
            {isSuperAdmin && (
              <Link
                to="/admin/brands"
                className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary/90"
              >
                Admin Portal
              </Link>
            )}

            {/* Mobile Chat URL Button (only shown on small screens) */}
            {merchantChatUrl && (
              <button
                onClick={handleCopyUrl}
                className="md:hidden flex items-center gap-1 bg-primary/10 p-2 rounded-lg relative overflow-hidden"
                title="Copy Chat URL"
              >
                {/* Mobile glimmer effect */}
                <div className="absolute inset-0 pointer-events-none">
                  <div className="absolute inset-0 w-[200%] animate-[shimmer_3s_infinite] bg-gradient-to-r from-transparent via-white/30 to-transparent"></div>
                </div>
                <div className="relative z-10">
                  {isCopied ? 
                    <Check className="h-4 w-4 text-green-500" /> : 
                    <Copy className="h-4 w-4 text-primary" />
                  }
                </div>
              </button>
            )}

            <div className="relative" ref={userMenuRef}>
              <button 
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                <User className="h-5 w-5 text-text/70" />
              </button>
              
              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg py-1 border-2 border-gray-200 z-20">
                  <div className="px-4 py-3 border-b border-gray-200 bg-gray-50">
                    <p className="text-sm font-medium text-text">Account</p>
                    <p className="text-sm text-text/70 truncate">{user?.email}</p>
                  </div>
                  
                  {/* Add Chat URL to user menu for mobile */}
                  {merchantChatUrl && (
                    <div className="px-4 py-3 border-b border-gray-200 md:hidden relative overflow-hidden">
                      {/* Menu glimmer effect */}
                      <div className="absolute inset-0 pointer-events-none">
                        <div className="absolute inset-0 w-[200%] animate-[shimmer_3s_infinite] bg-gradient-to-r from-transparent via-white/30 to-transparent"></div>
                      </div>
                      <p className="text-sm font-medium text-primary mb-1 relative z-10">Your Chat URL</p>
                      <div className="flex items-center gap-2 relative z-10">
                        <p className="text-sm text-gray-600 truncate">{merchantChatUrl}</p>
                        <button 
                          onClick={handleCopyUrl}
                          className="p-1 hover:bg-gray-100 rounded-full"
                        >
                          {isCopied ? 
                            <Check className="h-3.5 w-3.5 text-green-500" /> : 
                            <Copy className="h-3.5 w-3.5 text-primary" />
                          }
                        </button>
                      </div>
                    </div>
                  )}
                  
                  <button
                    className="w-full px-4 py-2 text-left text-sm text-text/70 hover:bg-gray-50 flex items-center gap-2"
                    onClick={() => {
                      setShowUserMenu(false);
                      setShowAccountSettings(true);
                    }}
                  >
                    <Settings className="h-4 w-4" />
                    <span>Account Settings</span>
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full px-4 py-2 text-left text-sm text-text/70 hover:bg-gray-50 flex items-center gap-2"
                  >
                    <LogOut className="h-4 w-4" />
                    <span>Log Out</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Account Settings Modal */}
      {showAccountSettings && (
        <div className="fixed inset-0 bg-black/30 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-xl shadow-xl w-full max-w-4xl max-h-[90vh] overflow-auto">
            <div className="p-6 border-b border-gray-200 flex justify-between items-center">
              <h2 className="text-2xl font-bold text-gray-800">Account Settings</h2>
              <button 
                onClick={() => setShowAccountSettings(false)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                ✕
              </button>
            </div>
            <div className="p-6">
              <AccountSettings />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;