import { Timestamp } from 'firebase/firestore';
import { StoreInfo } from './store';

// Theme configuration
export interface ThemeConfig {
  primary: string;
  secondary: string;
  accent?: string;  // Make accent optional since it's not used in the chat app
  background: string;
  text: string;
  // Add any other theme-related properties
}

// Default theme configuration
export const DEFAULT_THEME: ThemeConfig = {
  primary: '#2d8a6b',    // Rich sage green - professional and trustworthy
  secondary: '#41b883',  // Fresh mint - energetic but not overwhelming
  accent: '#1a3c34',     // Deep forest - adds depth
  background: '#f8faf9', // Soft sage white - easier on eyes than pure white
  text: '#243b36'        // Dark sage - softer than pure black
};

export interface StoreConfig {
    storeId: string;
    name: string;
    slug: string;  // URL-friendly store name
    branding: {
        primaryColor: string;
        logo: string;
        favicon: string;
        chatBubbleColor: string;
        fontFamily?: string;
    };
    chatConfig: {
        welcomeMessage: string;
        aiPersonality: string;
        productCatalog: boolean;
        enableEmailCapture: boolean;
        emailCaptureMessage?: string;
    };
    metadata: {
        createdAt: Timestamp;
        updatedAt: Timestamp;
        isActive: boolean;
    };
}

export interface MerchantConfig {
    id?: string;
    userId?: string;
    storeConfig?: StoreConfig;
    openai?: {
        assistantId?: string;
        customInstructions?: string;
        model: 'gpt-4-turbo' | 'gpt-4' | 'gpt-3.5-turbo';
    };
    subscription?: {
        plan: 'free' | 'pro' | 'enterprise';
        status: 'active' | 'trialing' | 'canceled';
        features: string[];
        limits: {
            monthlyConversations: number;
            concurrentChats: number;
            retentionDays: number;
        };
    };
    storeInfo: StoreInfo;
    branding: {
        colors: ThemeConfig;
        fonts: {
            primary: string;
            secondary: string;
        };
        logo?: string;
        chatTitle: string;
        welcomeMessage?: string;
        chatIcon?: string;
    };
    chatConfig: {
        temperature: number;
        maxTokens: number;
        model: string;
        welcomeMessage: string;
        initialGreeting: string;
        conversationStyle: string;
        leadCaptureTiming: string;
        primaryGoal: string;
        responseCreativity: number;
        productRecommendationLimit: number;
        assistantId?: string;
        educationTopics: string;
        aiName?: string;
        aiPersonality?: string;
        masterPrompt?: string;
        toneDirective?: string;
    };
    features: {
        chatEnabled: boolean;
        recommendationsEnabled: boolean;
        analyticsEnabled: boolean;
    };
    assistant: {
        name: string;
        description: string;
        greeting: string;
        persona: string;
        masterPrompt?: string;
    };
}

export interface SubdomainMapping {
  id: string;
  merchantId: string;
  subdomain: string;
  configId: string;
  status: 'active' | 'pending' | 'error';
  customDomain?: string;
  sslStatus?: 'active' | 'pending' | 'error';
}

export interface ChatThread {
  id: string;
  merchantId: string;
  configId: string;
  customer?: {
    email?: string;
    name?: string;
    metadata?: Record<string, any>;
  };
  status: 'active' | 'archived' | 'deleted';
  createdAt: Timestamp;
  updatedAt: Timestamp;
  lastMessageAt: Timestamp;
}

// Firestore REST API field types for worker compatibility
export interface FirestoreField {
  stringValue?: string;
  integerValue?: string;
  doubleValue?: number;
  booleanValue?: boolean;
  timestampValue?: string;
  arrayValue?: {
    values: FirestoreField[];
  };
  mapValue?: {
    fields: Record<string, FirestoreField>;
  };
}

export interface FirestoreDocument {
  fields: Record<string, FirestoreField>;
  name?: string;
  createTime?: string;
  updateTime?: string;
}

export interface UpdateConfigRequest extends Partial<MerchantConfig> {
  version?: string;
}

export const DEFAULT_CONFIG: MerchantConfig = {
  storeInfo: {
    name: 'MattressAI Store',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: 'en',
    currency: 'USD',
    measurementUnit: 'imperial',
    hasMultipleLocations: false,
    uniqueFeatures: '',
    storeFiles: []
  },
  chatConfig: {
    temperature: 0.7,
    maxTokens: 2000,
    model: 'gpt-4',
    welcomeMessage: 'Welcome! How can I help you find the perfect mattress?',
    initialGreeting: 'Welcome! How can I help you find the perfect mattress?',
    conversationStyle: 'professional',
    leadCaptureTiming: 'after-engagement',
    primaryGoal: 'lead',
    responseCreativity: 0.7,
    productRecommendationLimit: 3,
    educationTopics: 'sleep health, mattress types, sleep positions, mattress materials',
    aiName: 'MattressAI Assistant',
    aiPersonality: 'A knowledgeable and friendly sleep expert',
    masterPrompt: 'You are a helpful mattress expert...',
    toneDirective: 'Be friendly and professional'
  },
  branding: {
    colors: DEFAULT_THEME,
    fonts: {
      primary: 'Inter',
      secondary: 'system-ui'
    },
    chatTitle: 'MattressAI Chat',
    chatIcon: 'default'
  },
  features: {
    chatEnabled: true,
    recommendationsEnabled: true,
    analyticsEnabled: true
  },
  subscription: {
    plan: 'free',
    status: 'trialing',
    features: ['chat', 'analytics'],
    limits: {
      monthlyConversations: 100,
      concurrentChats: 1,
      retentionDays: 30
    }
  },
  assistant: {
    name: 'MattressAI Assistant',
    description: 'You are a helpful mattress expert...',
    greeting: 'Welcome! How can I help you find the perfect mattress?',
    persona: 'A knowledgeable and friendly sleep expert'
  }
}; 