import { createTheme, Theme } from '@mui/material/styles';
import { ThemeConfig, DEFAULT_THEME } from '../types/config';

// Create MUI theme from our theme config
export const createMUITheme = (config: ThemeConfig = DEFAULT_THEME): Theme => {
  // Ensure we have a valid config object
  const safeConfig = {
    ...DEFAULT_THEME,
    ...config
  };

  return createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: safeConfig.primary,
        light: safeConfig.secondary,
        dark: safeConfig.accent,
        contrastText: '#ffffff',
      },
      secondary: {
        main: safeConfig.secondary,
        light: '#a8f0d3',
        dark: safeConfig.accent,
        contrastText: '#ffffff',
      },
      background: {
        default: safeConfig.background,
        paper: safeConfig.background,
      },
      text: {
        primary: safeConfig.text,
        secondary: safeConfig.accent,
      },
      error: {
        main: '#ef4444',
      },
      warning: {
        main: '#f59e0b',
      },
      info: {
        main: '#3b82f6',
      },
      success: {
        main: safeConfig.primary,
      },
    },
    typography: {
      fontFamily: 'Inter, system-ui, -apple-system, sans-serif',
      h1: {
        fontSize: '2.5rem',
        fontWeight: 600,
        color: safeConfig.text,
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 600,
        color: safeConfig.text,
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 600,
        color: safeConfig.text,
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 500,
        color: safeConfig.text,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 500,
        color: safeConfig.text,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 500,
        color: safeConfig.text,
      },
      body1: {
        color: safeConfig.text,
      },
      body2: {
        color: safeConfig.accent,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: '0.5rem',
            fontWeight: 500,
            padding: '0.5rem 1rem',
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              borderRadius: '0.5rem',
              '&:hover fieldset': {
                borderColor: safeConfig.primary,
              },
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '0.75rem',
            boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: safeConfig.background,
            color: safeConfig.text,
            boxShadow: 'none',
            borderBottom: '1px solid #e5e7eb',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: safeConfig.background,
            borderRight: '1px solid #e5e7eb',
          },
        },
      },
    },
    shape: {
      borderRadius: 8,
    },
  });
};

// Tailwind theme configuration generator
export const createTailwindTheme = (config: ThemeConfig = DEFAULT_THEME) => {
  const safeConfig = {
    ...DEFAULT_THEME,
    ...config
  };

  return {
    colors: {
      primary: safeConfig.primary,
      secondary: safeConfig.secondary,
      background: safeConfig.background,
      text: safeConfig.text,
      accent: safeConfig.accent,
      'primary-light': '#a8f0d3',
      'primary-dark': safeConfig.accent,
      'gray-50': '#f9fafb',
      'gray-100': '#f3f4f6',
      'gray-200': '#e5e7eb',
      'gray-300': '#d1d5db',
      'gray-400': '#9ca3af',
      'gray-500': '#6b7280',
      'gray-600': '#4b5563',
      'gray-700': '#374151',
      'gray-800': '#1f2937',
      'gray-900': '#111827',
    },
  };
}; 