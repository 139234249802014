import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Button, Typography, Box, Paper } from '@mui/material';
import { ErrorMonitoringService } from '../../services/monitoring/ErrorMonitoringService';
import { useRouteError } from 'react-router-dom';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

/**
 * Error Boundary component to catch and handle errors in React components
 */
export class ErrorBoundary extends Component<Props, State> {
  private errorService = ErrorMonitoringService.getInstance();
  
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }
  
  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error,
      errorInfo: null
    };
  }
  
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Report error to monitoring service
    this.errorService.captureReactError(error, errorInfo.componentStack || '');
    
    this.setState({
      errorInfo
    });
  }
  
  handleReset = (): void => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
  }
  
  render(): ReactNode {
    if (this.state.hasError) {
      // Custom fallback UI
      if (this.props.fallback) {
        return this.props.fallback;
      }
      
      return (
        <Box sx={{ p: 4, maxWidth: '800px', mx: 'auto', mt: 4 }}>
          <Paper sx={{ p: 3, borderRadius: 2 }}>
            <Typography variant="h5" color="error" gutterBottom>
              Something went wrong
            </Typography>
            
            <Typography variant="body1" paragraph>
              We've encountered an error in the application. Our team has been notified.
            </Typography>
            
            <Typography variant="body2" color="text.secondary" paragraph>
              Error: {this.state.error?.message || 'Unknown error'}
            </Typography>
            
            {process.env.NODE_ENV === 'development' && this.state.errorInfo && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Component Stack:
                </Typography>
                <Box
                  component="pre"
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    bgcolor: 'grey.100',
                    fontSize: '0.875rem',
                    overflow: 'auto',
                    maxHeight: '300px'
                  }}
                >
                  {this.state.errorInfo.componentStack}
                </Box>
              </Box>
            )}
            
            <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleReset}
              >
                Try Again
              </Button>
              
              <Button
                variant="outlined"
                onClick={() => window.location.reload()}
              >
                Reload Page
              </Button>
            </Box>
          </Paper>
        </Box>
      );
    }
    
    return this.props.children;
  }
}

/**
 * Route Error Boundary for React Router routes
 */
export function RouteErrorBoundary() {
  const error = useRouteError() as Error;
  
  return (
    <Box sx={{ p: 4, maxWidth: '800px', mx: 'auto', mt: 4 }}>
      <Paper sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h5" color="error" gutterBottom>
          Route Error
        </Typography>
        
        <Typography variant="body1" paragraph>
          An error occurred while loading this route. Our team has been notified.
        </Typography>
        
        <Typography variant="body2" color="text.secondary" paragraph>
          Error: {error?.message || 'Unknown error'}
        </Typography>
        
        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.history.back()}
          >
            Go Back
          </Button>
          
          <Button
            variant="outlined"
            onClick={() => window.location.reload()}
          >
            Reload Page
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

// Add default export
export default ErrorBoundary;