import React from 'react';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { AlertCircle } from 'lucide-react';
import { useSecuritySettings } from '@/hooks/useSecuritySettings';
import FormTextField from '@/components/ui/form/FormTextField';

interface SecurityStepProps {
  onNext: () => void;
  onPrevious: () => void;
}

/**
 * Security Settings Step
 * Handles password management and security settings
 */
const SecurityStep: React.FC<SecurityStepProps> = ({ onNext, onPrevious }) => {
  const { 
    isGoogleUser,
    currentPassword,
    newPassword,
    confirmPassword,
    passwordError,
    isChangingPassword,
    setCurrentPassword,
    setNewPassword,
    setConfirmPassword,
    handlePasswordChange
  } = useSecuritySettings();

  return (
    <Card className="p-6">
      <div className="space-y-6">
        {isGoogleUser ? (
          <div className="bg-gray-50 border border-gray-200 rounded-lg p-4">
            <div className="flex items-center gap-3">
              <AlertCircle className="w-5 h-5 text-primary" />
              <div>
                <h3 className="font-medium text-gray-900">Google Account</h3>
                <p className="text-sm text-gray-600">
                  Your account is managed through Google. Please visit your Google Account settings to manage your password.
                </p>
                <a
                  href="https://myaccount.google.com/security"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-1 text-sm text-primary hover:text-primary/80 mt-2"
                >
                  Manage Google Account
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            {passwordError && (
              <div className="bg-red-50 border border-red-200 rounded-lg p-3">
                <p className="text-sm text-red-600">{passwordError}</p>
              </div>
            )}
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormTextField
                label="Current Password"
                type="password"
                value={currentPassword}
                onChange={setCurrentPassword}
                placeholder="Enter current password"
              />

              <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormTextField
                  label="New Password"
                  type="password"
                  value={newPassword}
                  onChange={setNewPassword}
                  placeholder="Enter new password"
                />

                <FormTextField
                  label="Confirm New Password"
                  type="password"
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  placeholder="Confirm new password"
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end gap-3 mt-6">
          <Button variant="secondary" onClick={onPrevious}>
            Previous
          </Button>
          
          {!isGoogleUser && (
            <Button 
              variant="primary" 
              onClick={handlePasswordChange}
              disabled={isChangingPassword || !currentPassword || !newPassword || !confirmPassword}
            >
              {isChangingPassword ? 'Updating...' : 'Update Password'}
            </Button>
          )}
          
          <Button 
            variant="primary" 
            onClick={onNext}
          >
            Next Step
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default SecurityStep; 