/**
 * Image Analysis API
 * Provides functionality for analyzing images using GPT-4o Vision capabilities.
 * Uses OpenAI's API to extract detailed descriptions and relevant information from images.
 */

import { getFirestore, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { auth } from '@/config/firebase';
import { isDevEnvironment } from '@/utils/environment';

// OpenAI API endpoint
const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';

// Get OpenAI API key with fallback mechanisms
// 1. Try import.meta.env.VITE_OPENAI_API_KEY (environment variable)
// 2. Use fallback key for development
// 3. If neither available, will try to fetch from Firestore when called
const ENV_API_KEY = typeof import.meta.env !== 'undefined' 
  ? import.meta.env.VITE_OPENAI_API_KEY 
  : undefined;

// Fallback API key for development or when not found in Firestore
const FALLBACK_OPENAI_API_KEY = ENV_API_KEY || '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';

// Interface for analysis response
interface ImageAnalysisResponse {
  description: string;
  detectedItems: string[];
  storeRelevance: 'high' | 'medium' | 'low';
  error?: string;
}

/**
 * Analyzes an image using GPT-4o Vision capabilities
 * @param imageUrl The URL of the image to analyze
 * @param prompt Optional custom prompt to guide the image analysis
 * @returns Detailed analysis of the image content
 */
export async function analyzeImage(
  imageUrl: string, 
  prompt?: string
): Promise<ImageAnalysisResponse> {
  if (!auth.currentUser) {
    throw new Error('No authenticated user found');
  }

  try {
    // Initialize with the fallback key
    let openaiApiKey = FALLBACK_OPENAI_API_KEY;
    
    // Try to get the OpenAI API key from Firestore if not in development mode
    if (!isDevEnvironment()) {
      try {
        const db = getFirestore();
        const settingsRef = doc(db, 'merchants', auth.currentUser.uid, 'merchantConfig', 'settings');
        const settingsDoc = await getDoc(settingsRef);
        
        if (settingsDoc.exists()) {
          const settings = settingsDoc.data();
          // If a key exists in Firestore, use it instead of the fallback
          if (settings?.apiKeys?.openai) {
            openaiApiKey = settings.apiKeys.openai;
          }
        }
      } catch (error) {
        console.warn('Could not retrieve API key from Firestore, using fallback key:', error);
      }
    }

    // Default prompt for store-relevant image analysis
    const defaultPrompt = `
      Analyze this image of a mattress store or sleep-related product. 
      Describe in detail what you see, including:
      - Layout and organization if it's a store interior
      - Products visible and their arrangement
      - Promotional materials or signage
      - Any unique features or selling points visible
      - For product images: materials, design features, and apparent benefits
      
      Focus on details that would be relevant for a mattress store assistant 
      to reference when helping customers.
    `;

    // Call OpenAI API
    const response = await fetch(OPENAI_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${openaiApiKey}`
      },
      body: JSON.stringify({
        model: 'gpt-4o',
        messages: [
          {
            role: 'user',
            content: [
              { type: 'text', text: prompt || defaultPrompt },
              { type: 'image_url', image_url: { url: imageUrl } }
            ]
          }
        ],
        max_tokens: 500
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`OpenAI API error: ${errorData.error?.message || response.statusText}`);
    }

    const data = await response.json();
    const analysisText = data.choices[0]?.message?.content || '';
    
    // Parse the analysis text to extract structured information
    // This is a simplified version - in production you might want to prompt
    // GPT-4o to return structured JSON directly
    const detectedItems = extractKeyItems(analysisText);
    const storeRelevance = determineStoreRelevance(analysisText);
    
    return {
      description: analysisText,
      detectedItems,
      storeRelevance
    };
  } catch (error) {
    console.error('Error analyzing image:', error);
    
    // Return a graceful fallback in development environments
    if (isDevEnvironment()) {
      return {
        description: 'This is a simulated image analysis description for development. In production, this would contain a detailed description of the store image generated by GPT-4o.',
        detectedItems: ['sample product display', 'store layout', 'promotional signage'],
        storeRelevance: 'high',
        error: error instanceof Error ? error.message : 'Unknown error'
      };
    }
    
    // In production, propagate the error
    throw error;
  }
}

/**
 * Simple function to extract key items from analysis text
 * In a production environment, you might want a more sophisticated approach
 */
function extractKeyItems(text: string): string[] {
  // Basic extraction - looking for items in lists or after colons
  const items: string[] = [];
  
  // Look for bullet points or numbered lists
  const bulletMatches = text.match(/[-•*]\s+([^\n]+)/g);
  if (bulletMatches) {
    bulletMatches.forEach(match => {
      // Clean up the match and add to items
      const cleanItem = match.replace(/^[-•*]\s+/, '').trim();
      if (cleanItem) items.push(cleanItem);
    });
  }
  
  // If no bullet points found, extract key terms another way
  if (items.length === 0) {
    // Look for nouns following "see", "notice", "contains", etc.
    const keyPhrases = text.match(/(see|notice|contains|features|displays?|shows?)\s+(\w+\s){1,3}/gi);
    if (keyPhrases) {
      keyPhrases.forEach(phrase => {
        const words = phrase.split(/\s+/);
        if (words.length >= 2) {
          // Take the words after the key verb
          items.push(words.slice(1).join(' ').trim());
        }
      });
    }
  }
  
  // Deduplicate and return
  return [...new Set(items)];
}

/**
 * Determine how relevant the image is to the store
 */
function determineStoreRelevance(text: string): 'high' | 'medium' | 'low' {
  const lowercaseText = text.toLowerCase();
  
  // Keywords indicating high relevance
  const highRelevanceTerms = [
    'store layout', 'floor plan', 'mattress display', 'product showcase',
    'showroom', 'sleep products', 'store interior', 'mattress', 'pillow',
    'bedding', 'customer experience', 'store map', 'promotional', 'sale'
  ];
  
  // Keywords indicating medium relevance
  const mediumRelevanceTerms = [
    'bedroom', 'furniture', 'sleep', 'comfort', 'rest', 'design',
    'home', 'décor', 'lifestyle', 'relaxation'
  ];
  
  // Count matches for each relevance level
  const highMatches = highRelevanceTerms.filter(term => lowercaseText.includes(term)).length;
  const mediumMatches = mediumRelevanceTerms.filter(term => lowercaseText.includes(term)).length;
  
  // Determine relevance based on matches
  if (highMatches >= 2) return 'high';
  if (highMatches === 1 || mediumMatches >= 2) return 'medium';
  return 'low';
}

/**
 * Saves the OpenAI API key to the merchant's settings in Firestore.
 * This allows the key to be persisted for future use.
 * @param apiKey The OpenAI API key to save
 * @returns A promise that resolves when the key is saved
 */
export async function saveOpenAIApiKey(apiKey: string): Promise<void> {
  if (!auth.currentUser) {
    throw new Error('No authenticated user found');
  }

  try {
    const db = getFirestore();
    const settingsRef = doc(db, 'merchants', auth.currentUser.uid, 'merchantConfig', 'settings');
    
    // First check if settings document exists
    const settingsDoc = await getDoc(settingsRef);
    
    if (settingsDoc.exists()) {
      // Update existing document
      const settings = settingsDoc.data();
      await updateDoc(settingsRef, {
        apiKeys: {
          ...settings.apiKeys,
          openai: apiKey
        }
      });
    } else {
      // Create new settings document
      await setDoc(settingsRef, {
        apiKeys: {
          openai: apiKey
        }
      });
    }
    
    console.log('Successfully saved OpenAI API key to Firestore');
  } catch (error) {
    console.error('Error saving OpenAI API key:', error);
    throw error;
  }
} 