import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { setupMerchantChat } from '@/services/api/merchantConfigApi';
import { db } from '@/config/firebase';
import { doc, setDoc, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { auth } from '@/config/firebase';
import { useAuthStore } from './authStore';

export interface OnboardingState {
  isComplete: boolean;
  currentStep: number;
  stepsCompleted: {
    storeBasics: boolean;
    brandIdentity: boolean;
    assistantPersonality: boolean;
    leadCapture: boolean;
    chatSetup: boolean;
  };
  config: {
    storeInfo: {
      name: string;
      hours: string;
      hasMultipleLocations: boolean;
      contactInfo: string;
      website?: string;
      location?: {
        address?: string;
        city?: string;
        state?: string;
        zip?: string;
        country?: string;
      };
    };
    branding: {
      colors: {
        primary: string;
        secondary: string;
        background?: string;
        text?: string;
      };
      logo?: string;
      chatIcon?: string;
      chatTitle: string;
      welcomeMessage: string;
    };
    assistant: {
      name: string;
      description: string;
      persona: string;
      greeting: string;
    };
    leads: {
      adminMobile: string;
      adminEmail: string;
      redirectUrl: string;
    };
    chatConfig?: {
      welcomeMessage?: string;
      conversationStyle?: string;
      leadCaptureTiming?: string;
      primaryGoal?: string;
      responseCreativity?: number;
      productRecommendationLimit?: number;
      educationTopics?: string;
    };
  };
  isDeployed: boolean;
  isDeploying: boolean;
  chatUrl: string | null;
  error: string | null;
}

interface OnboardingStore extends OnboardingState {
  setStep: (step: number) => void;
  completeStep: (step: keyof OnboardingState['stepsCompleted']) => void;
  updateConfig: <K extends keyof OnboardingState['config']>(
    section: K,
    data: Partial<OnboardingState['config'][K]>
  ) => void;
  resetOnboarding: () => void;
  completeOnboarding: () => void;
  initializeChat: () => Promise<void>;
  updateLiveChat: () => Promise<void>;
  deploy: () => Promise<void>;
  checkFirestoreCompletionStatus: (userId: string) => Promise<void>;
}

const initialState: OnboardingState = {
  isComplete: false,
  currentStep: 0,
  stepsCompleted: {
    storeBasics: false,
    brandIdentity: false,
    assistantPersonality: false,
    leadCapture: false,
    chatSetup: false,
  },
  config: {
    storeInfo: {
      name: '',
      hours: '',
      hasMultipleLocations: false,
      contactInfo: '',
    },
    branding: {
      colors: {
        primary: '#2563eb', // Default blue
        secondary: '#4f46e5', // Default indigo
      },
      chatTitle: 'MattressAI Match',
      welcomeMessage: 'Welcome! I\'m here to help you find the perfect mattress.',
    },
    assistant: {
      name: 'MattressAI',
      description: 'Your AI-powered mattress shopping assistant',
      persona: 'Professional, friendly, and knowledgeable about mattresses',
      greeting: 'Hello! I\'m your AI mattress expert. How can I help you today?',
    },
    leads: {
      adminMobile: '',
      adminEmail: '',
      redirectUrl: '',
    },
  },
  isDeployed: false,
  isDeploying: false,
  chatUrl: null,
  error: null,
};

function validateConfig(config: OnboardingState['config']) {
  if (!config) {
    throw new Error('Configuration is missing');
  }

  // Create a list of missing fields
  const missingFields = [];

  if (!config.storeInfo?.name) {
    missingFields.push('Store name');
  }

  if (!config.branding?.colors?.primary) {
    missingFields.push('Primary brand color');
  }

  if (!config.assistant?.name) {
    missingFields.push('Assistant name');
  }

  if (missingFields.length > 0) {
    throw new Error(`Required fields missing: ${missingFields.join(', ')}`);
  }

  return true;
}

export const useOnboardingStore = create<OnboardingStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      setStep: (step) => {
        // Reset error state when changing steps
        set({ currentStep: step, error: null });
      },
      completeStep: (step) =>
        set((state) => ({
          stepsCompleted: {
            ...state.stepsCompleted,
            [step]: true,
          },
          error: null // Reset error state
        })),
      updateConfig: (section, data) => {
        set((state) => {
          try {
            // Validate the section exists
            if (!state.config[section]) {
              console.error(`Invalid config section: ${section}`);
              return {
                ...state,
                error: `Invalid config section: ${section}`
              };
            }

            // Create new config with type safety
            const newConfig = {
              ...state.config,
              [section]: {
                ...state.config[section],
                ...data,
              },
            };

            // Log the config update for debugging
            console.log('Updating config:', {
              section,
              data,
              currentConfig: state.config,
              newConfig
            });

            // Return new state while preserving other fields
            return {
              ...state,
              config: newConfig,
              error: null
            };
          } catch (err) {
            // Log the error for debugging
            console.error('Config update failed:', {
              error: err,
              section,
              data,
              currentState: state
            });

            // Return new state while preserving existing config
            return {
              ...state,
              error: err instanceof Error ? err.message : 'Invalid configuration'
            };
          }
        });

        // After state update, try to sync with Firestore
        try {
          const { user } = useAuthStore.getState();
          if (user?.uid) {
            const state = get();
            const merchantConfigRef = doc(db, 'merchants', user.uid, 'merchantConfig', 'settings');
            setDoc(merchantConfigRef, {
              [section]: state.config[section],
              updatedAt: serverTimestamp()
            }, { merge: true }).catch(error => {
              console.error('Failed to sync with Firestore:', error);
              set(state => ({ ...state, error: 'Failed to save changes' }));
            });
          }
        } catch (error) {
          console.error('Failed to sync with Firestore:', error);
        }
      },
      resetOnboarding: () => set({ ...initialState }),
      completeOnboarding: () => {
        set((state) => ({
          stepsCompleted: {
            storeBasics: true,
            brandIdentity: true,
            assistantPersonality: true,
            leadCapture: true,
            chatSetup: true
          },
          isComplete: true
        }));
      },
      deploy: async () => {
        const state = get();
        const { user } = useAuthStore.getState();
        
        if (!user) {
          console.error('Deploy failed: No user found');
          set({ error: 'You must be logged in to deploy', isDeploying: false });
          return;
        }

        try {
          // Reset error state and set deploying
          set({ isDeploying: true, error: null });

          // Validate config exists
          if (!state.config) {
            throw new Error('Configuration is missing');
          }

          // Validate required fields with detailed errors
          const requiredFields = {
            'Store Name': state.config.storeInfo?.name?.trim?.(),
            'Primary Brand Color': state.config.branding?.colors?.primary,
            'Assistant Name': state.config.assistant?.name?.trim?.()
          };

          const missingFields = Object.entries(requiredFields)
            .filter(([_, value]) => !value)
            .map(([key]) => key);

          if (missingFields.length > 0) {
            throw new Error(`Required fields missing: ${missingFields.join(', ')}`);
          }

          // Log the deployment attempt
          console.log('Starting deployment with config:', {
            storeInfo: state.config.storeInfo,
            branding: state.config.branding,
            assistant: state.config.assistant
          });

          const subdomain = state.config.storeInfo.name
            .toLowerCase()
            .replace(/[^a-z0-9]/g, '-')
            .replace(/-+/g, '-')
            .replace(/^-|-$/g, '');

          try {
            // Break up the large write operation into smaller chunks
            // 1. First, update the merchant document (smallest payload)
            const merchantRef = doc(db, 'merchants', user.uid);
            const merchantData = {
              storeInfo: {
                name: state.config.storeInfo.name.trim()
              },
              chatUrl: `${subdomain}.chat.mattressai.co`,
              features: {
                chatEnabled: true,
                recommendationsEnabled: true,
                analyticsEnabled: true
              },
              updatedAt: serverTimestamp()
            };

            console.log('Updating merchant document:', merchantData);
            await setDoc(merchantRef, merchantData, { merge: true });

            // 2. Then update the subdomain mapping
            const subdomainRef = doc(db, 'subdomains', subdomain);
            const subdomainData = {
              merchantId: user.uid,
              subdomain,
              status: 'active',
              createdAt: serverTimestamp(),
              storeName: state.config.storeInfo.name.trim(),
              chatUrl: `${subdomain}.chat.mattressai.co`,
              features: {
                chatEnabled: true
              }
            };

            console.log('Creating subdomain mapping:', subdomainData);
            await setDoc(subdomainRef, subdomainData);

            // 3. Finally, update the merchant config (largest payload)
            const merchantConfigRef = doc(db, 'merchants', user.uid, 'merchantConfig', 'settings');
            const configData = {
              storeInfo: {
                ...state.config.storeInfo,
                name: state.config.storeInfo.name.trim()
              },
              branding: {
                colors: {
                  primary: state.config.branding.colors.primary,
                  secondary: state.config.branding.colors.secondary || state.config.branding.colors.primary,
                  background: '#ffffff',
                  text: '#000000'
                },
                ...(state.config.branding.logo && { logo: state.config.branding.logo }),
                ...(state.config.branding.chatIcon && { chatIcon: state.config.branding.chatIcon }),
                chatTitle: state.config.branding.chatTitle || state.config.storeInfo.name,
                welcomeMessage: state.config.branding.welcomeMessage || `Welcome to ${state.config.storeInfo.name}! How can I help you today?`
              },
              assistant: {
                ...state.config.assistant,
                description: state.config.assistant.description || `AI assistant for ${state.config.storeInfo.name}`,
                persona: state.config.assistant.persona || 'Professional and helpful'
              },
              leads: state.config.leads || {},
              features: {
                chatEnabled: true,
                recommendationsEnabled: true,
                analyticsEnabled: true
              },
              chatConfig: {
                welcomeMessage: state.config.branding.welcomeMessage || `Welcome to ${state.config.storeInfo.name}! How can I help you today?`,
                aiPersonality: state.config.assistant.persona || 'Professional and helpful',
                model: 'gpt-4',
                temperature: 0.7,
                maxTokens: 2000,
                assistantId: '', // Will be set by the backend
                masterPrompt: `You are an AI assistant for ${state.config.storeInfo.name}. ${state.config.assistant.description || ''}. ${state.config.assistant.persona || 'Professional and helpful'}`,
                initialGreeting: state.config.assistant.greeting || `Welcome to ${state.config.storeInfo.name}! How can I help you today?`,
                toneDirective: state.config.assistant.persona || 'Professional and helpful',
                conversationStyle: 'professional',
                leadCaptureTiming: 'after-engagement',
                primaryGoal: 'lead',
                educationTopics: '',
                responseCreativity: 0.7,
                productRecommendationLimit: 3
              },
              updatedAt: serverTimestamp(),
              createdAt: serverTimestamp()
            };

            console.log('Updating merchant config:', configData);
            await setDoc(merchantConfigRef, configData, { merge: true });

            // Update state after successful deployment
            set({ 
              ...state,
              isDeployed: true, 
              chatUrl: `${subdomain}.chat.mattressai.co`,
              isDeploying: false,
              error: null,
              stepsCompleted: {
                ...state.stepsCompleted,
                chatSetup: true
              }
            });

          } catch (error) {
            console.error('Firestore update failed:', error);
            set({ 
              ...state,
              isDeploying: false,
              isDeployed: false,
              error: error instanceof Error ? error.message : 'Failed to update configuration. Please try again.'
            });
            throw error;
          }

        } catch (error) {
          console.error('Deployment failed:', error);
          set({ 
            ...state,
            isDeploying: false,
            isDeployed: false,
            error: error instanceof Error ? error.message : 'Failed to deploy assistant'
          });
          throw error;
        }
      },
      initializeChat: async () => {
        try {
          const { user } = useAuthStore.getState();
          if (!user?.uid) {
            console.error('No user found or invalid user ID');
            throw new Error('User authentication required');
          }

          const { config } = get();
          const storeName = config.storeInfo.name?.trim();
          
          if (!storeName) {
            console.error('Store name is empty or invalid');
            throw new Error('Store name is required');
          }

          // Generate subdomain from store name
          const subdomain = storeName
            .toLowerCase()
            .replace(/[^a-z0-9]/g, '-')
            .replace(/-+/g, '-')
            .replace(/^-|-$/g, '');

          // Create subdomain mapping first
          await setDoc(doc(db, 'subdomains', subdomain), {
            merchantId: user.uid,
            subdomain,
            status: 'active',
            createdAt: serverTimestamp(),
            chatUrl: `${subdomain}.chat.mattressai.co`
          });
          
          // Create initial chat configuration
          const initialConfig = {
            storeInfo: {
              name: storeName,
            },
            branding: {
              colors: config.branding.colors,
              chatTitle: storeName,
            },
            chatConfig: {
              welcomeMessage: initialState.config.branding.welcomeMessage,
              aiName: storeName + ' Assistant',
            },
          };

          // Create the merchant document
          const merchantRef = doc(db, 'merchants', user.uid);
          await setDoc(merchantRef, {
            storeInfo: {
              name: storeName
            },
            chatUrl: `${subdomain}.chat.mattressai.co`,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            features: {
              chatEnabled: true,
              recommendationsEnabled: true,
              analyticsEnabled: true
            }
          });

          console.log('Created merchant document with store name:', storeName);
          
          // Create the merchant config document
          const merchantConfigRef = doc(db, 'merchants', user.uid, 'merchantConfig', 'settings');
          await setDoc(merchantConfigRef, {
            ...initialConfig,
            updatedAt: serverTimestamp()
          }, { merge: true });

          set({ isDeployed: true, chatUrl: `${subdomain}.chat.mattressai.co` });

        } catch (error) {
          console.error('Failed to initialize chat:', error);
          throw error;
        }
      },
      updateLiveChat: async () => {
        try {
          const { user } = useAuthStore.getState();
          if (!user) {
            console.log('No user found, skipping chat update');
            return;
          }

          const { config } = get();
          
          // Validate required fields
          if (!config.storeInfo.name || !config.branding.colors.primary || !config.assistant.name) {
            console.log('Missing required fields, skipping chat update');
            return;
          }

          const merchantConfigRef = doc(db, `merchants/${user.uid}/merchantConfig/settings`);
          
          const configData = {
            storeInfo: {
              name: config.storeInfo.name,
              hours: config.storeInfo.hours || '',
              hasMultipleLocations: config.storeInfo.hasMultipleLocations || false,
              contactInfo: config.storeInfo.contactInfo || '',
              ...(config.storeInfo.website && { website: config.storeInfo.website })
            },
            branding: {
              colors: {
                primary: config.branding.colors.primary,
                secondary: config.branding.colors.secondary || config.branding.colors.primary,
                background: '#ffffff',
                text: '#000000'
              },
              ...(config.branding.logo && { logo: config.branding.logo }),
              ...(config.branding.chatIcon && { chatIcon: config.branding.chatIcon }),
              chatTitle: config.branding.chatTitle || config.storeInfo.name,
              welcomeMessage: config.branding.welcomeMessage || `Welcome to ${config.storeInfo.name}! How can I help you today?`
            },
            assistant: {
              ...config.assistant,
              description: config.assistant.description || `AI assistant for ${config.storeInfo.name}`,
              persona: config.assistant.persona || 'Professional and helpful'
            },
            leads: config.leads,
            features: {
              chatEnabled: true,
              recommendationsEnabled: true,
              analyticsEnabled: true
            },
            chatConfig: {
              welcomeMessage: config.branding.welcomeMessage || `Welcome to ${config.storeInfo.name}! How can I help you today?`,
              aiPersonality: config.assistant.persona || 'Professional and helpful',
              model: 'gpt-4',
              temperature: 0.7,
              maxTokens: 2000,
              assistantId: '', // Will be set by the backend
              masterPrompt: `You are an AI assistant for ${config.storeInfo.name}. ${config.assistant.description || ''}. ${config.assistant.persona || 'Professional and helpful'}`,
              initialGreeting: config.assistant.greeting || `Welcome to ${config.storeInfo.name}! How can I help you today?`,
              toneDirective: config.assistant.persona || 'Professional and helpful',
              conversationStyle: 'professional',
              leadCaptureTiming: 'after-engagement',
              primaryGoal: 'lead',
              educationTopics: '',
              responseCreativity: 0.7,
              productRecommendationLimit: 3
            },
            updatedAt: serverTimestamp()
          };

          // Update the config
          await setDoc(merchantConfigRef, configData, { merge: true });
          
          // Also save the branding colors in the format expected by the chat app
          await setDoc(merchantConfigRef, {
            branding: {
              colors: {
                primary: config.branding.colors.primary,
                secondary: config.branding.colors.secondary || config.branding.colors.primary,
                background: '#ffffff',
                text: '#000000'
              }
            }
          }, { merge: true });
          
        } catch (error) {
          console.error('Failed to update chat:', error);
          // Don't throw the error, just log it
          // This prevents the error from breaking the deployment process
        }
      },
      checkFirestoreCompletionStatus: async (userId) => {
        if (!userId) return;

        try {
          const merchantConfigRef = doc(db, 'merchants', userId, 'merchantConfig', 'settings');
          const docSnap = await getDoc(merchantConfigRef);

          if (docSnap.exists()) {
            // If the settings document exists, assume onboarding is complete
            // We could add more specific checks here if needed (e.g., check for chatUrl)
            console.log('Firestore check: Found merchant config, marking onboarding as complete.');
            set({ isComplete: true, error: null });
          } else {
            // Optional: If the doc doesn't exist, ensure local state isn't incorrectly marked as complete
            if (get().isComplete) {
               console.log('Firestore check: Merchant config not found, ensuring local state is not complete.');
               set({ isComplete: false });
            } else {
               console.log('Firestore check: Merchant config not found, local state is already not complete.');
            }
          }
        } catch (error) {
          console.error('Error checking Firestore completion status:', error);
          // Decide how to handle errors - maybe don't change isComplete?
          // set({ error: 'Failed to check onboarding status.' });
        }
      },
    }),
    {
      name: 'mattressai-onboarding',
    }
  )
); 