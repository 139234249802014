/**
 * Centralized Merchant Configuration Service
 * 
 * This service provides a single source of truth for managing merchant configuration.
 * It stores all configuration data in the merchantConfig/settings subcollection
 * and only keeps essential summary data in the main merchant document.
 */

import { collection, doc, getDoc, setDoc, updateDoc, serverTimestamp, runTransaction, writeBatch } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { getAuth } from 'firebase/auth';
import { 
  MerchantConfig,
  DEFAULT_MERCHANT_CONFIG,
} from '../../types/merchantConfig';
import { masterPromptService } from '../ai/MasterPromptService';

/**
 * Error class for configuration validation errors
 */
export class ConfigValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ConfigValidationError';
  }
}

export class CentralizedConfigService {
  private static instance: CentralizedConfigService;
  private cachedConfig: MerchantConfig | null = null;
  private lastLoadTime: number = 0;
  private loadPromise: Promise<MerchantConfig> | null = null;
  private savePromise: Promise<void> | null = null;
  private pendingChanges: Partial<MerchantConfig> | null = null;
  private saveInProgress: boolean = false;
  private saveDebounceTimeout: NodeJS.Timeout | null = null;
  
  // Optimistic update flag - when true, cache is updated before save completes
  private optimisticUpdatesEnabled: boolean = true;

  // Constructor made private to enforce singleton pattern
  private constructor() {}

  // Get instance of the service (singleton)
  static getInstance(): CentralizedConfigService {
    if (!CentralizedConfigService.instance) {
      CentralizedConfigService.instance = new CentralizedConfigService();
    }
    return CentralizedConfigService.instance;
  }
  
  /**
   * Get the current merchant ID from auth
   * Falls back to user.uid if no merchantId claim exists
   * @returns {Promise<string|null>} The merchant ID or null if no user is logged in
   */
  private async getMerchantId(): Promise<string | null> {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.warn('No authenticated user found while trying to get merchant ID');
      return null;
    }
    
    const userUid = user.uid;
    
    try {
      const idTokenResult = await user.getIdTokenResult();
      const merchantId = idTokenResult.claims.merchantId as string;
      return merchantId || userUid;
    } catch (error) {
      console.error('Error getting merchant ID:', error);
      return userUid;
    }
  }

  /**
   * Path to the merchant config document
   * @param {string} merchantId The merchant ID
   * @returns {string} The path to the config document
   */
  private configPath(merchantId: string): string {
    return `merchants/${merchantId}/merchantConfig/settings`;
  }

  /**
   * Validates configuration data before saving
   * Only validates fields that are actually present in the update
   * @param {Partial<MerchantConfig>} config The configuration to validate
   * @throws {ConfigValidationError} If validation fails
   */
  private validateConfig(config: Partial<MerchantConfig>): void {
    // Validate required fields
    if (config.storeInfo && 'name' in config.storeInfo && !config.storeInfo.name) {
      throw new ConfigValidationError('Store name is required');
    }

    // Validate field types
    if (config.chatConfig) {
      if ('temperature' in config.chatConfig &&
          (typeof config.chatConfig.temperature !== 'number' || 
           config.chatConfig.temperature < 0 || 
           config.chatConfig.temperature > 1)) {
        throw new ConfigValidationError('Temperature must be a number between 0 and 1');
      }
      
      if ('maxTokens' in config.chatConfig &&
          (typeof config.chatConfig.maxTokens !== 'number' || 
           config.chatConfig.maxTokens < 100 || 
           config.chatConfig.maxTokens > 8000)) {
        throw new ConfigValidationError('Max tokens must be a number between 100 and 8000');
      }
    }

    // Validate color formats in branding
    if (config.branding?.colors) {
      const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      const colors = config.branding.colors;
      
      if ('primary' in colors && colors.primary && !colorRegex.test(colors.primary)) {
        throw new ConfigValidationError('Primary color must be a valid hex color');
      }
      
      if ('secondary' in colors && colors.secondary && !colorRegex.test(colors.secondary)) {
        throw new ConfigValidationError('Secondary color must be a valid hex color');
      }
      
      if ('background' in colors && colors.background && !colorRegex.test(colors.background)) {
        throw new ConfigValidationError('Background color must be a valid hex color');
      }
      
      if ('text' in colors && colors.text && !colorRegex.test(colors.text)) {
        throw new ConfigValidationError('Text color must be a valid hex color');
      }
    }
  }

  /**
   * Merge base configuration with updates
   * @param {MerchantConfig} baseConfig The base configuration
   * @param {Partial<MerchantConfig>} updates The updates to apply
   * @returns {MerchantConfig} The merged configuration
   */
  private mergeConfigs(baseConfig: MerchantConfig, updates: Partial<MerchantConfig>): MerchantConfig {
    // Deep clone the base config to prevent mutations
    const base = JSON.parse(JSON.stringify(baseConfig));
    
    // Create a proper deep merged object
    const merged = {
      ...base,
      ...updates,
      // Merge nested objects properly
      storeInfo: updates.storeInfo ? {
        ...base.storeInfo,
        ...updates.storeInfo
      } : base.storeInfo,
      branding: updates.branding ? {
        ...base.branding,
        ...updates.branding,
        colors: updates.branding?.colors ? {
          ...base.branding.colors,
          ...updates.branding.colors
        } : base.branding.colors,
        fonts: updates.branding?.fonts ? {
          ...base.branding.fonts,
          ...updates.branding.fonts
        } : base.branding.fonts
      } : base.branding,
      chatConfig: updates.chatConfig ? {
        ...base.chatConfig,
        ...updates.chatConfig
      } : base.chatConfig,
      assistant: updates.assistant ? {
        ...base.assistant,
        ...updates.assistant
      } : base.assistant,
      features: updates.features ? {
        ...base.features,
        ...updates.features
      } : base.features,
      subscription: updates.subscription ? {
        ...base.subscription,
        ...updates.subscription,
        limits: updates.subscription?.limits ? {
          ...base.subscription.limits,
          ...updates.subscription.limits
        } : base.subscription.limits
      } : base.subscription
    };
    
    return merged;
  }
  
  /**
   * Normalize configuration data by ensuring consistent field names
   * Maps legacy fields to their new standardized names
   * @param {MerchantConfig} config The configuration to normalize
   * @returns {MerchantConfig} The normalized configuration
   */
  private normalizeConfig(config: MerchantConfig): MerchantConfig {
    const normalized = { ...config };
    
    // Handle legacy fields if they exist in the raw data
    const rawConfig = config as any; // Use any to access potential legacy fields
    
    if (rawConfig.chatConfig) {
      normalized.assistant = {
        ...(normalized.assistant || {}),
        // Map legacy fields to new structure if they exist
        ...(rawConfig.chatConfig.aiName ? { name: rawConfig.chatConfig.aiName } : {}),
        ...(rawConfig.chatConfig.initialGreeting ? { greeting: rawConfig.chatConfig.initialGreeting } : {}),
        ...(rawConfig.chatConfig.aiPersonality ? { persona: rawConfig.chatConfig.aiPersonality } : {})
      };
      
      normalized.branding = {
        ...(normalized.branding || { 
          colors: DEFAULT_MERCHANT_CONFIG.branding.colors,
          fonts: DEFAULT_MERCHANT_CONFIG.branding.fonts,
          chatTitle: DEFAULT_MERCHANT_CONFIG.branding.chatTitle
        }),
        ...(rawConfig.chatConfig.welcomeMessage ? { welcomeMessage: rawConfig.chatConfig.welcomeMessage } : {})
      };
    }
    
    return normalized;
  }

  /**
   * Load configuration from Firestore
   * @param {boolean} forceRefresh Whether to force a refresh from the database
   * @returns {Promise<MerchantConfig>} The loaded configuration
   */
  async loadConfig(forceRefresh = false): Promise<MerchantConfig> {
    // If a save is in progress, wait for it to complete before loading
    if (this.savePromise) {
      try {
        await this.savePromise;
      } catch (error) {
        console.error('Error waiting for save to complete:', error);
      }
    }
    
    // If we have a cached config and it's not a force refresh, return it
    if (this.cachedConfig && !forceRefresh && Date.now() - this.lastLoadTime < 60000) {
      return JSON.parse(JSON.stringify(this.cachedConfig)); // Return a deep copy to prevent mutation
    }
    
    // If we're already loading, return the existing promise
    if (this.loadPromise) {
      return this.loadPromise;
    }
    
    this.loadPromise = this.loadConfigInternal();
    
    try {
      const config = await this.loadPromise;
      this.cachedConfig = JSON.parse(JSON.stringify(config)); // Store a deep copy
      this.lastLoadTime = Date.now();
      return JSON.parse(JSON.stringify(config)); // Return a copy to prevent mutation
    } finally {
      this.loadPromise = null;
    }
  }
  
  /**
   * Internal method to load configuration from Firestore
   * @returns {Promise<MerchantConfig>} The loaded configuration
   */
  private async loadConfigInternal(): Promise<MerchantConfig> {
    try {
      const merchantId = await this.getMerchantId();
      if (!merchantId) {
        console.error('No merchant ID found, returning default config');
        return JSON.parse(JSON.stringify(DEFAULT_MERCHANT_CONFIG)); // Return a deep copy of default config
      }
      
      console.log('Loading config for merchantId:', merchantId);
      const docRef = doc(db, this.configPath(merchantId));
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        console.log('Config not found for merchantId:', merchantId, 'returning default config');
        return JSON.parse(JSON.stringify(DEFAULT_MERCHANT_CONFIG));
      }
      
      // Get data and normalize it
      const data = docSnap.data();
      
      // Construct a configuration object
      const config: MerchantConfig = {
        storeInfo: data.storeInfo || DEFAULT_MERCHANT_CONFIG.storeInfo,
        branding: data.branding || DEFAULT_MERCHANT_CONFIG.branding,
        chatConfig: data.chatConfig || DEFAULT_MERCHANT_CONFIG.chatConfig,
        assistant: data.assistant || DEFAULT_MERCHANT_CONFIG.assistant,
        features: data.features || DEFAULT_MERCHANT_CONFIG.features,
        subscription: data.subscription || DEFAULT_MERCHANT_CONFIG.subscription
      };
      
      // Normalize the config to ensure consistent field names
      const normalizedConfig = this.normalizeConfig(config);
      
      return normalizedConfig;
    } catch (error) {
      console.error('Error loading merchant config:', error);
      return JSON.parse(JSON.stringify(DEFAULT_MERCHANT_CONFIG));
    }
  }

  /**
   * Save configuration to Firestore with debouncing and optimistic updates
   * @param {Partial<MerchantConfig>} config The configuration to save
   * @returns {Promise<void>}
   */
  async saveConfig(config: Partial<MerchantConfig>): Promise<void> {
    try {
      // Validate the configuration
      this.validateConfig(config);
      
      // Store the pending changes
      this.pendingChanges = this.pendingChanges 
        ? this.mergeConfigs(this.pendingChanges as MerchantConfig, config) 
        : config;
      
      // If a save is already in progress, don't start another one
      if (this.saveInProgress) {
        console.log('Save already in progress, queuing changes');
        return this.savePromise as Promise<void>;
      }
      
      // Clear any existing debounce timeout
      if (this.saveDebounceTimeout) {
        clearTimeout(this.saveDebounceTimeout);
      }
      
      // Create a promise that will be resolved when the save completes
      let resolvePromise: () => void;
      let rejectPromise: (error: Error) => void;
      
      this.savePromise = new Promise<void>((resolve, reject) => {
        resolvePromise = resolve;
        rejectPromise = reject;
      });
      
      // Apply optimistic updates immediately if enabled
      if (this.optimisticUpdatesEnabled && this.cachedConfig) {
        this.cachedConfig = this.mergeConfigs(this.cachedConfig, this.pendingChanges as Partial<MerchantConfig>);
        this.lastLoadTime = Date.now();
      }
      
      // Set a timeout to perform the actual save
      this.saveDebounceTimeout = setTimeout(async () => {
        this.saveInProgress = true;
        
        try {
          const changes = { ...this.pendingChanges };
          this.pendingChanges = null;
          
          // Get merchant ID
          const merchantId = await this.getMerchantId();
          if (!merchantId) {
            throw new Error('No merchant ID found');
          }
          
          // Load current config to merge with if we haven't done optimistic updates
          let currentConfig: MerchantConfig;
          if (this.optimisticUpdatesEnabled && this.cachedConfig) {
            currentConfig = this.cachedConfig;
          } else {
            // Force reload from database
            this.loadPromise = this.loadConfigInternal();
            currentConfig = await this.loadPromise;
            this.loadPromise = null;
          }
          
          // Get only the sections that are being updated to avoid overwriting others with defaults
          const updateSections: Partial<MerchantConfig> = {};
          
          // Only include sections that were in the original update
          if ('storeInfo' in changes) {
            updateSections.storeInfo = { ...currentConfig.storeInfo, ...changes.storeInfo };
          }
          
          if ('branding' in changes) {
            updateSections.branding = { ...currentConfig.branding, ...changes.branding };
          }
          
          if ('chatConfig' in changes) {
            updateSections.chatConfig = { ...currentConfig.chatConfig, ...changes.chatConfig };
          }
          
          if ('assistant' in changes) {
            updateSections.assistant = { ...currentConfig.assistant, ...changes.assistant };
          }
          
          if ('features' in changes) {
            updateSections.features = { ...currentConfig.features, ...changes.features };
          }
          
          if ('subscription' in changes) {
            updateSections.subscription = { ...currentConfig.subscription, ...changes.subscription };
          }
          
          console.log('Preparing to save partial update with sections:', Object.keys(updateSections));
          
          // Check if we should update the masterPrompt automatically
          // Only do this if changes would affect the prompt content and the merchant 
          // is not explicitly updating the masterPrompt already
          if (masterPromptService.shouldUpdateMasterPrompt(changes) && 
              !changes.assistant?.masterPrompt) {
            
            console.log('Configuration change detected that affects masterPrompt');
            
            // Check if the masterPrompt was manually edited
            const configRef = doc(db, this.configPath(merchantId));
            const configSnap = await getDoc(configRef);
            
            if (configSnap.exists()) {
              const currentConfig = configSnap.data() as MerchantConfig;
              
              if (currentConfig.assistant?.masterPromptSource === 'manual') {
                console.log('Manually edited masterPrompt detected, showing confirmation dialog');
                
                // Show confirmation dialog
                const shouldUpdate = await this.showConfirmationDialog(
                  'Update AI Prompt?',
                  'You have made changes that would normally update your AI prompt. Would you like to update it or keep your custom version?'
                );
                
                if (shouldUpdate) {
                  console.log('User confirmed masterPrompt update, scheduling update');
                  // Don't wait for this to complete - schedule the update to happen after save
                  setTimeout(() => {
                    this.updateMasterPromptInBackground(currentConfig);
                  }, 500);
                } else {
                  console.log('User chose to keep custom masterPrompt');
                }
              } else {
                console.log('Auto-generated masterPrompt detected, scheduling update');
                // Don't wait for this to complete - schedule the update to happen after save
                setTimeout(() => {
                  this.updateMasterPromptInBackground(currentConfig);
                }, 500);
              }
            } else {
              console.log('No existing config found, scheduling masterPrompt update');
              // Don't wait for this to complete - schedule the update to happen after save
              setTimeout(() => {
                this.updateMasterPromptInBackground(currentConfig);
              }, 500);
            }
          }
          
          // Save to database - only pass the sections that are being updated
          await this.saveConfigToDatabase(merchantId, updateSections as MerchantConfig);
          
          // Update cache with the final result - create a merged version with ALL sections
          const mergedConfig = this.mergeConfigs(currentConfig, updateSections as Partial<MerchantConfig>);
          this.cachedConfig = mergedConfig;
          this.lastLoadTime = Date.now();
          
          // Resolve the promise
          resolvePromise();
        } catch (error) {
          console.error('Error saving config:', error);
          
          // If optimistic updates were applied, reload from database to revert changes
          if (this.optimisticUpdatesEnabled) {
            try {
              this.cachedConfig = null; // Clear cache to force reload
              await this.loadConfig(true);
            } catch (loadError) {
              console.error('Error reverting optimistic updates:', loadError);
            }
          }
          
          // Reject the promise
          rejectPromise(error instanceof Error ? error : new Error('Unknown error saving config'));
        } finally {
          this.saveInProgress = false;
          this.savePromise = null;
          
          // If there are pending changes, save them
          if (this.pendingChanges) {
            setTimeout(() => this.saveConfig(this.pendingChanges as Partial<MerchantConfig>), 0);
          }
        }
      }, 300); // 300ms debounce
      
      return this.savePromise;
    } catch (error) {
      if (error instanceof ConfigValidationError) {
        throw error;
      } else {
        console.error('Error preparing config save:', error);
        throw new Error(`Failed to save configuration: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }
  }

  /**
   * Updates the masterPrompt in the background after other config changes
   */
  async updateMasterPromptInBackground(merchantIdOrConfig: string | MerchantConfig): Promise<void> {
    try {
      // Check if we received a merchant ID (string) or a config object
      let merchantId: string | null = null;
      let currentConfig: MerchantConfig;
      
      if (typeof merchantIdOrConfig === 'string') {
        merchantId = merchantIdOrConfig;
        currentConfig = await this.getConfig();
      } else {
        currentConfig = merchantIdOrConfig;
        merchantId = await this.getMerchantId();
      }
      
      if (!merchantId) {
        console.error('No merchant ID available for updating masterPrompt');
        return Promise.reject(new Error('No merchant ID available'));
      }

      // Generate a new masterPrompt
      const generatedMasterPrompt = await masterPromptService.generateMasterPrompt(currentConfig);

      // Ensure we have a valid assistant object
      if (!currentConfig.assistant) {
        currentConfig.assistant = { 
          name: 'AI Assistant',
          description: 'Your mattress expert',
          persona: 'helpful and knowledgeable',
          greeting: 'How can I help you with your mattress needs today?',
          masterPrompt: ''
        };
      }

      // Update the masterPrompt
      currentConfig.assistant.masterPrompt = generatedMasterPrompt;
      currentConfig.assistant.masterPromptSource = 'auto';
      currentConfig.assistant.masterPromptLastEditedAt = new Date().toISOString();
      
      // Save the updated config
      await this.saveConfig(currentConfig);
      
      console.log('Successfully updated masterPrompt');
      return Promise.resolve();
    } catch (error) {
      console.error('Error updating masterPrompt in background:', error);
      return Promise.reject(error);
    }
  }

  /**
   * Prepares a configuration object for saving to ensure data integrity
   */
  private prepareConfigForSaving(config: MerchantConfig): MerchantConfig {
    const configCopy = { ...config };

    // Ensure assistant object exists with required fields
    if (!configCopy.assistant) {
      configCopy.assistant = {
        name: 'AI Assistant',
        description: 'Your mattress expert',
        persona: 'helpful and knowledgeable',
        greeting: 'How can I help you with your mattress needs today?',
        masterPrompt: ''
      };
    }

    // Ensure branding exists with all required fields
    if (!configCopy.branding) {
      configCopy.branding = {
        colors: {
          primary: '#1976d2',
          secondary: '#f50057',
          background: '#ffffff',
          text: '#000000'
        },
        fonts: {
          primary: 'Inter',
          secondary: 'system-ui'
        },
        chatTitle: 'MattressAI Chat',
        welcomeMessage: 'Welcome! How can I help you find the perfect mattress?'
      };
    }

    // Ensure branding.colors exists and has all required fields
    if (!configCopy.branding.colors) {
      configCopy.branding.colors = {
        primary: '#1976d2',
        secondary: '#f50057',
        background: '#ffffff',
        text: '#000000'
      };
    }

    // Ensure each color field exists with a default value if undefined
    if (!configCopy.branding.colors.primary) configCopy.branding.colors.primary = '#1976d2';
    if (!configCopy.branding.colors.secondary) configCopy.branding.colors.secondary = '#f50057';
    if (!configCopy.branding.colors.background) configCopy.branding.colors.background = '#ffffff';
    if (!configCopy.branding.colors.text) configCopy.branding.colors.text = '#000000';

    // Ensure masterPrompt is not undefined in canonical location
    if (configCopy.assistant.masterPrompt === undefined || configCopy.assistant.masterPrompt === null) {
      console.log('Setting empty masterPrompt in assistant.masterPrompt');
      configCopy.assistant.masterPrompt = '';
      configCopy.assistant.masterPromptSource = 'auto';
      configCopy.assistant.masterPromptLastEditedAt = new Date().toISOString();
    }

    return configCopy;
  }

  /**
   * Save configuration to Firestore with transaction to ensure consistency
   */
  private async saveConfigToDatabase(merchantId: string, config: MerchantConfig): Promise<void> {
    try {
      console.log(`Saving config for merchant ${merchantId}`, config);
      
      // Process config to remove undefined values and ensure required structures
      const processedConfig = this.prepareConfigForSaving(config);
      
      // Additional validation to ensure no undefined values
      this.sanitizeObjectForFirestore(processedConfig);
      
      const merchantRef = doc(db, `merchants/${merchantId}`);
      const configRef = doc(db, this.configPath(merchantId));
      
      // First, get the current data to ensure we don't lose anything
      const currentConfigDoc = await getDoc(configRef);
      const currentConfig = currentConfigDoc.exists() ? currentConfigDoc.data() : {};
      
      // Merge the current config with the new config to preserve legacy data
      const mergedConfig = {
        ...currentConfig,
        ...processedConfig,
        // Ensure nested objects are properly merged
        storeInfo: {
          ...(currentConfig.storeInfo || {}),
          ...(processedConfig.storeInfo || {})
        },
        branding: {
          ...(currentConfig.branding || {}),
          ...(processedConfig.branding || {}),
          colors: {
            ...(currentConfig.branding?.colors || {}),
            ...(processedConfig.branding?.colors || {})
          },
          fonts: {
            ...(currentConfig.branding?.fonts || {}),
            ...(processedConfig.branding?.fonts || {})
          }
        },
        chatConfig: {
          ...(currentConfig.chatConfig || {}),
          ...(processedConfig.chatConfig || {})
        },
        assistant: {
          ...(currentConfig.assistant || {}),
          ...(processedConfig.assistant || {})
        },
        features: {
          ...(currentConfig.features || {}),
          ...(processedConfig.features || {})
        },
        subscription: {
          ...(currentConfig.subscription || {}),
          ...(processedConfig.subscription || {}),
          limits: {
            ...(currentConfig.subscription?.limits || {}),
            ...(processedConfig.subscription?.limits || {})
          }
        }
      };
      
      await runTransaction(db, async (transaction) => {
        // Get current merchant document
        const merchantDoc = await transaction.get(merchantRef);
        const existingMerchantData = merchantDoc.exists() ? merchantDoc.data() : {};
        
        // Save to config document with complete merged data
        transaction.set(configRef, {
          ...mergedConfig,
          updatedAt: serverTimestamp()
        });
        
        // Update merchant document with summary data
        const merchantData = {
          ...existingMerchantData,
          updatedAt: serverTimestamp(),
          storeInfo: {
            ...(existingMerchantData?.storeInfo || {}),
            name: mergedConfig.storeInfo.name,
            hasMultipleLocations: mergedConfig.storeInfo.hasMultipleLocations || false,
            website: mergedConfig.storeInfo.website || '',
            uniqueFeatures: mergedConfig.storeInfo.uniqueFeatures || '',
            timezone: mergedConfig.storeInfo.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            language: mergedConfig.storeInfo.language || 'en',
            currency: mergedConfig.storeInfo.currency || 'USD',
            measurementUnit: mergedConfig.storeInfo.measurementUnit || 'imperial',
            contactInfo: mergedConfig.storeInfo.contactInfo || '',
            hours: mergedConfig.storeInfo.hours || ''
          }
        };
        
        // Save to merchant document
        if (!merchantDoc.exists()) {
          transaction.set(merchantRef, merchantData);
        } else {
          transaction.set(merchantRef, merchantData, { merge: true });
        }
      });
      
      // Update cache with the complete merged data
      this.cachedConfig = mergedConfig;
      this.lastLoadTime = Date.now();
      
      console.log(`Config saved successfully for merchant ${merchantId}`);
    } catch (error) {
      console.error(`Error in transaction saving config for merchant ${merchantId}:`, error);
      // Clear cache to force a fresh load next time
      this.cachedConfig = null;
      this.lastLoadTime = 0;
      throw error;
    }
  }

  /**
   * Initialize a new merchant configuration
   * @param {string} merchantId The merchant ID
   * @param {string} storeName The store name
   * @returns {Promise<void>}
   */
  async initializeConfig(merchantId: string, storeName: string): Promise<void> {
    // Create a new config with the provided store name
    const newConfig: MerchantConfig = {
      ...DEFAULT_MERCHANT_CONFIG,
      storeInfo: {
        ...DEFAULT_MERCHANT_CONFIG.storeInfo,
        name: storeName
      }
    };
    
    // Save to database
    try {
      await this.saveConfigToDatabase(merchantId, newConfig);
      
      // Update cache
      this.cachedConfig = newConfig;
      this.lastLoadTime = Date.now();
    } catch (error) {
      console.error('Error initializing config:', error);
      throw error;
    }
  }

  /**
   * Shows a simple confirmation dialog using browser's native confirm
   * @param title The dialog title
   * @param message The dialog message
   * @returns Promise that resolves to true if confirmed, false otherwise
   */
  private showConfirmationDialog(title: string, message: string): Promise<boolean> {
    return Promise.resolve(window.confirm(`${title}\n\n${message}`));
  }

  /**
   * Retrieves the current merchant configuration
   * Uses cached value if available, otherwise fetches from database
   */
  async getConfig(): Promise<MerchantConfig> {
    try {
      // If we have a cached config that's not expired, use it
      if (this.cachedConfig && Date.now() - this.lastLoadTime < 60000) {
        return this.cachedConfig;
      }

      // Fetch fresh config
      const config = await this.loadConfig();
      
      // Cache the config and update fetch timestamp
      this.cachedConfig = config;
      this.lastLoadTime = Date.now();
      
      return this.cachedConfig;
    } catch (error) {
      console.error('Error fetching merchant config:', error);
      // If we have a cached config, use it as fallback
      if (this.cachedConfig) {
        console.log('Using cached config as fallback after error');
        return this.cachedConfig;
      }
      throw error;
    }
  }

  /**
   * Ensures required fields have values to prevent Firestore errors
   * @param {Partial<MerchantConfig>} config The configuration to ensure fields for
   * @returns {Partial<MerchantConfig>} The configuration with required fields
   */
  private ensureRequiredFields(config: Partial<MerchantConfig>): Partial<MerchantConfig> {
    const updatedConfig = { ...config };
    
    // Ensure branding has required fields
    if (updatedConfig.branding) {
      if (!updatedConfig.branding.colors) {
        updatedConfig.branding.colors = {
          primary: '#1976d2',
          secondary: '#f50057',
          background: '#ffffff',
          text: '#121212'
        };
      }
      
      if (!updatedConfig.branding.fonts) {
        updatedConfig.branding.fonts = {
          primary: 'Roboto',
          secondary: 'Arial'
        };
      }
    }
    
    // Ensure assistant has required fields if it exists
    if (updatedConfig.assistant && !updatedConfig.assistant.name) {
      updatedConfig.assistant.name = 'Mattress Assistant';
    }
    
    return updatedConfig;
  }

  /**
   * Directly saves the merchant configuration to Firestore without debouncing
   * Use this method when you need immediate saving with explicit merchant ID
   */
  async directSaveConfig(merchantId: string, config: Partial<MerchantConfig>): Promise<void> {
    try {
      console.log(`Directly saving config for merchant ${merchantId}`, config);
      
      // Ensure required fields have values to prevent Firestore errors
      const safeConfig = this.ensureRequiredFields(config);
      
      // Get a reference to the merchant config document
      const configRef = doc(db, `merchants/${merchantId}/merchantConfig/settings`);
      
      // Update the document with merge to preserve existing fields
      await setDoc(configRef, safeConfig, { merge: true });
      
      console.log(`Successfully saved config for merchant ${merchantId}`);
    } catch (error) {
      console.error(`Error in transaction saving config for merchant ${merchantId}:`, error);
      throw error;
    }
  }

  /**
   * Sanitizes an object to ensure it's safe for Firestore storage
   * Replaces undefined values with nulls or default values
   * @param obj The object to sanitize
   */
  private sanitizeObjectForFirestore(obj: any): void {
    if (!obj || typeof obj !== 'object') return;
    
    // Special handling for branding colors
    if (obj.branding) {
      if (!obj.branding.colors) {
        obj.branding.colors = {
          primary: '#1976d2',
          secondary: '#f50057',
          background: '#ffffff',
          text: '#000000'
        };
      } else {
        // Ensure each color has a value
        obj.branding.colors.primary = obj.branding.colors.primary || '#1976d2';
        obj.branding.colors.secondary = obj.branding.colors.secondary || '#f50057';
        obj.branding.colors.background = obj.branding.colors.background || '#ffffff';
        obj.branding.colors.text = obj.branding.colors.text || '#000000';
      }
    }
    
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined) {
        console.warn(`Found undefined value for key ${key}, replacing with null`);
        obj[key] = null; // Replace undefined with null for Firestore
      } else if (obj[key] === null) {
        // Keep null values as is
      } else if (typeof obj[key] === 'object') {
        // Recursively sanitize nested objects
        this.sanitizeObjectForFirestore(obj[key]);
      }
    });
  }
} 