/**
 * Templates module index
 * Provides template generation for MasterPromptService
 */

export * from './types';
export * from './leadCaptureStrategies';
export * from './comprehensiveTemplate';
export * from './cognitiveFrameworkTemplate';
export * from './customerCentricTemplate';

// Template factories
import { generateComprehensiveTemplate } from './comprehensiveTemplate';
import { generateCognitiveFrameworkTemplate } from './cognitiveFrameworkTemplate';
import { generateCustomerCentricTemplate } from './customerCentricTemplate';
import { TemplateParams } from './types';

/**
 * Gets the appropriate template generator function based on the template name
 * 
 * @param template The template name
 * @returns The template generator function
 */
export function getTemplateGenerator(template?: string): (params: TemplateParams) => string {
  switch (template) {
    case 'cognitive-framework':
      return generateCognitiveFrameworkTemplate;
    case 'customer-centric':
      return generateCustomerCentricTemplate;
    case 'comprehensive':
    default:
      return generateComprehensiveTemplate;
  }
} 