import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { ThemeConfig, DEFAULT_THEME, MerchantConfig, DEFAULT_CONFIG } from '@/types/config';

export class MerchantConfigService {
  private static instance: MerchantConfigService;
  private config: MerchantConfig | null = null;
  private subdomain: string | null = null;
  private db = getFirestore();

  private constructor() {}

  static getInstance(): MerchantConfigService {
    if (!MerchantConfigService.instance) {
      MerchantConfigService.instance = new MerchantConfigService();
    }
    return MerchantConfigService.instance;
  }

  async initializeFromSubdomain(hostname: string): Promise<MerchantConfig> {
    try {
      // Extract subdomain from hostname
      const subdomain = hostname.split('.')[0];
      this.subdomain = subdomain;
      
      // Get merchant configuration directly using subdomain
      const configRef = doc(this.db, `merchants/${subdomain}/merchantConfig/settings`);
      const configDoc = await getDoc(configRef);

      if (!configDoc.exists()) {
        // Initialize with default config if none exists
        this.config = DEFAULT_CONFIG;
        await setDoc(configRef, this.config);
      } else {
        const data = configDoc.data() as MerchantConfig;
        this.config = {
          ...DEFAULT_CONFIG,  // Ensure all default values are present
          ...data,           // Override with any existing values
        };
      }

      return this.config;
    } catch (error) {
      console.error('Failed to initialize merchant config:', error);
      throw error;
    }
  }

  getCurrentConfig(): MerchantConfig {
    if (!this.config) {
      throw new Error('Configuration not initialized');
    }
    return this.config;
  }

  async updateConfig(newConfig: Partial<MerchantConfig>): Promise<void> {
    if (!this.subdomain || !this.config) {
      throw new Error('Configuration not initialized');
    }

    try {
      const configRef = doc(this.db, `merchants/${this.subdomain}/merchantConfig/settings`);
      await setDoc(configRef, { ...this.config, ...newConfig }, { merge: true });
      this.config = { ...this.config, ...newConfig };
    } catch (error) {
      console.error('Failed to update configuration:', error);
      throw error;
    }
  }

  async updateTheme(theme: ThemeConfig): Promise<void> {
    if (!this.config) {
      throw new Error('Configuration not initialized');
    }

    await this.updateConfig({
      branding: {
        ...this.config.branding,
        colors: theme
      }
    });
  }
} 